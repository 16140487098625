
<div class="fit_the_screen">
  <app-admin_header></app-admin_header>

  <h1 class="page-heading">Network Management</h1>

  <div class="parent">
    <div class="table-container">  

        <!-- Search bar -->
      <div class="search-container">
        <label for="search">Search:</label>
        <input type="text" id="search" [(ngModel)]="searchTerm" (input)="applyFilter()" />
      </div>
      
      <!-- Pagination -->
      <nav aria-label="...">
        <ul class="pagination">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="changePage(currentPage - 1)" tabindex="-1">Previous</a>
          </li>
          <li *ngFor="let page of pages" class="page-item" [class.active]="currentPage === page">
            <a class="page-link" (click)="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="changePage(currentPage + 1)">Next</a>
          </li>
        </ul>
      </nav>

      <!-- Display network entries -->
      <table class="table table-striped">
        <thead>
        <tr>
          <th (click)="sortByColumn('id')" style="width: 50px;">
            ID
            <i class="fas fa-sort-up" *ngIf="sortBy === 'id' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'id' && !sortAsc"></i>
          </th>    
                    
          <th (click)="sortByColumn('branch')" style="width: 150px;">
            Branch
            <i class="fas fa-sort-up" *ngIf="sortBy === 'branch' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'branch' && !sortAsc"></i>
          </th>

          <th (click)="sortByColumn('address')" style="width: 150px;">
            Address
            <i class="fas fa-sort-up" *ngIf="sortBy === 'address' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'address' && !sortAsc"></i>
          </th>

          <th (click)="sortByColumn('tel')" style="width: 150px;">
            Telephone
            <i class="fas fa-sort-up" *ngIf="sortBy === 'tel' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'tel' && !sortAsc"></i>
          </th>

          <th (click)="sortByColumn('email')" style="width: 150px;">
            Email
            <i class="fas fa-sort-up" *ngIf="sortBy === 'email' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'email' && !sortAsc"></i>
          </th>

          <th style="width: 100px;">Action</th>
        </tr>
        </thead>

        <tbody>
            <tr *ngFor="let location of paginatedData; let index = index">
              <td>{{ location.id }}</td>
              <td>{{ location.branch }}</td>
              <td>{{ location.address }}</td>
              <td>{{ location.tel }}</td>
              <td>{{ location.email }}</td>
              <td>
                <button class="btn btn-primary" (click)="editNetwork(location)">
                  <i class="fas fa-edit"></i>
                </button>
                <button class="btn btn-danger" (click)="deleteNetwork(location.id)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
      </table>

      <!-- Pagination -->
      <nav aria-label="...">
        <ul class="pagination">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="changePage(currentPage - 1)" tabindex="-1">Previous</a>
          </li>
          <li *ngFor="let page of pages" class="page-item" [class.active]="currentPage === page">
            <a class="page-link" (click)="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="changePage(currentPage + 1)">Next</a>
          </li>
        </ul>
      </nav>
    </div>


    <!-- Pop up edit form -->
    <div class="modal" [class.show]="isEditing">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Network</h5>
            <button type="button" class="close" (click)="cancelEdit()">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="editForm" (ngSubmit)="saveEdit()">
              <div class="form-group">
                <label for="branch">Branch</label>
                <input type="text" id="branch" formControlName="branch" required>
                <div *ngIf="formEdit && editForm.get('branch').hasError('required')" class="error-message" >
                  This field is required
                </div>
              </div>

              <div class="form-group">
                <label for="address">Address</label>
                <input type="text" id="address" formControlName="address" required>
                <div *ngIf="formEdit && editForm.get('address').hasError('required')" class="error-message" >
                  This field is required
                </div>
              </div>

              <div class="form-group">
                <label for="tel">Telephone</label>
                <input type="text" id="tel" formControlName="tel" required>
                <div *ngIf="formEdit && editForm.get('tel').hasError('required')" class="error-message" >
                  This field is required
                </div>
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input type="text" id="email" formControlName="email" required>
                <div *ngIf="formEdit && editForm.get('email').hasError('required')" class="error-message" >
                  This field is required
                </div>
              </div>
            </form>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="saveEdit()">Save</button>
          </div>

        </div>
      </div>
    </div>

      <!-- Network add form -->
      <div class="form-container">
        <h2>Network Form</h2>
        <h3>Add a new network with the form below</h3>
          <form [formGroup]="form" (ngSubmit)="addNetwork()">

            <div class="form-group">
              <label for="branch">Branch</label>
              <input type="text" id="branch" formControlName="branch" required>
              <div *ngIf="formSubmit && form.get('branch').hasError('required')" class="error-message" >
                This field is required
              </div>
            </div>  
            
            <div class="form-group">
              <label for="address">Address</label>
              <input type="text" id="address" formControlName="address" required>
              <div *ngIf="formSubmit && form.get('address').hasError('required')"  class="error-message">
                This field is required
              </div>
            </div>
        
            <div class="form-group">
              <label for="tel">Telephone</label>
              <input type="text" id="tel" formControlName="tel" required>
              <div *ngIf="formSubmit && form.get('tel').hasError('required')"  class="error-message">
                This field is required
              </div>
            </div>   
  
            <div class="form-group">
              <label for="email">Email</label>
              <input type="text" id="email" formControlName="email" required>
              <div *ngIf="formSubmit && form.get('email').hasError('required')"  class="error-message">
                This field is required
              </div>
            </div>  
              <button class="add-btn" type="submit">Add</button>
        </form>
      </div> 
  </div>
</div>
