import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { PlatformLocation } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    apiUrl = 'http://localhost:8000/api';
    currBaseURL: any;
    trackerURL:any;
    cmsURL: any;
    token:any;
  
    tokenCMS:any;

  constructor(
      private http: HttpClient,
      private platformLocation: PlatformLocation
  ) {
      this.checkBaseUrl();
  }

  checkBaseUrl() {

      this.currBaseURL = (this.platformLocation as any).location.origin;

      let prodURL = this.currBaseURL.match(/production.ai/g);
      let localURL = this.currBaseURL.match(/localhost/g);

      if (localURL == null) {
          this.apiUrl = "https://linehaul.com.my/api/public/api";
  

      } else {
        //    this.apiUrl = "http://localhost:8000/api";
           this.apiUrl = "https://linehaul.com.my/api/public/api";
      }
  }

    postSendEmail(data){
        
        const httpOptions = {
            headers: new HttpHeaders(
            { 
                'Content-Type': 'application/json'
            })
        }
   
        const url = `${this.apiUrl}/send-mail`

        return this.http.post(url, data, httpOptions);
    }

    loadCarousel(page?: string, state?: string): Observable<any> {
        let url = page ? `${this.apiUrl}/carousel?page=${page}` : `${this.apiUrl}/carousel`;
    
        if (state) {
          url += `&state=${state}`;
        }
    
        return this.http.get(url);
    }

    loadNetwork(): Observable<any> {
        const url = `${this.apiUrl}/network_list`;
        
        return this.http.get(url);
    }

    loadFaq(): Observable<any> {
        const url = `${this.apiUrl}/faq`;
        
        return this.http.get(url);
    }

    store(endpoint: string, data: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/${endpoint}`, data);
    }

    destroy(endpoint: string, id: number): Observable<any> {
        return this.http.delete(`${this.apiUrl}/${endpoint}/${id}/delete`);
    }

    update(endpoint: string, id: number, updatedData: any): Observable<any> {
        return this.http.put(`${this.apiUrl}/${endpoint}/${id}/edit`, updatedData);
    }

}