import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation } from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    currBaseURL: any;

  constructor(
    private route: Router,
    private platformLocation: PlatformLocation
  ) {

    this.currBaseURL = (this.platformLocation as any).location.origin;

   }

  ngOnInit(): void {
  }

  goToLanding(){
    this.route.navigate(['']);
    // this.route.navigateByUrl("/landing")
    // this.route.navigate(['landing'])
    // .then(() => {
    //     window.location.reload();
    // });

    // window.location.href = this.currBaseURL + '/'

    // alert(this.currBaseURL)

  }


  //goToPitstop(){
  //  this.route.navigate(['pitstop']);
  //}

    goToContact(){
    this.route.navigate(['contactus']);
  }

  //goToFaq(){
  //  this.route.navigate(['faq']);
  //}

  goToAboutUs(){
    this.route.navigate(['about-us']);
  }

  //goToOurTeam(){
  //  this.route.navigate(['our-team']);
  //}

  //goToTimeline(){
  //this.route.navigate(['timeline']);
  //}

  goToOurStrength(){
    this.route.navigate(['our_strength']);
  }

  goToOurTeam(){
    this.route.navigate(['our_team']);
  }

  goToHome(){
    this.route.navigate(['']);
  }

  goToMilestone(){
    this.route.navigate(['milestone']);
  }

  goToCertificate(){
    this.route.navigate(['certificate']);
  }

  goToOurEvents(){
    this.route.navigate(['our_events']);
  }

  goToCharteredTransport(){
    this.route.navigate(['chartered_transport']);
  }

  goToPalletDelivery(){
    this.route.navigate(['fcl_ltl_pallet_delivery']);
  }

  goToCrossBorder(){
    this.route.navigate(['cross_border']);
  }

  goToWarehouse(){
    this.route.navigate(['warehouse']);
  }

  goToWhyUs(){
    this.route.navigate(['why_us']);
  }

  goToPersonal(){
    this.route.navigate(['personal']);
  }

  goToCareer(){
    this.route.navigate(['career']);
  }

  goToSchedules(){
    this.route.navigate(['schedule']);
  }

  goToNewBooking(){
    this.route.navigate(['new_booking']);
  }

  goToDuplicatedBooking(){
    this.route.navigate(['duplicated_booking']);
  }

  goToNetwork(){
    this.route.navigate(['network']);
  }

  goToFaq(){
    this.route.navigate(['faq']);
  }
  goToPromotion(){
    this.route.navigate(['promotion']);
  }

}
