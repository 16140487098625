<div class="fit_the_screen">
  <app-header></app-header>



  <h4 class="text-title text-center text-size title "><b>Promotion</b></h4>

  <div class="section" style="padding-top: 0px;">
    <div class="container">
      <div class="row mobile-center" style="margin:0px ;">
        <div class="col-md-6 col-xs-12">
          <img src="./assets/image/99promo.jpg" class="center-block img-responsive img-fluid imgborder">
        </div>
        <div class="col-md-6 col-xs-12">
          <h4 class="text-left mobiletitle"> Pallet Courier RM99 Promotion </h4>
          <p> We are thrilled to announce our exclusive Pallet Courier 99 promotion! </p>
          <p>Enjoy seamless pallet delivery nationwide for an unbeatable price of just RM99*! </p>
          <p> Don’t miss out on this amazing offer. Ensure your goods are delivered swiftly and efficiently across
            nationwide with Line Haul!
            <br><a class="footerlist" href="javascript:;" (click)="goToTnC()">Terms and Conditions</a>
          </p>
          <p>Click the Register Now button below to deliver your shipment today! </p>

          <div class="input-group-lg btncenter">
            <span type="button" class="sign_up_btn btn btn-dark inputButton mobilebtn"><a href="javascript:;"
                (click)="goToRegister()" style="color: white; text-decoration: none;">Register Now</a></span>
          </div>
        </div>
      </div>
      <hr style="border-top: 1px dashed Silver;">
    </div>
  </div>
  
  <div class="section" style="padding-top: 0px;">
    <div class="container">
      <div class="row mobile-center" style="margin:0px ;">
        <div class="col-md-6 col-xs-12">
          <img src="./assets/image/LH-Paws2Go.jpg" class="center-block img-responsive img-fluid imgborder">
        </div>
        <div class="col-md-6 col-xs-12">
          <h4 class="text-left mobiletitle"> Fur-Tastic Pets Delivery</h4>
          <p> Safely transporting your beloved pets across these routes in Sabah & Sarawak! </p>
          <p> Our delivery service ensures a smooth and comfortable journey for your furry babies. </p>
          <p>Trust us for reliable and compassionate pet delivery arrangement!</p>
          <p> Click below to know how! </p>

          <div class="input-group-lg btncenter">
            <span type="button" class="sign_up_btn btn btn-dark inputButton mobilebtn"><a href="javascript:;"
                (click)="goToClick()" style="color: white; text-decoration: none;">Click Now</a></span>
          </div>
        </div>
      </div>
      <hr style="border-top: 1px dashed Silver;">
    </div>
  </div>

  <div class="section" style="padding-top: 0px;">
    <div class="container">
      <div class="row mobile-center" style="margin:0px ;">
        <div class="col-md-6 col-xs-12">
          <img src="./assets/image/driver_agent.jpeg" class="center-block img-responsive img-fluid imgborder">
        </div>
        <div class="col-md-6 col-xs-12">
          <h4 class="text-left mobiletitle"> Line Haul Authorised Premier Agent</h4>
          <p> Line Haul is looking for an Authorised Premier Agent (APA) to be our partner in delivering the best pallet
            delivery service in Malaysia. </p>
          <p> Click below to know how! </p>

          <div class="input-group-lg btncenter">
            <span type="button" class="sign_up_btn btn btn-dark inputButton mobilebtn"><a href="javascript:;"
                (click)="goToJoin()" style="color: white; text-decoration: none;">Join Now</a></span>
          </div>
        </div>
      </div>
      <hr style="border-top: 1px dashed Silver;">
    </div>
  </div>


  <div class="section" style="padding-top: 0px;">
    <div class="container">
      <div class="row mobile-center" style="margin:0px ;">
        <div class="col-md-6 col-xs-12">
          <img src="./assets/image/LH-CollaborationTheLorry.jpg"
            class="center-block img-responsive img-fluid imgborder">
        </div>
        <div class="col-md-6 col-xs-12">
          <h4 class="text-left mobiletitle">Line Haul x TheLorry Partnership</h4>
          <p> TheLorry has become an official partner with Line Haul Pallet Express. </p>
          <p> This partnership marks a significant milestone in our commitment to providing the best Pallet Delivery
            services in Malaysia. </p>
          <p> Get the best rate for pallet delivery by clicking below link. </p>

          <div class="input-group-lg btncenter">
            <span type="button" class="sign_up_btn btn btn-dark inputButton mobilebtn">
              <a href="javascript:;" (click)="goToOrder()" style="color: white; text-decoration: none;">Order Now</a>

            </span>
          </div>


          <!-- second button type -->
          <!-- <form (submit)="DriverAgent()">
            <div class="input-group-lg mobile-center" style="text-align: right; padding-top: 10px;">
              <span class="input-group-btn">
                <button class="sign_up_btn btn btn-dark inputButton mobilebtn" type="submit" id="button" value="Click">Order Now<i
                    class="fa fa-flip-horizontal fa-truck"></i></button>
              </span>
            </div>
          </form> -->



        </div>
      </div>
      <!-- <hr style="border-top: 1px dashed Silver;"> -->
    </div>

  </div>


  <app-footer></app-footer>
</div>