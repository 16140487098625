<app-header></app-header>

<div class="section" style="justify-content: center;">
  <div class="background-image background-image-fixed" style="background-image : url('./assets/image/Icons/strength3.png')"></div>

  <div class="container"> 
    <div class="big-box">
    <h4 style="color: white; position: relative;">Our Strength</h4>
      <div class="box">
        <div class="imgBox">
          <img src="./assets/image/Icons/network.png" >
        </div>

        <div class="content">
    
            <h2>Wide Area Network</h2>

            <p>We are strategic business partners with Line Clear Express and Logistic Sdn Bhd by leveraging their infrastructure. A 
              total of 16 hubs and 36 depots in Peninsular Malaysia are well equipped. We enable more delivery area options for shippers 
              and consignees to expand their business coverage. There are more pick-up and delivery locations for transportation owners 
              to choose, boosting their profitability. <br>
              <br> 
              We are following the Personal Data Protection to ensure the customer’s data is well protected. <br>
              <br>
              By using WANs, companies are able to develop their networks through plug-in connections over locations. Besides, WANS 
              offers the highest level of availability and dependability by centralising network management and monitoring of use and 
              performance. <br>
           
            </p>
          </div>
      </div>

      <div class="box">
        <div class="imgBox">
          <img src="./assets/image/Icons/route.jpg">
        </div>

        <div class="content">
         
            <h2>Intelligent Route Planning</h2>

            <p>It provides a welcoming environment for drivers to comprehend and carry out their everyday responsibilities effectively 
              and strategically. GPS enabled automatic route planning that takes the driver's preferences into account. This ensures the 
              driver's safety while operating a vehicle through improving time management, decreasing manual route planning, checking the 
              state of the roads, etc. Business productivity can be increased by considering numerous elements, including time constraints,
              driver shifts, the number of stops, and even stop changes. <br> 
              By delivering goods efficiently, it improves their customer service. 
       
            </p>
          </div>
        </div>



    <div class="box">
      <div class="imgBox">
        <img src="./assets/image/Icons/Schedule.jpg">
      </div>

      <div class="content">
      
          <h2>Fixed Operating Schedule</h2>

          <p>We are aware of the importance of maintaining a high rate of on-time delivery. The use of logistics scheduling software 
            ensures that the shippers and consignees ship their items according to their service requirements. Also, enables drivers 
            to use the most effective routes by pre-planning their schedule. This can greatly enhance our on-time delivery processes, 
            which greatly improves customer’s satisfaction by meeting their expectation of receiving their parcel at the predicted time. 
            In addition, we will be operating 365 days a year to fulfil market demands with different types of services.      
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
