<div class="fit_the_screen">
  <app-header></app-header>

    <!-- The slideshow/carousel -->
    <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000"> 
      <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
          <a href="{{slider.external_link}}"><img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100 desktop-view"></a>
          <a href="{{slider.external_link}}"><img [src]="slider.mobile_url" alt="{{ slider.name }}" class="slideShow-images w-100 mobile-view"></a>

          <!-- <div class="captionBox carousel-caption text-start">
            <div class="captionContent">
              <h3 class="captionTitle">{{ slider.name }}</h3>
              <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            </div>      
          </div> -->
        </div>
      </div>
  
      <!-- Left and right controls/icons -->
      <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div> 
    
  <div class="section">
      
    <!-- first segment -->
    <!-- <div class="container p-0" style="width: 95%;">
      <div> -->
<!-- Laptop or desktop screen size -->
        <!-- <form class="d-none d-sm-block">
          <table class="table table-borderless">
            <thead style="border: none;">
              <tr>
                <th ><label class="form-label text-title" >Track Your Pallet</label></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>

                  <div class="formWithButton input-group mb-3 d-flex" style=" flex-wrap: wrap;">
                    <input type="text" class="form-control form-control-lg multiChoice" placeholder="Enter Tracking Number(s)" aria-label="Recipient's username" style="width: max-content;">
                    <span class="btn inputButton" type="button">Track &#10230;</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        <table class="table table-borderless">
          <thead>

            <tr>
              <th><label for="exampleDataList" class="form-label text-title">Create Your Pallet</label></th>
              <th></th>
            </tr>
            <tr>
              <th><h5 class="text-title">From</h5></th>
              <th><h5 class="text-title">To</h5></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td >
                <div class="input-group d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-map-marker" for="Origin" style="font-size: 20px; padding: 10px;"></label>
                  <input class="form-control multiChoice w-auto" list="originOptions" id="Origin" placeholder="Origin" >
                  <datalist id="originOptions">
                    <option value="San Francisco">
                    <option value="New York">
                    <option value="Seattle">
                    <option value="Los Angeles">
                    <option value="Chicago">
                  </datalist>
                </div>
              </td>
              <td >
                <div class="input-group d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-map-marker" for="Destination" style="font-size: 20px; padding: 10px;"></label>
                  <input class="form-control multiChoice w-auto" list="destinationOptions" id="Destination" placeholder="Destination">
                  <datalist id="destinationOptions">
                    <option value="San Francisco">
                    <option value="New York">
                    <option value="Seattle">
                    <option value="Los Angeles">
                    <option value="Chicago">
                  </datalist>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="input-group d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-archive" for="Service" style="font-size: 15px;"></label>
                <input class="form-control multiChoice w-auto" list="servicesOptions" id="Service" placeholder="Service">
                <datalist id="servicesOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist>
                </div>
              </td>
              <td>
                <div class="input-group mb-3 d-flex multiChoice">
                  <input type="text" class="form-control multiChoice" aria-label="Amount (to the nearest dollar)" placeholder="Quantity Pallet" style="width: auto;">
                  <span class="input-group-text multiChoice fa fa-info-circle bs-tooltip-right" data-bs-toggle="tooltip" data-bs-placement="auto" title="1.1m(L) X 1.1m(W) X 2.1m(H)" aria-hidden="true" style="font-size: 15px; padding: 10px ;"></span>
                </div>
              </td>
            </tr>

          </tbody>
        </table>

        <div class="container" style="display: flex; justify-content: center;">
          <button class="border btn btn-light " type="reset" value="Reset" style="margin-right: 20px; font-size: 20px;">Reset</button>
          <span class="btn inputButton"  type="submit" style="width: 220px; font-size: 20px">Quote &#10230;</span>
        </div>

        </form>
      </div> -->

        <!-- For mobile -->
        <!-- <form class="d-block d-sm-none">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th ><label for="exampleFormControlInput1" class="form-label  text-title ">Track Your Pallet</label></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 300px;">    
                  <div class="formWithButton input-group mb-3 d-flex" style=" flex-wrap: wrap;">
                    <input type="text" class="form-control form-control-lg multiChoice" placeholder="Tracking Number(s)" aria-label="Recipient's username" style="width: 10%; overflow: hidden;">
                    <button class="btn btn-dark inputButton" type="button">Track &#10230;</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          
          <table class="table table-borderless">
            <thead>
    
              <tr>
                <th><label for="exampleDataList" class="form-label"><h5  class="text-title ">Create Your Pallet</h5></label></th>
              </tr>
              <tr>
                <th style="width: 300px;"><h5  class="text-title ">From</h5></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="min-width: 140px;">
                  <div class="input-group d-flex">
                    <label class="input-group-text multiChoice text-center d-flex fa fa-map-marker" for="Origin" style="font-size: 20px; padding: 10px;"></label>
                    <input class="form-control multiChoice w-auto" list="originOptions" id="Origin" placeholder="Origin" >
                    <datalist id="originOptions">
                      <option value="San Francisco">
                      <option value="New York">
                      <option value="Seattle">
                      <option value="Los Angeles">
                      <option value="Chicago">
                    </datalist>
                  </div>
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th style="width: 300px; "><h5 class="text-title ">To</h5></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="min-width: 140px;">
                  <div class="input-group d-flex">
                    <label class="input-group-text multiChoice text-center d-flex fa fa-map-marker" for="Destination" style="font-size: 20px; padding: 10px;"></label>
                    <input class="form-control multiChoice w-auto" list="destinationOptions" id="Destination" placeholder="Destination">
                    <datalist id="destinationOptions">
                      <option value="San Francisco">
                      <option value="New York">
                      <option value="Seattle">
                      <option value="Los Angeles">
                      <option value="Chicago">
                    </datalist>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="min-width: 140px;">
                  <div class="input-group d-flex">
                    <label class="input-group-text multiChoice text-center d-flex fa fa-archive" for="Service" style="font-size: 15px;"></label>
                  <input class="form-control multiChoice w-auto" list="servicesOptions" id="Service" placeholder="Service">
                  <datalist id="servicesOptions">
                    <option value="San Francisco">
                    <option value="New York">
                    <option value="Seattle">
                    <option value="Los Angeles">
                    <option value="Chicago">
                  </datalist>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="min-width: 140px;">
                  <div class="input-group mb-3 d-flex multiChoice">
                    <input type="text" class="form-control multiChoice" aria-label="Amount (to the nearest dollar)" placeholder="Quantity Pallet" style="width: auto;">
                    <span class="input-group-text multiChoice fa fa-info-circle bs-tooltip-right" data-bs-toggle="tooltip" data-bs-placement="auto" title="1.1m(L) X 1.1m(W) X 2.1m(H)" aria-hidden="true" style="font-size: 15px; padding: 10px ;"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="container" style="display: flex; justify-content: center;">
            <button class="border btn btn-light " type="reset" value="Reset" style="margin-right: 1%;">Reset</button>
            <span class="btn inputButton"  type="submit" style="width: 150px;">Quote &#10230;</span>
          </div>
        </form>  
    </div> -->

    <!-- second segment -->
    <div class="section">
        <div class="container d-flex flex-wrap justify-content-center p-0">
          <div>
            <img class="d-none d-md-block" src="../assets/image/highlight_web.png" alt="" width="100%" >
            <img class="d-xs-block d-sm-block d-md-none" src="../assets/image/highlight_mobile.png" alt="" width="100%">
          </div>
            
       
      </div>
    </div>

    <!-- Third segment -->
    <div class="section">
      <div class="container p-0">
        <h5 class="text-title text-center"> Effortless Delivery</h5>
        <div class=" container d-flex flex-wrap justify-content-center p-0">
          <div>
            <img class="d-none d-md-block" src="../assets/image/Icons/homepage_img.png" alt="Apple Vector" width="100%">
          </div>
        </div>

        <div class="d-md-none d-flex flex-wrap justify-content-center p-0" style="width: 100%;">

          <img class="ED-bg-img" src="../assets/image/Icons/Line_Haul_Icon_Pack-22_4.png" alt="Apple Vector">

        <div class="row">
          <div class="card col-6 col-sm-6">
            <img class="ED-img" src="../assets/icons/No.1.png" alt="Apple Vector" width="91px"  height="75px" style=" margin: 10px auto">
            <img class="ED-text" src="../assets/icons/Group 238.png" alt="Apple Vector">          
          </div>

          <div class="card col-6 col-sm-6">
            <img class="ED-img" src="../assets/icons/No.2.png" alt="Apple Vector" width="138px"  height="67px" style="margin: 10px auto">
            <img class="ED-text" src="../assets/icons/Group 239.png" alt="Apple Vector">          
          </div>

          <div class="card col-6 col-sm-6">
            <img class="ED-img" src="../assets/icons/No.3.png" alt="Apple Vector" width="123px"  height="85px" style="margin: 10px auto">
            <img class="ED-text" src="../assets/icons/Group 240.png" alt="Apple Vector">          
          </div>

          <div class="card col-6 col-sm-6">
            <img class="ED-img" src="../assets/icons/No.4.png" alt="Apple Vector" width="139px"  height="84px" style="margin: 10px auto">
            <img class="ED-text" src="../assets/icons/Group 241.png" alt="Apple Vector">          
          </div>

          <div class="card col-6 col-sm-6">
            <img class="ED-img" src="../assets/icons/No.5.png" alt="Apple Vector" width="143px"  height="63px" style="margin: 10px auto">
            <img class="ED-text" src="../assets/icons/Group 243.png" alt="Apple Vector">          
          </div>

          <div class="card col-6 col-sm-6">
            <img class="ED-img" src="../assets/icons/No.6.png" alt="Apple Vector" width="85px"  height="97px" style="margin: 10px auto">
            <img class="ED-text" src="../assets/icons/Group 242.png" alt="Apple Vector">          
          </div>
        </div>
          
        </div>
      </div>
    </div>


    <div class="container p-0">  
      <h5 class="text-title text-center" style="margin-bottom: 5%;">We are your trusted partner in logistics. Join us in redefining the future of transportation and supply chain management. Your journey begins with us.</h5>

      <div class="mobile_Screen_Size">
        <div class="row p-0 m-0">
          <div class="signUpNow-Box" style="background-image: url(../assets/image/Icons/Rectangle_6.png); background-repeat: no-repeat;margin: auto; display: block;">
            <div class="card signUpNow-Card">
              <img src="../assets/icons/Line Haul Icon Pack-25.png" alt="Apple Vector" width="50%" style="margin: auto;">
                <h4 class="text-title text-center">Get Started and Join The Community</h4>
              <span class="signUpNow-btn btn inputButton"  type="submit" style="width: 150px; margin: auto;"><a href="https://pallet.linehaul.com.my/login" style="color: white; text-decoration: none;">Sign Up Now</a></span>
            </div>
          </div>
    
          <!-- <div class="signUpNow-Box" style="background-image: url(../assets/image/Icons/Rectangle_7.png); background-repeat: no-repeat;">
            <div class="card signUpNow-Card">
              <img src="../assets/icons/Line Haul Icon Pack-26 1.png" alt="Apple Vector" width="50%" style="margin: auto;">
                <h4 class="text-title text-center">Join The Community</h4>
              <span class="signUpNow-btn btn inputButton"  type="submit" style="width: 150px; margin: auto;"><a href="https://pallet.linehaul.com.my/login" style="color: white; text-decoration: none;">Sign Up Now</a></span>
            </div>
          </div> -->
        </div>
      </div>

      <div class="desktop_Screen_Size">
        <div class=" row p-0 m-0">
          <div class="col-12">
            <div class="signUpNow-Box w-100" style="background-image: url(../assets/image/Icons/Rectangle_6.png); background-repeat: no-repeat;">
              <div class="card signUpNow-Card">
                <img src="../assets/icons/Line Haul Icon Pack-25.png" alt="Apple Vector" width="50%" style="margin: auto;">
                  <h4 class="text-title text-center">Get Started and Join The Community</h4>
                <button class="signUpNow-btn btn inputButton"  type="submit" style="margin: auto;"><a href="https://pallet.linehaul.com.my/login" style="color: white; text-decoration: none;">Sign Up Now</a></button>
              </div>
            </div>          
          </div>

          <!-- <div class="col-12">
            <div class="signUpNow-Box w-100" style="background-image: url(../assets/image/Icons/Rectangle_7.png); background-repeat: no-repeat;">
              <div class="card signUpNow-Card">
                <img src="../assets/icons/Line Haul Icon Pack-26 1.png" alt="Apple Vector" width="50%" style="margin: auto;">
                  <h4 class="text-title text-center">Join The Community</h4>
                <button class="signUpNow-btn btn inputButton"  type="submit" style="margin: auto;"><a href="https://pallet.linehaul.com.my/login" style="color: white; text-decoration: none;">Sign Up Now</a></button>
              </div>
            </div>          
          </div> -->
        </div>        
      </div>


    </div>

  </div>

  <div class="section pt-0">
    <div class="container" >
    <h4 class="text-title text-center">
    Step into the future of logistics with our all-new apps for seamless booking!
    </h4>
      <div style="margin: auto; justify-content: center; display: flex; flex-wrap: wrap;">
        <a href="https://apps.apple.com/my/app/line-haul/id1664682546">
          <img src="./assets/icons/App_Store_icon_blue.png" class="spacer" alt="Apple" height="50px" width="150px" style="cursor: pointer;">
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.linehaul.customerapp&pcampaignid=web_share">
          <img  src="./assets/icons/Google_Play_icon_blue.png" class="spacer" alt="Google Play" height="50px" width="150px" style="cursor: pointer;">
        </a>
      </div>
  </div>
  </div>


  <app-footer></app-footer>
</div>