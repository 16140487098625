import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin_network.component.html',
  styleUrls: ['./admin_network.component.css']
})
export class AdminNetworkComponent implements OnInit {
  newNetwork: any = {};
  network_lists: any[] = []; 
  isEditing: boolean = false;
  editNetworkId: number | null = null;
  editNetworkDetails: any = {}; 
  currentPage: number = 1;
  itemsPerPage: number = 8; 
  formSubmit: boolean = false;
  formEdit: boolean = false; 
  selectedPage: string = '';

  editForm: FormGroup; 
  form: FormGroup;

  sortBy: string = 'id';
  sortAsc: boolean = true;

  searchTerm: string = '';

  get totalPages() {
    return Math.ceil(this. network_lists.length / this.itemsPerPage);
  }

  get pages() {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  get uniquePages() {
    return Array.from(new Set(this. network_lists.map(item => item.branch)));
  }

  get paginatedData() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredData.slice(start, end);
  }

  get filteredData() {
    if (this.selectedPage) {
      return this.network_lists.filter(item => item.page === this.selectedPage);
    } else {
      return this.network_lists;
    }
  }


  generatePageArray(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private FormsModule: FormsModule

  ) { 
    this.editForm = this.formBuilder.group({
      branch: ['', Validators.required],
      address: ['', Validators.required],
      tel: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    this.form = this.formBuilder.group({
      branch: ['', Validators.required],
      address: ['', Validators.required],
      tel: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.loadNetwork();
  }
  
  loadNetwork(): void {
    this.apiService.loadNetwork().subscribe(
      (response) => {
        console.log('API Response:', response);
        this. network_lists = response.network_lists;
        this.applySort(); 
        // this.applyFilter(); 

      },
      (error) => {
        console.error('Error fetching network:', error);
      }
    );
  }

  applyFilter(): void {
    if (this.searchTerm.trim() !== '') {
      this.network_lists = this.network_lists.filter(
        (data) =>
          data.branch.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          data.address.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          data.tel.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          data.email.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    } else {
      this.loadNetwork();
    }
    this.applySort(); 
  }
  
  sortByColumn(column: string) {
    if (this.sortBy === column) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortBy = column;
      this.sortAsc = true;
      this.currentPage = 1; 
    }
    this.applySort();
  }

  applySort() {
    this.network_lists.sort((a, b) => {
      const order = this.sortAsc ? 1 : -1;
      return a[this.sortBy] > b[this.sortBy] ? order : -1 * order;
    });
  
    this.currentPage = 1;
  }
  
  //-----------ADD, EDIT, REMOVE data---------------------------------------------
  addNetwork() {
    this.apiService.store('network_list', this.newNetwork).subscribe(
      () => {
        this.newNetwork = {};
        this.loadNetwork();
      },
      (error) => {
        console.error('Error adding network entry:', error);
      }
    );
    this.formSubmit = true; 
  }

  editNetwork(network: any) {
    this.isEditing = true;
    this.editNetworkId = network.id;
    this.editForm.patchValue(network);
  }
  
  saveEdit() {
    this.apiService.update('network_list', this.editNetworkId, this.editForm.value).subscribe(
      () => {
        this.isEditing = false;
        this.editNetworkId = null;
        this.editForm.reset();
        this.loadNetwork();
      },
      (error) => {
        console.error('Error updating network entry:', error);
      }
    );
    this.formEdit = true; 
  }
  
  cancelEdit() {
    this.isEditing = false;
    this.editNetworkId = null;
    this.editNetworkDetails = {};
  }

  deleteNetwork(id: number) {
    this.apiService.destroy('network_list', id).subscribe(
      () => {
        this.loadNetwork();
      },
      (error) => {
        console.error('Error deleting network entry:', error);
      }
    );
  }
}
