<div class="fit_the_screen">
  <app-header></app-header>

   <!-- The slideshow/carousel -->
   <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>      
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div> 

  <div class="section">
    <div class="container p-0 d-none d-md-block">
    <h4 class="text-title text-center">Milestone</h4>
    <div style="margin: 10%;">
      <img src="../assets/image/Milestone_diagram.png" width="100%">
    </div>
  </div>


  <div class="container p-0 d-block d-md-none">
    <h4 class="text-title text-center">Milestone</h4>
    <div style="margin: 10%;">
      <img src="../assets/image/Milestone_diagram_mobile.png" width="100%">
    </div>
  </div>
  </div>


  <app-footer></app-footer>
</div>