import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser'; 

@Component({
  selector: 'app-home',
  templateUrl: './duplicated_booking.component.html',
  styleUrls: ['./duplicated_booking.component.css']
})
export class DuplicatedBookingComponent implements OnInit {

  constructor(
    private route: Router,
    private viewportScroller: ViewportScroller,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
        this.titleService.setTitle("About Us | Line Haul")

        const metaDesc = this.metaService.getTag('name=description'); 

        if(!metaDesc){
            this.metaService.addTags([
                {name: "description", content: "Linehaul is an organization lead by a team of highly enthusiastic individuals whom are committed, innovative and passionate in continuously adding value to our business industry. Our Vision is to be the market leader in logistic innovation, providing execution efficiency and accessibility to our customers, henceforth, carrying the Mission to constantly increase our value proposition in the context of secured, price competitiveness and haulage excellence. The company organization structure is helmed by 4 key departments in which, are supervised by industry captains whom will navigate the operation towards business excellence. The mentioned departments are Operation Excellence, Commercial (Sales & Marketing), Information System & Technology, (IS&T), Strategic Planning and Finance. In Linehaul, our team is committed and motivated towards product innovation to cater for different market segments and its demand, hence investing extensively in logistic Research & Development initiatives to sustain market dynamics and maintain our competitive advantages. Begin your business journey with us, your entrusted Logistic Partner!"}
            ]);
        }else{
            this.metaService.updateTag(  
                {name: "description", content: "Linehaul is an organization lead by a team of highly enthusiastic individuals whom are committed, innovative and passionate in continuously adding value to our business industry. Our Vision is to be the market leader in logistic innovation, providing execution efficiency and accessibility to our customers, henceforth, carrying the Mission to constantly increase our value proposition in the context of secured, price competitiveness and haulage excellence. The company organization structure is helmed by 4 key departments in which, are supervised by industry captains whom will navigate the operation towards business excellence. The mentioned departments are Operation Excellence, Commercial (Sales & Marketing), Information System & Technology, (IS&T), Strategic Planning and Finance. In Linehaul, our team is committed and motivated towards product innovation to cater for different market segments and its demand, hence investing extensively in logistic Research & Development initiatives to sustain market dynamics and maintain our competitive advantages. Begin your business journey with us, your entrusted Logistic Partner!" },  
                "name=description"  
            )  
        }
  }

  
  goToPremium(){
    window.open('https://linehaul.com.my/assets/image/Icons/premium.jpg', '_blank');
  } 
  
  goToExpress(){
    window.open('https://linehaul.com.my/assets/image/Icons/express.jpg', '_blank');
  } 

  
  public onClick(elementId: string): void { 

    this.viewportScroller.scrollToAnchor(elementId);
    
    //   window.location.href = '/v2/landing#AnchorId';
  }
  
  public goToFragment(fragment: string): void{

    // let elementId = 'serviceId'
    
    // this.route.navigate( ['/landing#'], {fragment: fragment});
    // this.route.navigate(['/landing#'], { fragment: 'serviceId' });
    // this.route.navigate(['/landing#'+fragment])
    // this.redirectTo('/landing#serviceId');
    // window.location.href = '/v2/landing#' + fragment;
    // window.location.href = '/landing#' + fragment;
    // window.location.href = 'https://lineclearexpress.com/v2/landing#serviceId'
    // window.location.replace("http://someUrl.com");
    // this.viewportScroller.scrollToAnchor(elementId);

    // production
    // this.route.navigateByUrl("/v2/landing#serviceId")

    // staging 
    this.route.navigateByUrl("/#serviceId")
    
  }

  goToAboutUs(){
    this.route.navigate(['about-us']);
  }

  goToOurStrength(){
    this.route.navigate(['our_strength']);
  }

  goToHome(){
    this.route.navigate(['home']);
  }

  goToMilestone(){
    this.route.navigate(['milestone']);
  }

  goToWhyUs(){
    this.route.navigate(['why_us']);
  }

  goToNewBooking(){
    this.route.navigate(['new_booking']);
  }

  goToDuplicatedBooking(){
    this.route.navigate(['duplicated_booking']);
  }


}


