<div class="fit_the_screen">
  <app-header></app-header>
  <h1>Frequently Asked Questions (FAQ)</h1>

  <div class="faq-container">
    <div *ngFor="let topic of groupedFaqs" class="faq-topic">
      <h2>{{ topic.topic }}</h2>

      <div *ngFor="let qa of topic.qaList" class="faq-item" (click)="toggleAnswer(qa.id)">
        <div class="faq-question">
          <span>{{ qa.number }}. {{ qa.question }}</span>
          <i class="fas" [ngClass]="{'fa-chevron-down': !answersVisible[qa.id], 'fa-chevron-up': answersVisible[qa.id]}"></i>
        </div>
        <div class="faq-answer" [class.active]="answersVisible[qa.id]">{{ qa.answer }}</div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
