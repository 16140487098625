import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyPolicyComponent } from './privacy_policy/privacy_policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HomeComponent } from './home/home.component';
import { OurStrengthComponent} from './our_strength/our_strength.component';
import { OurTeamComponent } from './our_team/our_team.component';
import { MilestoneComponent } from './milestone/milestone.component';
import { CertificateComponent } from './certificate/certificate.component';
import { OurEventsComponent } from './our_events/our_events.component';
import { CharteredTransportComponent } from './chartered_transport/chartered_transport.component';
import { PalletDeliveryComponent } from './fcl_ltl_pallet_delivery/fcl_ltl_pallet_delivery.component';
import { CrossBorderComponent } from './cross_border/cross_border.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { WhyUsComponent } from './why_us/why_us.component';
import { PersonalComponent } from './personal/personal.component';
import { CareerComponent } from './career/career.component';
import { SchedulesComponent } from './schedule/schedules.component';
import { NewBookingComponent } from './new_booking/new_booking.component';
import { DuplicatedBookingComponent } from './duplicated_booking/duplicated_booking.component';
import { NetworkComponent } from './network/network.component';
import { AdminComponent } from './admin/admin.component';
import { AdminNetworkComponent } from './admin_network/admin_network.component';
import { AdminHeaderComponent } from './admin_header/admin_header.component';
import { AdminCarouselComponent } from './admin_carousel/admin_carousel.component';
import { AdminFaqComponent } from './admin_faq/admin_faq.component';
import { FaqComponent } from './faq/faq.component';
import { PromotionComponent } from './promotion/promotion.component';

const routes: Routes = [

    {
        path: "",
        component: HomeComponent
    },

    { 
        path: "about-us", 
        component: AboutUsComponent
    },

    { 
        path: "header", 
        component: HeaderComponent
    },

    {
        path: "home",
        component: HomeComponent
    },
    
    { 
        path: "contactus", 
        component: ContactComponent
    },

    { 
        path: "privacy_policy", 
        component: PrivacyPolicyComponent
    },

    { 
        path: "about-us",
        component: AboutUsComponent
    },

    {
        path: "milestone",
        component: MilestoneComponent
    },

    {
        path: "certificate",
        component: CertificateComponent
    },

    {
        path: "our_events",
        component: OurEventsComponent
    },

    {
        path: "chartered_transport",
        component: CharteredTransportComponent
    },

    {
        path: "fcl_ltl_pallet_delivery",
        component: PalletDeliveryComponent
    },

    {
        path: "cross_border",
        component: CrossBorderComponent
    },

    {
        path: "warehouse",
        component: WarehouseComponent
    },

    {
        path: "why_us",
        component: WhyUsComponent
    },

    {
        path: "personal",
        component: PersonalComponent
    },

    {
        path: "career",
        component: CareerComponent
    },

    {
        path: "schedule",
        component: SchedulesComponent
    },

    {
        path: "new_booking",
        component: NewBookingComponent
    },

    {
        path: "duplicated_booking",
        component: DuplicatedBookingComponent
    },

    {
        path: "network",
        component: NetworkComponent
    },

    { 
        path: "our_strength",
        component: OurStrengthComponent
    },

    { 
        path: "our_team",
        component: OurTeamComponent
    },

    {
        path: "admin",
        component: AdminComponent
    },

    {
        path: "admin_header", 
        component: AdminHeaderComponent
    },

    {
        path: "admin_network",
        component: AdminNetworkComponent
    },

    {
        path: "admin_carousel", 
        component: AdminCarouselComponent
    },

    {
        path: "admin_faq", 
        component: AdminFaqComponent
    },

    {
        path: "faq", 
        component: FaqComponent
    },
    {
        path: "promotion", 
        component: PromotionComponent
    },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
                                            scrollPositionRestoration: 'enabled', 
                                            anchorScrolling: 'enabled',
                                            onSameUrlNavigation: 'reload'
                                        })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
