<div class="fit_the_screen">
  <app-header></app-header>

  <!-- The slideshow/carousel -->
  <div id="slideShow" class="carousel slide " data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../assets/image/Temporary_slide_img_3.png" alt="Los Angeles" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">Events</h3>
            <p class="slideShow_Caption text-start">Since its inception in 2020, Line Haul has been on a relentless mission to redefine the logistics landscape. From day one, our commitment to excellence has propelled us forward, and we've been steadfastly delivering innovative and reliable logistics solutions ever since. </p>
            <span type="button" class="d-none learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span>
          </div>      
        </div>
      </div>

      <!-- <div class="carousel-item">
        <img src="../assets/image/Temporary_slide_img_2.png" alt="Los Angeles" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">Los Angeles</h3>
            <p class="slideShow_Caption text-start CropLongTexts-2line">Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. </p>
            <span type="button" class="d-none learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span>
          </div>      
        </div>
      </div>

      <div class="carousel-item">
        <img src="../assets/image/Temporary_slide_img_3.png" alt="Los Angeles" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">Los Angeles</h3>
            <p class="slideShow_Caption text-start CropLongTexts-2line">Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. </p>
            <span type="button" class="d-none learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span>
          </div>      
        </div>
      </div>

      <div class="carousel-item">
        <img src="../assets/image/Temporary_slide_img_4.png" alt="Los Angeles" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">Los Angeles</h3>
            <p class="slideShow_Caption text-start CropLongTexts-2line">Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. </p>
            <span type="button" class="d-none learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span>
          </div>      
        </div>
      </div> -->

    </div>
    
    <!-- Left and right controls/icons -->
    <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <div class="section">

    <h4 class="text-title text-center">Our Events</h4>

    <!-- laptop or desktop -->
    <div class="container d-flex p-0" style="flex-wrap: wrap; width: 95%;"> 
      <div class="d-none d-md-block">
        <div class="our_event-box container d-flex">
          <h5 class="our_event-title" style="margin: auto; padding-right: 20px;">16/04/2023</h5>
          <img src="../assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="our_event-images d-block">
        <div class="our_event-content">
          <div>
          <h5 class="our_event-title">Title</h5>
          <p class="text-start CropLongTexts-2line">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div> 
        </div>

        <div style="margin: auto;">
          <span type="button" class="learnMoreBtn btn btn-light btn-lg" (click)="goToOurStrength()">Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
        </div>
      </div>


      <div class="our_event-box container d-flex">
        <h5 class="our_event-title" style="margin: auto; padding-right: 20px;">16/04/2023</h5>
        <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="our_event-images d-block">
      <div class="our_event-content"> 
        <div>
        <h5 class="our_event-title">Title</h5>
        <p class="text-start CropLongTexts-2line">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
     </div>
    </div>

      <div style="margin: auto;">
        <span type="button" class="learnMoreBtn btn btn-light btn-lg" (click)="goToOurStrength()">Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
      </div>
    </div>


      <div class="our_event-box container d-flex">
        <h5 class="our_event-title" style="margin: auto; padding-right: 20px;">16/04/2023</h5>
        <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="our_event-images d-block">
      <div class="our_event-content" >
        <div>
         <h5 class="our_event-title">Title</h5>
          <p class="text-start CropLongTexts-2line">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
        </div> 
      </div>

      <div style="margin: auto;">
        <span type="button" class="learnMoreBtn btn btn-light btn-lg" (click)="goToOurStrength()">Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
      </div>
    </div>

    <div class="our_event-box container d-flex">
      <h5 class="our_event-title" style="margin: auto; padding-right: 20px;">16/04/2023</h5>
      <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="our_event-images d-block">
    <div class="our_event-content"> 
      <div>
      <h5 class="our_event-title">Title</h5>
      <p class="text-start CropLongTexts-2line">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
      </div>
  </div>

    <div style="margin: auto;">
      <span type="button" class="learnMoreBtn btn btn-light btn-lg" (click)="goToOurStrength()">Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
    </div>
    </div>

      </div>
    </div>

    <!-- mobile -->
    <div class="justify-content-center m-auto d-flex w-70 d-block d-md-none" style="flex-wrap: wrap; padding: 3%;">
      <div class="our_event-box container d-flex">
        <h5 class="our_event-date text-center" style="margin: auto; padding-right: 20px;">16/04/<br>2023</h5>
        <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="our_event-images d-block">
        <div class="our_event-content">
          <div>
            <h5 class="our_event-title">Title</h5>
            <p class="text-start CropLongTexts-2line">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div> 
        </div>

        <div style="margin: auto;">
        <span type="button" class="learnMoreBtn-simple btn btn-light fw-bold" (click)="goToOurStrength()"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
        </div>
      </div>

      <div class="our_event-box container d-flex">
        <h5 class="our_event-date text-center" style="margin: auto; padding-right: 20px;">16/04/<br>2023</h5>
        <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="our_event-images d-block">
        <div class="our_event-content">
          <div>
            <h5 class="our_event-title">Title</h5>
            <p class="text-start CropLongTexts-2line">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div> 
        </div>

        <div style="margin: auto;">
        <span type="button" class="learnMoreBtn-simple btn btn-light fw-bold" (click)="goToOurStrength()"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
        </div>
      </div>

      <div class="our_event-box container d-flex">
        <h5 class="our_event-date text-center" style="margin: auto; padding-right: 20px;">16/04/<br>2023</h5>
        <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="our_event-images d-block">
        <div class="our_event-content">
          <div>
            <h5 class="our_event-title">Title</h5>
            <p class="text-start CropLongTexts-2line">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div> 
        </div>

        <div style="margin: auto;">
        <span type="button" class="learnMoreBtn-simple btn btn-light fw-bold" (click)="goToOurStrength()"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
        </div>
      </div>

      <div class="our_event-box container d-flex">
        <h5 class="our_event-date text-center" style="margin: auto; padding-right: 20px;">16/04/<br>2023</h5>
        <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="our_event-images d-block">
        <div class="our_event-content">
          <div>
            <h5 class="our_event-title">Title</h5>
            <p class="text-start CropLongTexts-2line">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div> 
        </div>

        <div style="margin: auto;">
        <span type="button" class="learnMoreBtn-simple btn btn-light fw-bold" (click)="goToOurStrength()"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
        </div>
      </div>
    </div>


  <!-- news and updates segment -->
  <div class="section">
      <div class="container p-0">
        <h4 class="text-title text-center">News and Updates</h4>
        <div id="news_accordion">

          <div class="card">
            <div class="card-header">
              <a class="News-Heading btn vissually-hidden" data-bs-toggle="collapse" href="#news_collapseOne">
                <span class="text-title droptext-mobile pt-2 pb-2">Lorem ipsum<span class="dropdown-arrow-mobile">&#10093;</span></span>
              </a>
            </div>
            <div id="news_collapseOne" class="collapse" data-bs-parent="#news_accordion">
              <div class="card-body">
                <h4 class="text-title">Posuere sollicitudin aliquam</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <a class="btn News-Heading" data-bs-toggle="collapse" href="#news_collapseTwo">
                <span class="text-title droptext-mobile pt-2 pb-2">Lorem ipsum<span class="dropdown-arrow-mobile">&#10093;</span></span>
              </a>
            </div>
            <div id="news_collapseTwo" class="collapse" data-bs-parent="#news_accordion">
              <div class="card-body">
                <h4 class="text-title">Posuere sollicitudin aliquam</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <a class="btn News-Heading" data-bs-toggle="collapse" href="#news_collapseThree">
                <span class="text-title droptext-mobile pt-2 pb-2">Lorem ipsum<span class="dropdown-arrow-mobile">&#10093;</span></span>
              </a>
            </div>
            <div id="news_collapseThree" class="collapse" data-bs-parent="#news_accordion">
              <div class="card-body">
                <h4 class="text-title">Posuere sollicitudin aliquam</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <a class="btn News-Heading" data-bs-toggle="collapse" href="#news_collapseFour">
                <span class="text-title droptext-mobile pt-2 pb-2">Lorem ipsum<span class="dropdown-arrow-mobile">&#10093;</span></span>
              </a>
            </div>
            <div id="news_collapseFour" class="collapse" data-bs-parent="#news_accordion">
              <div class="card-body">
                <h4 class="text-title">Posuere sollicitudin aliquam</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <a class="btn News-Heading" data-bs-toggle="collapse" href="#news_collapseFive">
                <span class="text-title droptext-mobile pt-2 pb-2">Lorem ipsum<span class="dropdown-arrow-mobile">&#10093;</span></span>
              </a>
            </div>
            <div id="news_collapseFive" class="collapse" data-bs-parent="#news_accordion">
              <div class="card-body">
                <h4 class="text-title">Posuere sollicitudin aliquam</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <a class="btn News-Heading" data-bs-toggle="collapse" href="#news_collapseSix">
                <span class="text-title droptext-mobile pt-2 pb-2">Lorem ipsum<span class="dropdown-arrow-mobile">&#10093;</span></span>
              </a>
            </div>
            <div id="news_collapseSix" class="collapse" data-bs-parent="#news_accordion">
              <div class="card-body">
                <h4 class="text-title">Posuere sollicitudin aliquam</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                </div>
            </div>
          </div>

          

        </div>
      </div>  
  </div>

  </div>

  <app-footer></app-footer>
</div>