<div class="fit_the_screen">
  <app-header></app-header>

  <!-- The slideshow/carousel -->
  <div id="slideShow" class="carousel slide " data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../assets/image/Temporary_slide_img_2.png" alt="Los Angeles" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">Los Angeles</h3>
            <p class="slideShow_Caption text-start CropLongTexts-2line">Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. </p>
            <span type="button" class="d-none learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span>
          </div>      
        </div>
      </div>

      <!-- <div class="carousel-item">
        <img src="../assets/image/Temporary_slide_img_2.png" alt="Los Angeles" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">Los Angeles</h3>
            <p class="slideShow_Caption text-start CropLongTexts-2line">Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. </p>
            <span type="button" class="d-none learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span>
          </div>      
        </div>
      </div>

      <div class="carousel-item">
        <img src="../assets/image/Temporary_slide_img_3.png" alt="Los Angeles" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">Los Angeles</h3>
            <p class="slideShow_Caption text-start CropLongTexts-2line">Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. </p>
            <span type="button" class="d-none learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span>
          </div>      
        </div>
      </div>

      <div class="carousel-item">
        <img src="../assets/image/Temporary_slide_img_4.png" alt="Los Angeles" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">Los Angeles</h3>
            <p class="slideShow_Caption text-start CropLongTexts-2line">Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Mi eget mauris pharetra et. </p>
            <span type="button" class="d-none learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span>
          </div>      
        </div>
      </div> -->

    </div>
    
    <!-- Left and right controls/icons -->
    <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <div class="section">
      <h4 class="text-title text-center">Certificates</h4>

      <!-- laptop or desktop -->
      <div class="container d-flex p-0" style="flex-wrap: wrap; width: 95%;">
        
        <div class="d-none d-md-block">
          <div class="certificate-box container d-flex">
              <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
            <div class="certificate-content"> 
              <h5 class="certificate-title">Title</h5>
              <p class="text-start  CropLongTexts-2line">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
            </div>

            <div style="margin: auto;">
              <span type="button" class="learnMoreBtn btn btn-light" (click)="goToOurStrength()">Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i> </span>
            </div>
          </div>


          <div class="certificate-box container d-flex">
              <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
            <div class="certificate-content"> 
              <h5 class="certificate-title">Title</h5>
              <p class="text-start  CropLongTexts-2line">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
            </div>

            <div style="margin: auto;">
              <span type="button" class="learnMoreBtn btn btn-light" (click)="goToOurStrength()">Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
            </div>
          </div>


          <div class="certificate-box container d-flex">
              <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
            <div class="certificate-content"> 
              <h5 class="certificate-title">Title</h5>
              <p class="text-start  CropLongTexts-2line">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
            </div>

            <div style="margin: auto;">
              <span type="button" class="learnMoreBtn btn btn-light" (click)="goToOurStrength()">Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
            </div>
          </div>

          <div class="certificate-box container d-flex">
              <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
            <div class="certificate-content"> 
              <h5 class="certificate-title">Title</h5>
              <p class="text-start  CropLongTexts-2line">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
            </div>

            <div style="margin: auto;">
              <span type="button" class="learnMoreBtn btn btn-light" (click)="goToOurStrength()">Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
            </div>
          </div>

          <div class="vertical-line">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <!-- Add more dots as needed -->
          </div>

        </div>
      </div>

      <!-- mobile -->
      <div class="container d-block d-md-none" style="flex-wrap: wrap;">
        <!-- <div class="certificate-box container d-flex">
              <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
            <div style="padding-left: 20px;"> 
              <h5 class="certificate-title">Title</h5>
              <p class="text-start">
                Lorem ipsum dolor ........</p>
            </div>

            <div style="justify-content: center; margin: auto; margin-left: 10px; margin-right: 10px;">
              <span type="button" class="learnMoreBtn-simple btn btn-light" (click)="goToOurStrength()">&#10230;</span>
            </div>
        </div> -->

        
        <div class="vertical-line">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <!-- Add more dots as needed -->
        </div>

        <div class="certificate-box container d-flex">
          <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
        <div class="certificate-content"> 
          <h5 class="certificate-title">Title</h5>
          <p class="text-start CropLongTexts-2line">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div>

          <div style="margin: auto;">
          <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </div>
        </div>

        <div class="certificate-box container d-flex">
          <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
        <div class="certificate-content"> 
          <h5 class="certificate-title">Title</h5>
          <p class="text-start CropLongTexts-2line">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div>

          <div style="margin: auto;">
          <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </div>
        </div>

        <div class="certificate-box container d-flex">
          <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
        <div class="certificate-content"> 
          <h5 class="certificate-title">Title</h5>
          <p class="text-start CropLongTexts-2line">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div>

          <div style="margin: auto;">
          <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </div>
        </div>

        <div class="certificate-box container d-flex">
          <img src="./assets/image/Icons/Cargo with Foremen M.png" alt="New York" class="certificate-images d-block">
        <div class="certificate-content"> 
          <h5 class="certificate-title">Title</h5>
          <p class="text-start CropLongTexts-2line">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.              </p>
          </div>

          <div style="margin: auto;">
          <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </div>
        </div>

      </div>
  </div>


  <app-footer></app-footer>
</div>