
// Core Module
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// External Module 
import { NgxSpinnerModule } from "ngx-spinner";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LivechatWidgetModule } from '@livechat/angular-widget';
import { ReactiveFormsModule } from '@angular/forms';


// Component 
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyPolicyComponent } from './privacy_policy/privacy_policy.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurStrengthComponent } from './our_strength/our_strength.component';
import { OurTeamComponent } from './our_team/our_team.component';
import { MilestoneComponent } from './milestone/milestone.component';
import { CertificateComponent } from './certificate/certificate.component';
import { OurEventsComponent } from './our_events/our_events.component';
import { CharteredTransportComponent } from './chartered_transport/chartered_transport.component';
import { PalletDeliveryComponent } from './fcl_ltl_pallet_delivery/fcl_ltl_pallet_delivery.component';
import { CrossBorderComponent } from './cross_border/cross_border.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { WhyUsComponent } from './why_us/why_us.component';
import { PersonalComponent } from './personal/personal.component';
import { CareerComponent } from './career/career.component';
import { SchedulesComponent } from './schedule/schedules.component';
import { NewBookingComponent } from './new_booking/new_booking.component';
import { DuplicatedBookingComponent } from './duplicated_booking/duplicated_booking.component';
import { NetworkComponent } from './network/network.component';
import { AdminComponent } from './admin/admin.component';
import { AdminNetworkComponent } from './admin_network/admin_network.component';
import { AdminHeaderComponent } from './admin_header/admin_header.component';
import { AdminCarouselComponent } from './admin_carousel/admin_carousel.component';
import { AdminFaqComponent } from './admin_faq/admin_faq.component';
import { FaqComponent } from './faq/faq.component';
import { PromotionComponent } from './promotion/promotion.component';

// Animation 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    HomeComponent,
    AboutUsComponent,
    MilestoneComponent,
    CertificateComponent,
    OurEventsComponent,
    OurStrengthComponent,
    OurTeamComponent,
    CharteredTransportComponent, 
    PalletDeliveryComponent,
    CrossBorderComponent,
    WarehouseComponent,
    WhyUsComponent,
    PersonalComponent,
    CareerComponent,
    SchedulesComponent,
    NewBookingComponent,
    DuplicatedBookingComponent,
    NetworkComponent,
    AdminComponent,
    AdminHeaderComponent,
    AdminCarouselComponent,
    AdminNetworkComponent,
    AdminFaqComponent,
    FaqComponent,
    PromotionComponent
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    LivechatWidgetModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    ReactiveFormsModule,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }