import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title, Meta } from '@angular/platform-browser'; 

import Swal from 'sweetalert2'

@Component({
  selector: 'app-our_strength',
  templateUrl: './our_strength.component.html',
  styleUrls: ['./our_strength.component.css']
})
export class OurStrengthComponent implements OnInit {

    branchLocator:any;
    postcode:any = "";
    visible:boolean = false;
    isAuto:boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private route: Router,
    private apiService: ApiService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
    this.spinner.show();

    this.titleService.setTitle("Our Strength | Line Haul")

    const metaDesc = this.metaService.getTag('name=description'); 

    if(!metaDesc){
        this.metaService.addTags([
            {name: "description", content: ""}
        ]);
    }else{
        this.metaService.updateTag(  
            { name: "description", content: "" },  
            "name=description"  
          )  
    }

    window.scrollTo(0, 0)
  }

  showSpinner() {
    // visible return true 
    this.visible = true;
    

    // calling function after 2 second 
    setTimeout(() => {;
        this.hideSpinner()
    }, 1500);
  }

  hideSpinner(){
        // visible return false 
        this.visible = false;
  }

}