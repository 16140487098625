<div class="fit_the_screen">
  <app-header></app-header>

   <!-- The slideshow/carousel -->
   <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>      
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div> 

  <div class="section">
  <!-- mobile -->
    <div class="d-block d-md-none">
      <h4 class="text-title text-center">Why Us</h4>
        <div class="container-fluid p-0 d-flex justify-content-center" style="padding-left: 0px; padding-right: 0px; width: auto;" >
          <div class="row" style="width: 99%;">

            <div class="card-box justify-content-center d-flex">
              <div class="card card-mobile">
                <div class="card-img-box">
                  <img class="card-img-top card-img" src="../assets/icons/LineHaul_Why_Us_3.png" alt="Card image">
                </div>
                <div class="card-body green_hover">
                  <div>
                    <h4 class="card-title text-title text-center" style="width: auto;">Robust Infrastructure for seamless<br>Connectivity</h4>
                    <ul>
                      <li>Strategic partnership with Line Clear Express and Logistics Sdn Bhd.</li>
                      <li>Leverage 16 hubs and 36 depots for extensive connectivity.</li>
                      <li>More delivery options, enhancing business coverage and profitability.</li>
                      <li>Commitment to Personal Data Protection for secure customer data handling.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-box justify-content-center d-flex">
              <div class="card card-mobile">
                <div class="card-img-box">
                <img class="card-img-top card-img" src="../assets/icons/LineHaul_Why_Us_2.png" alt="Card image">
                </div>
                  <div class="card-body green_hover">
                    <div class="">
                      <h4 class="card-title text-title text-center" style="width: auto;">Intelligent Route Planning for Optimal Efficiency</h4>
                      <ul>
                        <li>Environment for strategic execution of daily tasks for drivers.</li>
                        <li>GPS-enabled automatic route planning with driver preferences.</li>
                        <li>Prioritizes safety, improves time management, and reduces manual efforts.</li>
                        <li>Enhances business productivity by considering time constraints, driver shifts, stops, and route changes.</li>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>

              <div class="card-box justify-content-center d-flex">
                <div class="card card-mobile">
                  <div class="card-img-box">
                    <img class="card-img-top card-img" src="../assets/icons/LineHaul_Why_Us_1.png" alt="Card image">
                  </div>
                    <div class="card-body green_hover">
                      <div>
                        <h4 class="card-title text-title text-center" style="width: auto;">Fixed Operating Schedule for On-Time
                          Deliveries</h4>
                          <ul>
                            <li>Utilization of logistics schedule software for adherence to service requirements.</li>
                            <li>Empowers drivers to pre-plan routes for optimal efficiency.</li>
                            <li>Operational 365 days a year to meet market demands with diverse services.</li>
                            <li>Ensures on time deliveries, providing a reliable and consistent customer experience.</li>
                          </ul>
                      </div>
                    </div>
                  </div>
              </div>
          </div>

      </div>
    </div>

    <!-- laptop/desktop -->
    <div class="d-none d-md-block">
      <h4 class="text-title text-center">Why Us</h4>
        <div class="container-fluid p-0 d-flex justify-content-start" style="padding-left: 0px; padding-right: 0px; width: auto;" >

          <div class="row" style="width: 99%; margin: 0px;">

            <div class="card-box col-md-6 justify-content-end d-flex">
              <div class="card card-desktop" style=" margin: 5%;">
                <div class="card-img-box">
                  <img class="card-img-top card-img" src="../assets/icons/LineHaul_Why_Us_3.png" alt="Card image">
                </div>
                <div class="card-body green_hover">
                  <div>
                    <h4 class="card-title text-title text-center" style="width: auto;">Robust Infrastructure for seamless<br>Connectivity</h4>
                    <ul>
                      <li>Strategic partnership with Line Clear Express and Logistics Sdn Bhd.</li>
                      <li>Leverage 16 hubs and 36 depots for extensive connectivity.</li>
                      <li>More delivery options, enhancing business coverage and profitability.</li>
                      <li>Commitment to Personal Data Protection for secure customer data handling.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-box col-md-6 justify-content-start d-flex">
              <div class="card card-desktop" style=" margin: 5%;">
                <div class="card-img-box">
                <img class="card-img-top card-img" src="../assets/icons/LineHaul_Why_Us_2.png" alt="Card image">
                </div>
                  <div class="card-body green_hover">
                    <div class="">
                      <h4 class="card-title text-title text-center" style="width: auto;">Intelligent Route Planning for Optimal <br> Efficiency</h4>
                      <ul>
                        <li>Environment for strategic execution of daily tasks for drivers.</li>
                        <li>GPS-enabled automatic route planning with driver preferences.</li>
                        <li>Prioritizes safety, improves time management, and reduces manual efforts.</li>
                        <li>Enhances business productivity by considering time constraints, driver shifts, stops, and route changes.</li>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>

              <div class="card-box col-md-12 justify-content-center d-flex">
                <div class="card card-desktop" style=" margin: 3%;">
                  <div class="card-img-box">
                    <img class="card-img-top card-img" src="../assets/icons/LineHaul_Why_Us_1.png" alt="Card image">
                  </div>
                    <div class="card-body green_hover">
                      <div>
                        <h4 class="card-title text-title text-center" style="width: auto;">Fixed Operating Schedule for On-Time <br>
                          Deliveries</h4>
                          <ul>
                            <li>Utilization of logistics schedule software for adherence to service requirements.</li>
                            <li>Empowers drivers to pre-plan routes for optimal efficiency.</li>
                            <li>Operational 365 days a year to meet market demands with diverse services.</li>
                            <li>Ensures on time deliveries, providing a reliable and consistent customer experience.</li>
                          </ul>
                      </div>
                    </div>
                  </div>
              </div>
          </div>

      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>