<div class="fit_the_screen">
  <app-header></app-header>

  <!-- The slideshow/carousel -->
  <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>      
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div> 

  <div class="section">

    <h4 class="text-title text-center">Explore Your Route, Unleash Efficiency!</h4>

    <!-- first segment -->
    <div class="container p-0">
      <div>

        <!-- desktop/laptop -->
      <form class="d-none d-sm-block">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th><h5 class="text-title">From</h5></th>
              <th><h5 class="text-title">To</h5></th>
            </tr>
          </thead>
          <tbody>

            <!-- first row -->

            <tr>
              <td style="width: 50%;">
                <!-- <input class="form-control multiChoice" list="originOptions" id="Origin" placeholder="Origin" >
                <datalist id="originOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist> -->
                <div class="input-group d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-map-marker" for="Origin" style="font-size: 20px; padding: 10px;"></label>
                  <input class="form-control multiChoice w-auto" list="originOptions" id="Origin" placeholder="Origin" >
                  <datalist id="originOptions">
                    <option value="San Francisco">
                    <option value="New York">
                    <option value="Seattle">
                    <option value="Los Angeles">
                    <option value="Chicago">
                  </datalist>
                </div>
              </td>
              <td >
                <!-- <input class="form-control multiChoice" list="destinationOptions" id="Destination" placeholder="Destination">
                <datalist id="destinationOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist> -->
                <div class="input-group d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-map-marker" for="Destination" style="font-size: 20px; padding: 10px;"></label>
                  <input class="form-control multiChoice w-auto" list="destinationOptions" id="Destination" placeholder="Destination">
                  <datalist id="destinationOptions">
                    <option value="San Francisco">
                    <option value="New York">
                    <option value="Seattle">
                    <option value="Los Angeles">
                    <option value="Chicago">
                  </datalist>
                </div>
              </td>
            </tr>

            <!-- Second row -->

            <tr>
              <td >
                <!-- <input class="form-control multiChoice" list="servicesOptions" id="Service" placeholder="Service">
                <datalist id="servicesOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist> -->
                <div class="input-group d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-archive" for="Service" style="font-size: 15px;"></label>
                <input class="form-control multiChoice w-auto" list="servicesOptions" id="Service" placeholder="Service">
                <datalist id="servicesOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist>
                </div>
              </td>
              <td>
                <!-- <div class="formWithButton input-group d-flex iconButton-box">
                  <span class="btn btn-outline-light iconButton" type="button" id="button-addon2" data-bs-toggle="tooltip" data-bs-placement="left">&#9951;</span>
                  <input type="text" class="form-control multiChoice" list="datalistOptions" id="Quantity" placeholder="Option" aria-label="Username" aria-describedby="addon-wrapping" style="width:auto; border: 0px;">
                </div> -->
                <!-- <input class="form-control multiChoice" list="deliveryOption" id="Option" placeholder="Option">
                <datalist id="deliveryOption">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist> -->
                <div class="input-group d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-truck" for="Option" style="font-size: 15px;"></label>
                <input class="form-control multiChoice w-auto" list="deliveryOption" id="Option" placeholder="Option">
                <datalist id="deliveryOption">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist>
                </div>
              </td>
            </tr>



          </tbody>
        </table>

        <!-- second segment -->

        <table class="table table-borderless">
          <thead>
            <tr>
              <th><h5 class="text-title">Departing</h5></th>
              <th><h5 class="text-title">Arrival By</h5></th>
            </tr>
          </thead>
          <tbody>

            <!-- Third row -->

            <tr>
              <td style="width: 50%;">
                <input class="form-control multiChoice " type="date" id="Departing" placeholder="Departing">
              </td>
              <td>
                <input class="form-control multiChoice " type="date" id="Arrival" placeholder="Arrival">
              </td>
            </tr>

            <tr>
              <td>
                <div class="input-group mb-3 d-flex multiChoice">
                  <input type="text" class="form-control multiChoice" aria-label="Amount (to the nearest dollar)" placeholder="Quantity Pallet" style="width: auto;">
                  <span class="input-group-text multiChoice fa fa-info-circle bs-tooltip-right" data-bs-toggle="tooltip" data-bs-placement="auto" title="1.1m(L) X 1.1m(W) X 2.1m(H)" aria-hidden="true" style="font-size: 15px; padding: 10px ;"></span>
                </div>
              </td>
              <td>
              </td>
            </tr>


          </tbody>
        </table>

        <div class="container" style="display: flex; justify-content: center;">
          <button class="border btn btn-light " type="reset" value="Reset" style="margin-right: 20px; font-size: 20px;">Reset</button>
          <span class="btn inputButton"  type="submit" style="width: 220px; font-size: 20px">Quote &#10230;</span>
        </div>

        </form>
      </div>

      <!-- For mobile  -->
      <form class="d-block d-sm-none">
        
        <table class="table table-borderless">
          
          <thead>
            <tr>
              <th style="width: 300px;"><h5 class="text-title">From</h5></th>
            </tr>
          </thead>

          <tbody>

            <!-- first row -->

            <tr>
              <td style="min-width: 140px;">
                <!-- <input class="form-control multiChoice" list="originOptions" id="Origin" placeholder="Origin" >
                <datalist id="originOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist> -->
                <div class="input-group mb-3 d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-map-marker" for="Origin" style="font-size: 20px; padding: 10px;"></label>
                  <input class="form-control multiChoice w-auto" list="originOptions" id="Origin" placeholder="Origin" >
                  <datalist id="originOptions">
                    <option value="San Francisco">
                    <option value="New York">
                    <option value="Seattle">
                    <option value="Los Angeles">
                    <option value="Chicago">
                  </datalist>
                </div>
              </td>
            </tr>
          </tbody>
          <!-- Second row -->
          <thead>
            <tr>
              <th style="width: 300px; "><h5 class="text-title ">To</h5></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="min-width: 140px;">
                <!-- <input class="form-control multiChoice" list="destinationOptions" id="Destination" placeholder="Destination">
                <datalist id="destinationOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist> -->
                <div class="input-group mb-3 d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-map-marker" for="Destination" style="font-size: 20px; padding: 10px;"></label>
                  <input class="form-control multiChoice w-auto" list="destinationOptions" id="Destination" placeholder="Destination">
                  <datalist id="destinationOptions">
                    <option value="San Francisco">
                    <option value="New York">
                    <option value="Seattle">
                    <option value="Los Angeles">
                    <option value="Chicago">
                  </datalist>
                </div>
              </td>
            </tr>

            <!-- Third row -->

            <tr>
              <td style="min-width: 140px;">
                <!-- <input class="form-control multiChoice" list="datalistOptions" id="Service" placeholder="Service">
                <datalist id="datalistOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist> -->
                <div class="input-group mb-3 d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-archive" for="Service" style="font-size: 15px;"></label>
                  <input class="form-control multiChoice w-auto" list="datalistOptions" id="Service" placeholder="Service">
                <datalist id="datalistOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist>
                </div>
              </td>
            </tr>
            <!-- Forth row -->
            <tr>
              <td style="min-width: 140px;">
                <!-- <div class="formWithButton input-group mb-3 d-flex" style=" flex-wrap: wrap; border: 1px solid #1FAF4B; border-radius: 5px;">
                  <span class="btn btn-outline-light iconButton" type="button" id="button-addon2" data-bs-toggle="tooltip" data-bs-placement="left" style=" border: 0px;  color: black; background-color: white; font-weight: bold; box-shadow: 0 0 0 0; display: flex; align-items: center;">&#9951; </span>
                  <input type="text" class="form-control multiChoice" list="datalistOptions" id="Quantity" placeholder="Option" aria-label="Username" aria-describedby="addon-wrapping" style="width:auto; border: 0px;">
                </div> -->
                <!-- <input class="form-control multiChoice" list="datalistOptions" id="Option" placeholder="Option">
                <datalist id="datalistOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist> -->
                <div class="input-group mb-3 d-flex">
                  <label class="input-group-text multiChoice text-center d-flex fa fa-truck" for="Option" style="font-size: 15px;"></label>
                  <input class="form-control multiChoice w-auto" list="datalistOptions" id="Option" placeholder="Option">
                <datalist id="datalistOptions">
                  <option value="San Francisco">
                  <option value="New York">
                  <option value="Seattle">
                  <option value="Los Angeles">
                  <option value="Chicago">
                </datalist>
                </div>
              </td>
            </tr>

          </tbody>
        </table>

        <!-- second segment of mobile -->
        <table class="table table-borderless">
          
          <thead>
            <tr>
              <th style="width: 300px;"><h5 class="text-title">Departing</h5></th>
            </tr>
          </thead>

          <tbody>

            <!-- first row -->

            <tr>
              <td style="min-width: 140px;">
                <input class="form-control multiChoice" type="date" id="Departing" placeholder="Departing">
              </td>
            </tr>
          </tbody>
          <!-- Second row -->
          <thead>
            <tr>
              <th style="width: 300px; "><h5 class="text-title ">Arrived By</h5></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="min-width: 140px;">
                <input class="form-control multiChoice" type="date" id="Arrival" placeholder="Arrival">
              </td>
            </tr>

            <!-- Third row -->

            <tr>
              <td style="min-width: 140px;">
                <!-- <div class="formWithButton input-group mb-3 d-flex" style=" flex-wrap: wrap; border: 1px solid #1FAF4B; border-radius: 5px;">
                  <input type="text" class="form-control form-control-lg multiChoice" placeholder="Quantity Pallet" aria-label="Recipient's username" aria-describedby="button-addon2" style="width: 10%; border: 0px; box-shadow: 0 0 0 0;">
                  <span class="btn btn-outline-light iconButton" type="button" id="button-addon2" data-bs-toggle="tooltip" data-bs-placement="left" title="1.1m(L) X 1.1m(W) X 2.1m(H)" style=" border: 0px;  color: black; background-color: white; font-weight: bold; box-shadow: 0 0 0 0; display: flex; align-items: center;">&#9432;</span>
                </div> -->
                <div class="input-group mb-3 d-flex multiChoice">
                  <input type="text" class="form-control multiChoice" aria-label="Amount (to the nearest dollar)" placeholder="Quantity Pallet" style="width: auto;">
                  <span class="input-group-text multiChoice fa fa-info-circle bs-tooltip-right" data-bs-toggle="tooltip" data-bs-placement="auto" title="1.1m(L) X 1.1m(W) X 2.1m(H)" aria-hidden="true" style="font-size: 15px; padding: 10px;"></span>
                </div>
              </td>
            </tr>

          </tbody>
        </table>

        <div class="container" style="display: flex; justify-content: center;">
          <button class=" border btn btn-light " type="reset" value="Reset" style="margin-right: 1%;">Reset</button>
          <span class="btn inputButton"  type="submit" style="width: 150px;">Quote &#10230;</span>
        </div>
      </form>
    </div>  
    </div>
    
  <app-footer></app-footer>
</div>