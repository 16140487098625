<div class="fit_the_screen">
  <app-header></app-header>
      <div class="section">
          <!-- mobile -->
          <div class="d-block d-sm-none">
            <div class="container p-0">
                
              <div style=" background-color: white; width: 100%; height: 400px;">
                <img src="../assets/image/Icons/New_booking_img.png" width="100%" height="100%">
              </div>
            
              <div class="container-fluid createAccount-container p-0">
            
                <div class="section tableBox pb-0" style="width: auto;">

                  <div class="container CA-laptop p-0">

                            <!-- <h4>Create an Account</h4>

                            <input class="multiChoice form-control" type="text" placeholder="Name" aria-label="Recipient's username" style="font-size: 20px;" >

                            <input class="multiChoice form-control" type="email" placeholder="Email" aria-label="Recipient's email" style="font-size: 20px;">

                            <input class="multiChoice form-control" type="password" placeholder="Password" aria-label="Recipient's password" style="font-size: 20px;">

        
                    <div class="form-check" style="padding-left: 5px;">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style="border-color: #1FAF4B; border: 1px solid #1FAF4B; transform: scale(1.5);">
                      <label class="form-check-label" for="flexCheckDefault" style="margin-left: 30px; margin-bottom: 2%;">
                        Show password
                      </label>
                    </div>
                    <div class="form-check" style="padding-left: 5px;">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  style="border-color: #1FAF4B; border: 1px solid #1FAF4B; transform: scale(1.5);">
                      <label class="form-check-label" for="flexCheckChecked" style="margin-left: 30px;">
                        I agree with to the Terms and Privacy Policy
                      </label>
                    </div> -->
        
                    <div class="col-sm-12 d-flex justify-content-center pt-4" >
                      <span type="button" data-bs-dismiss="offcanvas" class="inputButton btn btn-dark btn-lg" style="width: 80%;">Sign Up</span>
                    </div>
        
                    <div class="line-with-or">
                      <span class="or-text">or</span>
                    </div>
        
                    <h4 class="text-center">Sign up with</h4>
        
                    <div class="d-flex justify-content-center">
                      <img src="../assets/icons/Google_circle.png" style="width: 10%; margin: 2%;">
                      <img src="../assets/icons/Facebook_circle.png" style="width: 10%; margin: 2%;">
                    </div>
        
                    <p class="text-center">Already have an account? :<a href="" style="font-weight: bold; font-size: 20px; "> Log In</a></p>
        
        
                  </div>

                </div>

              </div>
            </div>
          </div>


          <!-- laptop/desktop -->
          <div class="d-none d-sm-block">
            <div class="container p-0 ">
              <div class="row">
                <div class="col-md-6" style=" width: 50%;">
                  <img src="../assets/image/Icons/New_booking_img.png" width="100%" height="100%">
                </div>

                <div class="tableBox col-md-6" >
                  <div class="CA-laptop">

                          <!-- <h4>Create an Account</h4>

                          <input class="multiChoice form-control" type="text" placeholder="Name" aria-label="Recipient's username" style="font-size: 20px;" >

                          <input class="multiChoice form-control" type="email" placeholder="Email" aria-label="Recipient's email" style="font-size: 20px;">

                          <input class="multiChoice form-control" type="password" placeholder="Password" aria-label="Recipient's password" style="font-size: 20px;">


                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style="border-color: #1FAF4B; border: 1px solid #1FAF4B; transform: scale(1.5);">
                    <label class="form-check-label" for="flexCheckDefault" style="margin-left: 30px; margin-bottom: 2%;">
                      Show password
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  style="border-color: #1FAF4B; border: 1px solid #1FAF4B; transform: scale(1.5);">
                    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 30px;">
                      I agree with to the Terms and Privacy Policy
                    </label>
                  </div> -->

                  <div class="d-flex justify-content-center pt-4">
                    <span type="button" data-bs-dismiss="offcanvas" class="inputButton btn btn-lg" style="min-width: 200px;">Sign Up</span>
                  </div>

                  <div class="line-with-or">
                    <span class="or-text">or</span>
                  </div>

                  <h4 class="text-center">Sign up with</h4>

                  <div class="d-flex justify-content-center">
                    <img href="" type="button" src="../assets/icons/Google_circle.png" style="width: 10%; margin: 2%;">
                    <img href="" type="button" src="../assets/icons/Facebook_circle.png" style="width: 10%; margin: 2%;">
                  </div>

                  <p class="text-center">Already have an account? :<a href="" style="font-weight: bold; font-size: 20px; "> Log In</a></p>


                </div>

                </div>
              </div>

            </div>

          </div>

      </div>
  <app-footer></app-footer>
</div>
