<div class="fit_the_screen">
  <app-header></app-header>

   <!-- The slideshow/carousel -->
   <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>      
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div> 


  <div class="section">
      <div class="container p-0">
        <h4 class="text-title text-center">LTL Pallet Delivery</h4>
        <p class="text-center">
            Less than Load (LTL) pallet delivery tailored for businesses of all sizes, 
            our services optimize pallet loads, providing cost-effective solutions for your shipment. 
            Whether you have individual pallets or full container loads, our extensive network ensures 
            reliable and careful delivery. Choose the right pallet option for your needs with the flexibility and reliability you deserve.
        </p>
      </div>



  <div class="disable_view section pb-0">
    <h4 class="text-title text-center" style="margin: 2%;">Pallet Precision<br>Unleashing The Power Of LTL Delivery Excellence</h4>

    <!-- <div class="container-fluid" style="background-color: #1FAF4B; padding: 30px 20px 30px 20px;">
        <div class="container d-flex align-self-center justify-content-center p-0">
          <div class="d-flex justify-content-start align-self-center">
            <img src="../assets/icons/LineHaul_FCL_truck.png" alt="Los Angeles" style="width: 80%; height: 70%;">
          </div >
          <div class="align-self-center " style="width: 450px; color: white;">
            <h4 class="text-title">FCL Pallet</h4>
            <p>
              Your goods on pallets fill the entire shipping container for 
              secure and exlusive shipping, ideal for larger shipments.
            </p>
          </div>

        </div>
    </div> -->
    <div class="container-fluid" style="background-color: #1FAF4B; padding: 30px 20px 30px 20px;">
      <div class="container d-flex align-self-start justify-content-center p-0">
        <div class="align-self-center text-end" style="width: 450px; color: white;">
          <h4 class="text-title">LTL Pallet</h4>
            <p>
            Your fully or partially palletized goods share space with others in a single container,
            offering flexibility and cost-effectiveness, particularly 
            suitable for smaller shipments.
            </p>
        </div>
        <div class="d-flex justify-content-end align-self-center">
          <img src="../assets/icons/LineHaul_LTL_truck.png" alt="Los Angeles" style="width: 80%; height: 70%;">
        </div >
      </div>
    </div>
  </div>

  <!-- samsung fold 280px -->
  <div class="foldSize section">
    <h4 class="text-title text-center" style="margin: 2%;">
      Pallet Precision<br>Unleashing The Power Of LTL Delivery Excellence
    </h4>

    <!-- <div class="container-fluid" style="background-color: #1FAF4B; padding: 30px 20px 30px 20px;">
        <div class="align-self-center justify-content-center">
          <div class="d-flex justify-content-center align-self-center">
            <img src="../assets/icons/LineHaul_FCL_truck.png" alt="Los Angeles" style="width: 80%; height: 70%;">
          </div >
          <div class="align-self-center text-center" style="max-width: 450px; margin: auto;">
            <h4 class="text-title">FCL Pallet</h4>
            <p>
              Your goods on pallets fill the entire shipping container for 
              secure and exlusive shipping, ideal for larger shipments.
            </p>
          </div>
        </div>
    </div> -->

    <div class="container-fluid" style="background-color: #1FAF4B; padding: 30px 20px 30px 20px;">
      <div class="align-self-center justify-content-center">
        <div class="d-flex justify-content-center align-self-center">
          <img src="../assets/icons/LineHaul_LTL_truck.png" alt="Los Angeles" style="width: 80%; height: 70%;">
        </div >
        <div class="align-self-center text-center" style="max-width: 450px; margin: auto; color: white;">
          <h4 class="text-title">LTL Pallet</h4>
          <p>Your fully or partially palletized goods share space with others in a single container,
            offering flexibility and cost-effectiveness, particularly 
            suitable for smaller shipments. </p>
        </div>
      </div>
    </div>

    </div>
  </div>

  <app-footer></app-footer>
</div>