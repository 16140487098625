import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation } from "@angular/common";

@Component({
  selector: 'app-admin_header',
  templateUrl: './admin_header.component.html',
  styleUrls: ['./admin_header.component.css']
})
export class AdminHeaderComponent implements OnInit {
    currBaseURL: any;

  constructor(
    private route: Router,
    private platformLocation: PlatformLocation
  ) {

    this.currBaseURL = (this.platformLocation as any).location.origin;

   }

  ngOnInit(): void {
  }

  goToLanding(){
    this.route.navigate(['']);

  }

  goToAdmin() {
    this.route.navigate(['admin']);
  }

  goToAdminCarousel() {
    this.route.navigate(['admin_carousel']);
  }

  goToAdminNetwork() {
    this.route.navigate(['admin_network']);
  }

  goToAdminFaq() {
    this.route.navigate(['admin_faq']);
  }

}