<app-header></app-header>


<div class="section">
  <div class="container">
   
      
      <div class="box">
        <div class="col-lg-12 col-md-12 col-xs-12">
          <h4>Our Team</h4>

          <p>
            Linehaul is a company which is operated by a group of creative, innovative, aggressive and passionate employees. Our mission 
            and vision is to simplify the transportation process by providing easy access for everyone and everywhere with safe, prompt, 
            affordable transport that also creates more job opportunities and income for drivers.
            </p>


          <br>
          <p>
            We are led by 4 key individuals with over 7 years of experience in this particular field. We are also led by 4 key 
            departments, which are Intelligent Operation, Sales and Marketing, Information Technology, and Business Strategy Planning.
          </p>

          <br>
          <p>
            Our team is motivated with great communication skill's sales team to promote our products in order to create more business 
            opportunities for everyone in the market. We are also a creative and innovative team who create more products and services 
            to resolve market supplies and demands. Our team consists of a young and experienced R&D team to develop our logistic 
            platform according to market demands. Besides, we have an aggressive on-ground intelligence operation team to understand 
            and fulfil clients' expectations. </p>
        </div>
      </div>

  </div>
</div>


<app-footer class="footer"></app-footer>