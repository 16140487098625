<div class="fit_the_screen">
  <app-header></app-header>
  <!-- <script defer src="../location.js"></script> -->
  <!-- The slideshow/carousel -->
  <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div>

  <div class="section">
    <div class="container p-0">
      <form>
        <div class="formWithButton input-group mb-3 d-flex" style=" flex-wrap: wrap;">
          <input id="location_input" [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}"
            class="form-control form-control-lg multiChoice" placeholder="Enter Postcode, City"
            aria-label="Recipient's username" aria-describedby="button-addon2" style="width: 10%; height: 50px;">
          <button id="search_button" (click)="filterCarousel()" class="btn inputButton" type="button"
            style="border: 1px solid #1FAF4B; background-color: #1FAF4B; color: white; outline: none;">Search
            &#10230;</button>
        </div>
      </form>
    </div>


    <div class="container pb-5 p-0 ">
      <!-- desktop/laptop -->
      <div class=" pb-5 p-0 d-none d-md-block">
        <div id="network_carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="99999999"
          wrap="false">

          <div class="container">
            <div class="row">
              <div class="col-12 text-end lead">
                <button class="next_prev_btn btn btn-secondary-outline m-2" data-bs-target="#network_carousel"
                  title="go back" data-bs-slide="prev">
                  <i class="fa fa-lg fa-chevron-left"></i>
                </button>
                <button class="next_prev_btn btn btn-secondary-outline m-2" data-bs-target="#network_carousel"
                  title="more" data-bs-slide="next">
                  <i class="fa fa-lg fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="carousel-inner row w-100 mx-auto" role="listbox" style="overflow: hidden;">

          </div>
        </div>
      </div>

      <!-- mobile -->
      <div class="container p-0 d-block d-md-none ">
        <div id="mobile_network_carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="9999999">

          <div class="container">
            <div class="row">
              <div class="col-12 text-end lead">
                <button class="next_prev_btn btn btn-secondary-outline " data-bs-target="#mobile_network_carousel"
                  title="go back" data-bs-slide="prev">
                  <i class="fa fa-lg fa-chevron-left"></i>
                </button>
                <button class="next_prev_btn btn btn-secondary-outline" data-bs-target="#mobile_network_carousel"
                  title="more" data-bs-slide="next">
                  <i class="fa fa-lg fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="carousel-inner row w-100 mx-auto" role="listbox" style="overflow: hidden;">
            <div class="h-100 d-flex justify-content-center">

            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- google map segment -->
    <div class="section">
      <div class="container">
        <h4 class="text-title text-center mb-5">Line Haul Headquarters</h4>
        <div class=" map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.197914536771!2d101.56308441422686!3d3.0415565977850196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4d293070b0b1%3A0xd21df5b1a41377b4!2s3%2C%20Jalan%20TP%202%2C%20Taman%20Perindustrian%20Uep%2C%2047600%20Subang%20Jaya%2C%20Selangor%2C%20Malaysia!5e0!3m2!1sen!2sus!4v1658887826017!5m2!1sen!2sus"
            width="100%" height="300px" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade" style="border-radius: 10px;"></iframe>
        </div>
      </div>
    </div>


  </div>

  <app-footer></app-footer>
</div>