<div class="fit_the_screen">
  <app-header></app-header>

  <!-- The slideshow/carousel -->
  <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div>

  <div class="section">
    <div class="col-md-12">
      <iframe width="83%" height="400" src="https://www.youtube.com/embed/gCy9fBLV8y8?playlist=gCy9fBLV8y8&loop=1&autoplay=1&mute=1"
        title="Line Haul Pallet Express - Streamlined Cross Border Solutions" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
        style="display: block; margin: auto;">
      </iframe>
    </div>
  </div>

  <div class="section p-0">
    <!-- laptop/desktop -->
    <div class="d-none d-sm-block section p-0">

      <div class="container-fluid CrossBorder_Top">
        <div class=" container banner-content d-flex align-self-center justify-content-center p-0">
          <div class="d-flex justify-content-start align-self-center">
            <img src="../assets/icons/LineHaul_CrossBorder_2.png" alt="Los Angeles">
          </div>
          <div class="banner-text align-self-center ">
            <h4 class="text-title">Freight Forwarding Service (Air & Sea)</h4>
            <p>
              Embark on a journey of global connectivity with our complrehensive
              freight forwarding services. Whether it's the swift and efficient
              shipping of goods by air or the cost-effective shipping of larger
              items by sea, our experienced team ensures your caargo traverses
              international borders smoothly. We handle the logistics, allowing
              you to concentrate on the core aspects of your business.
            </p>
          </div>

        </div>
      </div>
      <div class="container-fluid CrossBorder_Bottom">
        <div class="container banner-content d-flex align-self-center justify-content-center p-0">
          <div class="banner-text align-self-center text-end">
            <h4 class="text-title">Custom Clearance Service</h4>
            <p>
              Navigating the complexities of customs procedures is our expertise.
              Our custom clearance services ensure a hassle-free process, minimizing
              delays and ensuring compliance with regulations. Trust us to handle the
              intricacies, so your goods can move swiftly through borders
            </p>
          </div>
          <div class="d-flex justify-content-end align-self-center">
            <img src="../assets/icons/LineHaul_CrossBorder_1.png" alt="Los Angeles">
          </div>
        </div>
      </div>
    </div>

    <!-- mobile sizes -->
    <div class="d-block d-sm-none section p-0">
      <div class="container-fluid" style="background-color: white; padding: 10px;">
        <div class="d-flex align-self-center justify-content-center">
          <div class="row" style="width: 99%;">
            <div class="col-6 d-flex justify-content-center align-self-center">
              <img src="../assets/icons/LineHaul_CrossBorder_2.png" alt="Los Angeles" style="width: 80%; height: 70%;">
            </div>

            <div class="col-6 align-self-center">
              <h4 class="text-title text-center">Freight Forwarding Service<br>(Air & Sea)</h4>
            </div>

            <div class="col-12  align-self-center d-block" style="width: 450px;">
              <p style="margin-top: 4%;">
                Embark on a journey of global connectivity with our complrehensive
                freight forwarding services. Whether it's the swift and efficient
                shipping of goods by air or the cost-effective shipping of larger
                items by sea, our experienced team ensures your caargo traverses
                international borders smoothly. We handle the logistics, allowing
                you to concentrate on the core aspects of your business.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid" style="background-color:  #2a2a86; padding: 10px;">
        <div class="d-flex align-self-center justify-content-center">
          <div class="row" style="width: 99%; color: white;">

            <div class="col-6 align-self-center">
              <h4 class="text-title text-center">Freight Forwarding Service<br>(Air & Sea)</h4>
            </div>

            <div class="col-6 d-flex justify-content-center align-self-center">
              <img src="../assets/icons/LineHaul_CrossBorder_1.png" alt="Los Angeles" style="width: 80%; height: 70%;">
            </div>



            <div class="col-12  align-self-center d-block" style="width: 450px;">
              <p style="margin-top: 4%;">
                Embark on a journey of global connectivity with our complrehensive
                freight forwarding services. Whether it's the swift and efficient
                shipping of goods by air or the cost-effective shipping of larger
                items by sea, our experienced team ensures your caargo traverses
                international borders smoothly. We handle the logistics, allowing
                you to concentrate on the core aspects of your business.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <app-footer></app-footer>
</div>