import { Component, OnInit, AfterViewInit,  ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser'; 
import { HttpClient } from '@angular/common/http';  

declare var $: any; // Declare jQuery
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  public sliders: any[] = [];    

  constructor(
    private route: Router,
    private viewportScroller: ViewportScroller,
    private titleService: Title,
    private metaService: Meta,
    private el: ElementRef,
    private httpClient: HttpClient     

  ) { }

  ngOnInit(): void { 

  }

  ngAfterViewInit(): void {
      // Initialize tooltips using jQuery
      const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')) as Element[];
      const tooltipList = tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
  }

  
  goToPremium(){
    window.open('https://linehaul.com.my/assets/image/Icons/premium.jpg', '_blank');
  } 
  
  goToExpress(){
    window.open('https://linehaul.com.my/assets/image/Icons/express.jpg', '_blank');
  } 

  
  public onClick(elementId: string): void { 

    this.viewportScroller.scrollToAnchor(elementId);
    
  }
  
  public goToFragment(fragment: string): void{

    this.route.navigateByUrl("/#serviceId")
    
  }

  goToAboutUs(){
    this.route.navigate(['about-us']);
  }

  goToOurStrength(){
    this.route.navigate(['our_strength']);
  }

  goToHome(){
    this.route.navigate(['home']);
  }

  goToMilestone(){
    this.route.navigate(['milestone']);
  }

}
