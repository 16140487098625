import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser'; 
import { ApiService } from '../api.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  public sliders: any[] = [];    

  constructor(
    private route: Router,
    private viewportScroller: ViewportScroller,
    private titleService: Title,
    private metaService: Meta,
    private apiService: ApiService,

  ) { }

  ngOnInit(): void {
        this.titleService.setTitle("About Us | Line Haul")

        const metaDesc = this.metaService.getTag('name=description'); 

        if(!metaDesc){
            this.metaService.addTags([
                {name: "description", content: "Line Haul is a Pallet Courier Express logistics revolutionizing Mid-Mile Delivery across Malaysia. Driven by a team committed to product innovation and adapting to diverse market segments."}
            ]);
        }else{
            this.metaService.updateTag(  
                {name: "description", content: "Line Haul is a Pallet Courier Express logistics revolutionizing Mid-Mile Delivery across Malaysia. Driven by a team committed to product innovation and adapting to diverse market segments." },  
                "name=description"  
            )  
        }
        this.loadCarousel('aboutus', 'active');       

  }

  loadCarousel(page: string, state: string): void {
    this.apiService.loadCarousel(page, state).subscribe(
        response => {
            this.sliders = response.carousels;
        },
        error => {
            console.error('Error fetching carousel:', error);
        }
    );
  }

  goToPremium(){
    window.open('https://linehaul.com.my/assets/image/Icons/premium.jpg', '_blank');
  } 
  
  goToExpress(){
    window.open('https://linehaul.com.my/assets/image/Icons/express.jpg', '_blank');
  } 

  
  public onClick(elementId: string): void { 

    this.viewportScroller.scrollToAnchor(elementId);
    
    //   window.location.href = '/v2/landing#AnchorId';
  }
  
  public goToFragment(fragment: string): void{

    // let elementId = 'serviceId'
    
    // this.route.navigate( ['/landing#'], {fragment: fragment});
    // this.route.navigate(['/landing#'], { fragment: 'serviceId' });
    // this.route.navigate(['/landing#'+fragment])
    // this.redirectTo('/landing#serviceId');
    // window.location.href = '/v2/landing#' + fragment;
    // window.location.href = '/landing#' + fragment;
    // window.location.href = 'https://lineclearexpress.com/v2/landing#serviceId'
    // window.location.replace("http://someUrl.com");
    // this.viewportScroller.scrollToAnchor(elementId);

    // production
    // this.route.navigateByUrl("/v2/landing#serviceId")

    // staging 
    this.route.navigateByUrl("/#serviceId")
    
  }

  goToAboutUs(){
    this.route.navigate(['about-us']);
  }

  goToOurStrength(){
    this.route.navigate(['our_strength']);
  }

  goToMilestone(){
    this.route.navigate(['milestone']);
  }

}