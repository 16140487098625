<footer class="footer">
<!-- laptop -->
    <div class="footer-box d-none d-md-block">
      <div class="container" style="width: 95%; background-color: #2a2a86;">
        <footer class="row row-cols-5 d-flex" style="background-color: #2a2a86; color: white;" >

          <div class="col-4 p-0">
            <!-- <div class=" p-0" style="width: fit-content; background-color: white; position: relative;"> -->
              <a (click)="goToHome()">
                <img width="357px" height="250px" alt="Brand" src="../assets/icons/New_LH_Logo_Footer_DeskTop_full.png" id="logo" style="background-color: white; width: 100%;">
              </a>
              <!-- <a>
                <div class="text-center">
                  <img width="253px" height="56px" alt="Brand" src="./assets/icons/New_LH_Logo_Footer_Desktop.png" id="logo" style="position: relative; margin-top: 105px; width: 70%;">
                </div>
              </a> -->
            <!-- </div> -->
          </div>

          <div class=" col-1" style="width: 11%; margin-left: 1%;">
            <h5 class="footerlist-title mb-4 font-weight-bold">Company</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a class="footerlist" (click)="goToAboutUs()">About Us</a> </li>
              <!-- <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToOurEvents()">Our Events</a></li> -->
              <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToCareer()">Career</a></li>
              <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToContact()">Contact Us</a> </li>
              <!-- <li class="nav-item mb-2"> <a class="footerlist" href="javascript:;" (click)="goToOurEvents()">News and Updates</a></li> -->
            </ul>
          </div>
  
          <div class=" col-1">
            <h5 class="footerlist-title mb-4 font-weight-bold">Join Us</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToPersonal()">Create Account</a></li>
            </ul>
          </div>
  
          <div class=" col-1">
            <h5 class="footerlist-title mb-4 font-weight-bold">Legal</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToPrivacyPolicy()">Privacy Policy</a></li>
              <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToTnC()">Terms and Conditions</a></li>
              <!-- <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToContact()">FAQ</a></li> -->
            </ul>
          </div>

          <div class="col-1"></div>


          <div class="followUsColumn d-flex col-3" style="float: right; width: 29%;">
            <div style="width: fit-content; color: white;">
               <!-- Facebook -->
                <h5 class="downloadAppTitle font-weight-bold">Follow us</h5>
                <a href="https://www.facebook.com/linehaulexpress">
                  <img src="./assets/icons/Facebook_icon_green.png" alt="Facebook" height="45px" width="45px" style="cursor: pointer;">
                </a> 
                <a href="https://www.instagram.com/linehaulpalletexpress/">
                  <img src="./assets/icons/Instagram_icon_green.png" alt="Instagram" height="45px" width="45px" style="cursor: pointer;">
                </a> 
                <a href="https://www.tiktok.com/@linehaulpalletexpress">
                  <img src="./assets/icons/TikTok_icon_green.png" alt="Tik Tok" height="45px" width="45px" style="cursor: pointer;">
                </a> 
                <a href="https://www.youtube.com/channel/UCF_ls0lW25SETDU4uyztxFg">
                  <img src="./assets/icons/Youtube_icon_green.png" alt="Youtube" height="45px" width="45px" style="cursor: pointer;">
                </a> 
                <a href="https://www.linkedin.com/company/linehaul-sdn-bhd/">
                  <img src="./assets/icons/LinkedIn_icon_green.png" alt="LinkedIn" height="45px" width="45px" style="cursor: pointer;">
                </a> 
                <!-- download app -->
                <h5 class="downloadAppTitle font-weight-bold mb-0 mt-5">Download The App</h5>
                  <div>
                    <a href="https://apps.apple.com/my/app/line-haul/id1664682546">
                      <img class="download_icons" src="./assets/icons/App_Store_icon_green.png" alt="Apple Vector"  style="margin-right: 2%;">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.linehaul.customerapp&pcampaignid=web_share">
                      <img class="download_icons" src="./assets/icons/Google_Play_icon_green.png" alt="Google Play Vector">    
                    </a>
                  </div>
            </div>
          </div>

        </footer>
      </div>
    </div>      

  <!-- Mobile -->
    <div class="container-fluid d-md-none p-0" style=" background-color: #2a2a86; color: white;">
      <div class="container-fluid col p-0" style="background-color: white;">

        <a (click)="goToHome()">
          <img width="100%" height="100%" alt="Brand" src="../assets/icons/New_LH_Logo_Footer_Mobile.png" id="logo" style="background-color: white; width: 100%;">
        </a>
      </div>

      <div class="middleFooter container d-flex">
        <div class=" col-md-4">
          <h5 class="footerlist-title mb-4 font-weight-bold">Company</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a class="footerlist" (click)="goToAboutUs()">About Us</a> </li>
            <!-- <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToOurEvents">Our Events</a></li> -->
            <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToCareer()">Career</a></li>
            <li class="nav-item mb-2"><a class="footerlist" (click)="goToContact()">Contact Us</a> </li>
            <!-- <li class="nav-item mb-2"> <a class="footerlist" href="javascript:;" (click)="goToPrice()">News and Updates</a></li> -->
          </ul>
        </div>

        <div class="col-md-4">
          <h5 class="footerlist-title mb-4 font-weight-bold">Join Us</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToPersonal()">Create Account</a></li>
          </ul>
        </div>

        <div class="col-md-4">
          <h5 class="footerlist-title mb-4 font-weight-bold">Legal</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToPrivacyPolicy()">Privacy Policy</a></li>
            <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToTnC()">Terms and Conditions</a></li>
            <!-- <li class="nav-item mb-2"><a class="footerlist" href="javascript:;" (click)="goToContact">FAQ</a></li> -->
          </ul>
        </div>
      </div>  

      <!-- <div class="col" style="max-width: 20%;" > -->
        <div class=" followUsColumn d-flex pb-5 container ">

            <div class="col-4">
              <div style="padding: 0 15px;">
                <h5 class="downloadAppTitle mb-4 font-weight-bold">Follow us</h5>
              <!-- Facebook -->
                <a href="https://www.facebook.com/linehaulexpress">
                  <img src="./assets/icons/Facebook_icon_green.png" alt="Facebook" height="35px" width="35px" style="cursor: pointer;">
                </a>
                <a href="https://www.instagram.com/linehaulpalletexpress/">
                  <img src="./assets/icons/Instagram_icon_green.png" alt="Instagram" height="35px" width="35px" style="cursor: pointer;">
                </a>
                <a href="https://www.tiktok.com/@linehaulpalletexpress">
                  <img src="./assets/icons/TikTok_icon_green.png" alt="Tik Tok" height="35px" width="35px" style="cursor: pointer;">
                </a>
                <a href="https://www.youtube.com/channel/UCF_ls0lW25SETDU4uyztxFg">
                  <img src="./assets/icons/Youtube_icon_green.png" alt="Youtube" height="35px" width="35px" style="cursor: pointer;">
                </a>
                <a href="https://www.linkedin.com/company/linehaul-sdn-bhd/">
                  <img src="./assets/icons/LinkedIn_icon_green.png" alt="LinkedIn" height="35px" width="35px" style="cursor: pointer;">
                </a>
              </div>
            </div>
  
            <div class="col-8 d-flex justify-content-end">
              <div style="padding: 0 15px;">
                <!-- download app -->
                <h5 class="downloadAppTitle mb-4 font-weight-bold">Download The App</h5>
                <a href="https://apps.apple.com/my/app/line-haul/id1664682546">
                  <img  class="download_icons_mobile" src="./assets/icons/App_Store_icon_green.png" alt="Apple" >
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.linehaul.customerapp&pcampaignid=web_share">
                  <img  class="download_icons_mobile" src="./assets/icons/Google_Play_icon_green.png" alt="Google Play" >
                </a>
              </div>
            </div>

        </div>
      <!-- </div> -->
    </div>
</footer>


