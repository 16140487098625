import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private route: Router,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
  }


  goToPrivacyPolicy(){
    //this.route.navigate(['privacy_policy']);
    window.open('https://linehaul.com.my/doc/Privacy_Policy.pdf', '_blank');
  }

  goToTnC(){
    window.open('https://linehaul.com.my/doc/Terms_and_Conditions.pdf', '_blank');
  } 

  goToPrice(){
    window.open('https://linehaul.com.my/doc/Price_Chart.pdf', '_blank');
  } 
  

  
  public onClick(elementId: string): void { 

    this.viewportScroller.scrollToAnchor(elementId);
    
    //   window.location.href = '/v2/landing#AnchorId';
  }
  
  public goToFragment(fragment: string): void{

    // let elementId = 'serviceId'
    
    // this.route.navigate( ['/landing#'], {fragment: fragment});
    // this.route.navigate(['/landing#'], { fragment: 'serviceId' });
    // this.route.navigate(['/landing#'+fragment])
    // this.redirectTo('/landing#serviceId');
    // window.location.href = '/v2/landing#' + fragment;
    // window.location.href = '/landing#' + fragment;
    // window.location.href = 'https://lineclearexpress.com/v2/landing#serviceId'
    // window.location.replace("http://someUrl.com");
    // this.viewportScroller.scrollToAnchor(elementId);

    // production
    // this.route.navigateByUrl("/v2/landing#serviceId")

    // staging 
    this.route.navigateByUrl("/#serviceId")
    
  }

  goToOurStrength(){
    this.route.navigate(['our_strength']);
  }

  goToOurTeam(){
    this.route.navigate(['our_team']);
  }

  goToHome(){
    this.route.navigate(['home']);
  }

  goToMilestone(){
    this.route.navigate(['milestone']);
  }

  goToCertificate(){
    this.route.navigate(['certificate']);
  }

  goToOurEvents(){
    this.route.navigate(['our_events']);
  }

  goToCharteredTransport(){
    this.route.navigate(['chartered_transport']);
  }

  goToPalletDelivery(){
    this.route.navigate(['fcl_ltl_pallet_delivery']);
  }

  goToCrossBorder(){
    this.route.navigate(['cross_border']);
  }

  goToWarehouse(){
    this.route.navigate(['warehouse']);
  }

  goToWhyUs(){
    this.route.navigate(['why_us']);
  }

  goToPersonal(){
    this.route.navigate(['personal']);
  }

  goToCareer(){
    this.route.navigate(['career']);
  }

  goToSchedules(){
    this.route.navigate(['schedule']);
  }

  goToNewBooking(){
    this.route.navigate(['new_booking']);
  }

  goToDuplicatedBooking(){
    this.route.navigate(['duplicated_booking']);
  }

  goToNetwork(){
    this.route.navigate(['network']);
  }

  goToAboutUs(){
    this.route.navigate(['about-us']);
  }

  goToContact(){
    this.route.navigate(['contactus']);
  }

}

