<div class="fit_the_screen">
  <app-header></app-header>

  <!-- The slideshow/carousel -->
  <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div>

  <div class="section">
    <div class="container p-0">
      <h4 class="text-title text-center">Chartered Transport Service</h4>
      <p class="text-center">
        Our chartered transport service goes beyond conventional logistics. Tailored to meet your specific needs,
        our chartered solutions offer you complete control over your shipments. Whether it's a single delivery or
        a comprehensive transport plan, our dedicated chartered services ensure your cargo reaches its destination
        on time and with precision.
      </p>
    </div>
  </div>

  <!-- laptop/desktop -->
  <div class="d-none d-md-block">
    <div class="section pt-0">
      <div class="container p-0">

        <h4 class="text-title text-center">Our Fleet <br> Your Reliable Transport Solutions</h4>
        <div class="d-flex">
          <div class="card mover-card">
            <div class="card-img-box">
              <img class="custom-card-img" src="../assets/icons/Line Haul Icon Pack-10.png" alt="Card image"
                width="100%" height="80%">
            </div>
            <div class="card-body our_fleet-card p-0">
              <div class="card-content">
                <h4 class="card-title text-title text-center m-1">7.5 Ton</h4>
                <p class="card-text text-center ">4,050 KG (6 - 10 pallet)</p>
              </div>
            </div>
          </div>
          
          <div class=" card mover-card">
            <div class="card-img-box">
              <img class="custom-card-img" src="../assets/icons/Line Haul Icon Pack-11.png" alt="Card image" width="90%"
                height="80%">
            </div>
            <div class="card-body our_fleet-card p-0">
              <div class="card-content">
                <h4 class="card-title text-title text-center m-1">10 Ton</h4>
                <p class="card-text text-center ">7,906 KG (14 pallet)</p>
              </div>
            </div>
          </div>          

          <div class="card mover-card">
            <div class="card-img-box">
              <img class="custom-card-img" src="../assets/icons/Line Haul Icon Pack-12.png" alt="Card image"
                width="100%" height="80%">
            </div>
            <div class="card-body our_fleet-card p-0">
              <div class="card-content">
                <h4 class="card-title text-title text-center m-1">Prime Mover</h4>
                <p class="card-text text-center ">14,230 KG (20 - 22 pallet)</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- mobile -->
  <div class="container d-md-none pb-5 p-0">
    <h4 class="text-title text-center">Our Fleet <br> Your Reliable Transport Solutions</h4>
    <div class="container-fluid">
      <div id="CT_multi_carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"
        data-bs-wrap="false">
        <div class="carousel-inner row w-100 mx-auto" role="listbox">
          <div class="carousel-item active">
            <div class="h-100 d-flex justify-content-center">
              <div class="card mover-card-mobile">
                <div class="card-img-box">
                  <img class="custom-card-img" src="../assets/icons/Line Haul Icon Pack-10.png" alt="Card image"
                    width="100%">
                </div>
                <div class="card-body our_fleet-card p-0">
                  <div class="card-content">
                    <h4 class="card-title text-title text-center m-1">7.5 Ton</h4>
                    <p class="card-text text-center ">4,050 KG (6 - 10 pallet)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item ">
            <div class="h-100 d-flex justify-content-center">
              <div class=" card mover-card-mobile">
                <div class="card-img-box">
                  <img class="custom-card-img" src="../assets/icons/Line Haul Icon Pack-11.png" alt="Card image"
                    width="100%">
                </div>
                <div class="card-body our_fleet-card p-0">
                  <div class="card-content">
                    <h4 class="card-title text-title text-center m-1">10 Ton</h4>
                    <p class="card-text text-center ">7,906 KG (14 pallet)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="carousel-item ">
            <div class="h-100 d-flex justify-content-center">
              <div class="card mover-card-mobile">
                <div class="card-img-box">
                  <img class="custom-card-img" src="../assets/icons/Line Haul Icon Pack-12.png" alt="Card image"
                    width="100%">
                </div>
                <div class="card-body our_fleet-card p-0">
                  <div class="card-content">
                    <h4 class="card-title text-title text-center m-1">Prime Mover</h4>
                    <p class="card-text text-center ">14,230 KG (20 - 22 pallet)</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <a class="carousel-control-prev" data-bs-target="#CT_multi_carousel" role="button" data-bs-slide="prev">
          <i class="fa fa-chevron-left fa-lg text-muted"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next text-faded" data-bs-target="#CT_multi_carousel" role="button"
          data-bs-slide="next">
          <i class="fa fa-chevron-right fa-lg text-muted"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>