import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser'; 

@Component({
  selector: 'app-our_team',
  templateUrl: './our_team.component.html',
  styleUrls: ['./our_team.component.css']
})
export class OurTeamComponent implements OnInit {

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Our Team | Line Haul")
  }

}