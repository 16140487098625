

<div class="fit_the_screen">
  <app-admin_header></app-admin_header>

  <h1 class="page-heading">FAQ Management</h1>

  <div class="parent">
    <div class="table-container">  

      <!-- Search bar -->
      <div class="search-container">
        <label for="search">Search:</label>
        <input type="text" id="search" [(ngModel)]="searchTerm" (input)="applyFilter()" />
      </div>
      
      <!-- Pagination -->
      <nav aria-label="...">
        <ul class="pagination">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="changePage(currentPage - 1)" tabindex="-1" [attr.aria-disabled]="currentPage === 1">Previous</a>
          </li>
          <li *ngFor="let page of pages" class="page-item" [class.active]="currentPage === page">
            <a class="page-link" (click)="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="changePage(currentPage + 1)" [attr.aria-disabled]="currentPage === totalPages">Next</a>
          </li>
        </ul>
      </nav>

      <!-- Display FAQ entries -->
      <table class="table table-striped">
        <thead>
        <tr>
          <th (click)="sortByColumn('id')" style="width: 50px;">
            ID
            <i class="fas fa-sort-up" *ngIf="sortBy === 'id' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'id' && !sortAsc"></i>
          </th>    

          <th (click)="sortByColumn('number')" style="width: 50px;">
            No.
            <i class="fas fa-sort-up" *ngIf="sortBy === 'number' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'number' && !sortAsc"></i>
          </th>
                    
          <th (click)="sortByColumn('topic')" style="width: 150px;">
            Topic
            <i class="fas fa-sort-up" *ngIf="sortBy === 'topic' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'topic' && !sortAsc"></i>
          </th>

          <th (click)="sortByColumn('question')" style="width: 150px;">
            Question
            <i class="fas fa-sort-up" *ngIf="sortBy === 'question' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'question' && !sortAsc"></i>
          </th>

          <th (click)="sortByColumn('answer')" style="width: 150px;">
            Answer
            <i class="fas fa-sort-up" *ngIf="sortBy === 'answer' && sortAsc"></i>
            <i class="fas fa-sort-down" *ngIf="sortBy === 'answer' && !sortAsc"></i>
          </th>


          <th style="width: 100px;">Action</th>
        </tr>
        </thead>

        <tbody>
            <tr *ngFor="let faq of paginatedData; let index = index">
              <td>{{ faq.id }}</td>
              <td>{{ faq.number }}</td>
              <td>{{ faq.topic }}</td>
              <td>{{ faq.question }}</td>
              <td>{{ faq.answer }}</td>
              <td>
                <button class="btn btn-primary" (click)="editFaq(faq)">
                  <i class="fas fa-edit"></i>
                </button>
                <button class="btn btn-danger" (click)="deleteFaq(faq.id)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
      </table>

      <!-- Pagination -->
      <nav aria-label="...">
        <ul class="pagination">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="changePage(currentPage - 1)" tabindex="-1">Previous</a>
          </li>
          <li *ngFor="let page of pages" class="page-item" [class.active]="currentPage === page">
            <a class="page-link" (click)="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="changePage(currentPage + 1)">Next</a>
          </li>
        </ul>
      </nav>
    </div>


    <!-- Pop up edit form -->
    <div class="modal" [class.show]="isEditing">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit FAQ</h5>
            <button type="button" class="close" (click)="cancelEdit()">
              <span>&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form [formGroup]="editForm" (ngSubmit)="saveEdit()">
              <div class="form-group">
                <label for="number">No.</label>
                <input type="text" id="number" formControlName="number" required>
                <div *ngIf="formEdit && editForm.get('number').hasError('required')" class="error-message" >
                  This field is required
                </div>
              </div>

              <div class="form-group">
                <label for="branch">Topic</label>
                <input type="text" id="topic" formControlName="topic" required>
                <div *ngIf="formEdit && editForm.get('topic').hasError('required')" class="error-message" >
                  This field is required
                </div>
              </div>

              <div class="form-group">
                <label for="address">Question</label>
                <input type="text" id="question" formControlName="question" required>
                <div *ngIf="formEdit && editForm.get('question').hasError('required')" class="error-message" >
                  This field is required
                </div>
              </div>

              <div class="form-group">
                <label for="answer">Answer</label>
                <input type="text" id="answer" formControlName="answer" required>
                <div *ngIf="formEdit && editForm.get('answer').hasError('required')" class="error-message" >
                  This field is required
                </div>
              </div>

            </form>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="saveEdit()">Save</button>
          </div>

        </div>
      </div>
    </div>

      <!-- FAQ add form -->
      <div class="form-container">
        <h2>FAQ Form</h2>
        <h3>Add a new FAQ with the form below</h3>
          <form [formGroup]="form" (ngSubmit)="addFaq()">

            <div class="form-group">
              <label for="number">No.</label>
              <input type="text" id="number" formControlName="number" required>
              <div *ngIf="formSubmit && form.get('number').hasError('required')"  class="error-message">
                This field is required
              </div>
            </div>   

            <div class="form-group">
              <label for="topic">Topic</label>
              <input type="text" id="topic" formControlName="topic" required>
              <div *ngIf="formSubmit && form.get('topic').hasError('required')" class="error-message" >
                This field is required
              </div>
            </div>  
            
            <div class="form-group">
              <label for="question">Question</label>
              <input type="text" id="question" formControlName="question" required>
              <div *ngIf="formSubmit && form.get('question').hasError('required')"  class="error-message">
                This field is required
              </div>
            </div>
        
            <div class="form-group">
              <label for="answer">Answer</label>
              <input type="text" id="answer" formControlName="answer" required>
              <div *ngIf="formSubmit && form.get('answer').hasError('required')"  class="error-message">
                This field is required
              </div>
            </div>   
  
            <button class="add-btn" type="submit">Add</button>
          </form>
      </div> 
  </div>
</div>

