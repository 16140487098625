
<div class="fit_the_screen">
  <app-admin_header></app-admin_header>

  <h1 class="page-heading">Carousel Management</h1>

  <div class="parent">
      <div class="table-container">    

        <!-- Search -->
        <div class="search-container">
          <label for="search">Search:</label>
          <input type="text" id="search" [(ngModel)]="searchTerm" (input)="applyFilter()" />
        </div>

        <!-- Pagination -->
        <nav aria-label="...">
          <ul class="pagination">
            <li class="page-item" [class.disabled]="currentPage === 1">
              <a class="page-link" (click)="changePage(currentPage - 1)" tabindex="-1">Previous</a>
            </li>
            <li *ngFor="let page of pages" class="page-item" [class.active]="currentPage === page">
              <a class="page-link" (click)="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
              <a class="page-link" (click)="changePage(currentPage + 1)">Next</a>
            </li>
          </ul>
        </nav>

        <!-- Display carousel entries -->
        <table class="table table-striped">
          <thead>
          <tr>
            <th (click)="sortByColumn('id')" style="width: 50px;">
              ID
              <i class="fas fa-sort-up" *ngIf="sortBy === 'id' && sortAsc"></i>
              <i class="fas fa-sort-down" *ngIf="sortBy === 'id' && !sortAsc"></i>
            </th>    

            <th (click)="sortByColumn('number')" style="width: 150px;">
              No. 
              <i class="fas fa-sort-up" *ngIf="sortBy === 'number' && sortAsc"></i>
              <i class="fas fa-sort-down" *ngIf="sortBy === 'number' && !sortAsc"></i>
            </th>
                      
            <th (click)="sortByColumn('name')" style="width: 150px;">
              Name
              <i class="fas fa-sort-up" *ngIf="sortBy === 'name' && sortAsc"></i>
              <i class="fas fa-sort-down" *ngIf="sortBy === 'name' && !sortAsc"></i>
            </th>

            <th (click)="sortByColumn('url')" style="width: 150px;">
              Image Link
              <i class="fas fa-sort-up" *ngIf="sortBy === 'url' && sortAsc"></i>
              <i class="fas fa-sort-down" *ngIf="sortBy === 'url' && !sortAsc"></i>
            </th>

            <th (click)="sortByColumn('description')" style="width: 150px;">
              Description
              <i class="fas fa-sort-up" *ngIf="sortBy === 'description' && sortAsc"></i>
              <i class="fas fa-sort-down" *ngIf="sortBy === 'description' && !sortAsc"></i>
            </th>

            <th (click)="sortByColumn('page')" style="width: 150px;">
              Page
              <i class="fas fa-sort-up" *ngIf="sortBy === 'page' && sortAsc"></i>
              <i class="fas fa-sort-down" *ngIf="sortBy === 'page' && !sortAsc"></i>
            </th>

            <th (click)="sortByColumn('state')" style="width: 150px;">
              State 
              <i class="fas fa-sort-up" *ngIf="sortBy === 'state' && sortAsc"></i>
              <i class="fas fa-sort-down" *ngIf="sortBy === 'state' && !sortAsc"></i>
            </th>

            <th style="width: 100px;">Action</th>
          </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of paginatedData; let index = index">
              <td>{{ item.id }}</td>
              <td>{{ item.number }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.url }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.page }}</td>
              <td>{{ item.state }}</td>
              <td>
                <button class="btn btn-primary" (click)="editCarousel(item)">
                  <i class="fas fa-edit"></i> 
                </button>
                <button class="btn btn-danger" (click)="deleteCarousel(item.id)">
                  <i class="fas fa-trash-alt"></i> 
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <nav aria-label="...">
          <ul class="pagination">
            <li class="page-item" [class.disabled]="currentPage === 1">
              <a class="page-link" (click)="changePage(currentPage - 1)" tabindex="-1">Previous</a>
            </li>
            <li *ngFor="let page of pages" class="page-item" [class.active]="currentPage === page">
              <a class="page-link" (click)="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
              <a class="page-link" (click)="changePage(currentPage + 1)">Next</a>
            </li>
          </ul>
        </nav>
      </div>


      <!-- Pop up edit -->
      <div class="modal" [class.show]="isEditing">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Carousel</h5>
              <button type="button" class="close" (click)="cancelEdit()">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="editForm" (ngSubmit)="saveEdit()">

                <div class="form-group">
                  <label for="number">No.</label>
                  <input type="text" id="number" formControlName="number" required>
                  <div *ngIf="formEdit && editForm.get('number').hasError('required')" class="error-message" >
                    This field is required
                  </div>
                </div>

                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" id="name" formControlName="name" required>
                  <div *ngIf="formEdit && editForm.get('name').hasError('required')" class="error-message" >
                    This field is required
                  </div>
                </div>

                <div class="form-group">
                  <label for="url">Image Link</label>
                  <input type="text" id="url" formControlName="url" required>
                  <div *ngIf="formEdit && editForm.get('url').hasError('required')" class="error-message" >
                    This field is required
                  </div>
                </div>

                <div class="form-group">
                  <label for="description">Description</label>
                  <input type="text" id="description" formControlName="description" required>
                  <div *ngIf="formEdit && editForm.get('description').hasError('required')" class="error-message" >
                    This field is required
                  </div>
                </div>

                <div class="form-group">
                  <label for="page">Page</label>
                  <select id="page" formControlName="page" required>
                    <option value="home">home</option>
                    <option value="aboutus">aboutus</option>
                    <option value="milestone">milestone</option>
                    <option value="chartered-transport">chartered-transport</option>
                    <option value="fcl-ltl-pallet-delivery">fcl-ltl-pallet-delivery</option>
                    <option value="cross-border">cross-border</option>
                    <option value="warehouses">warehouse</option>
                    <option value="why-us">why-us</option>
                    <option value="personal">personal</option>
                    <option value="driver">driver</option>
                    <option value="schedules">schedules</option>
                    <option value="network">network</option>
                    <option value="contact-us">contact-us</option>
                  </select>
                  <div *ngIf="formEdit && editForm.get('page').hasError('required')"  class="error-message">
                    Please choose a page
                  </div>
                </div>

                <div class="form-group">
                  <label for="state">State</label>
                  <select id="state" formControlName="state" required>
                    <option value="active">active</option>
                    <option value="inactive">inactive</option>
                  </select>
                  <div *ngIf="formEdit && editForm.get('state').hasError('required')"  class="error-message">
                    Please choose a state
                  </div>
                </div>

              </form>
            </div>
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
              <button type="button" class="btn btn-primary" (click)="saveEdit()">Save</button>
            </div>

          </div>
        </div>
      </div>

      <!-- Carousel add form -->
      <div class="form-container">
        <h2>Carousel Form</h2>
        <h3>Add a new carousel with the form below</h3>
          <form [formGroup]="form" (ngSubmit)="addCarousel()">

            <div class="form-group">
              <label for="number">No.</label>
              <input type="text" id="number" formControlName="number" required>
              <div *ngIf="formSubmit && form.get('number').hasError('required')" class="error-message" >
                This field is required
              </div>
            </div>
        
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" formControlName="name" required>
              <div *ngIf="formSubmit && form.get('name').hasError('required')" class="error-message" >
                This field is required
              </div>
            </div>
        
            <div class="form-group">
              <label for="url">Image Link</label>
              <input type="text" id="url" formControlName="url" required>
              <div *ngIf="formSubmit && form.get('url').hasError('required')"  class="error-message">
                This field is required
              </div>
            </div>
        
            <div class="form-group">
              <label for="description">Description</label>
              <input type="text" id="description" formControlName="description" required>
              <div *ngIf="formSubmit && form.get('description').hasError('required')"  class="error-message">
                This field is required
              </div>
            </div>          
  
            <div class="form-group">
              <label for="page">Page</label>
              <select id="page" formControlName="page" required>
                <option value="home">home</option>
                <option value="aboutus">aboutus</option>
                <option value="milestone">milestone</option>
                <option value="chartered-transport">chartered-transport</option>
                <option value="fcl-ltl-pallet-delivery">fcl-ltl-pallet-delivery</option>
                <option value="cross-border">cross-border</option>
                <option value="warehouses">warehouse</option>
                <option value="why-us">why-us</option>
                <option value="personal">personal</option>
                <option value="driver">driver</option>
                <option value="schedules">schedules</option>
                <option value="network">network</option>
                <option value="contact-us">contact-us</option>
              </select>
              <div *ngIf="formSubmit && form.get('page').hasError('required')"  class="error-message">
                Please choose a page
              </div>
            </div>

            <div class="form-group">
              <label for="state">State</label>
              <select id="state" formControlName="state" required>
                <option value="active">active</option>
                <option value="inactive">inactive</option>
              </select>
              <div *ngIf="formSubmit && form.get('state').hasError('required')"  class="error-message">
                Please choose a state
              </div>
            </div>

      
            <button class="add-btn" type="submit">Add</button>
          
          </form>
      </div> 
  </div>
</div>