import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin_faq.component.html',
  styleUrls: ['./admin_faq.component.css']
})
export class AdminFaqComponent implements OnInit {
  newFaq: any = {};
  faqs: any[] = []; 
  isEditing: boolean = false;
  editFaqId: number | null = null;
  editFaqDetails: any = {}; 
  currentPage: number = 1;
  itemsPerPage: number = 8; 
  formSubmit: boolean = false;
  formEdit: boolean = false; 
  selectedPage: string = '';

  editForm: FormGroup; 
  form: FormGroup;

  sortBy: string = 'id';
  sortAsc: boolean = true;

  searchTerm: string = '';

  get totalPages() {
    return Math.ceil(this.faqs.length / this.itemsPerPage);
  }

  get pages() {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  get paginatedData() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredData.slice(start, end);
  }

  get filteredData() {
    if (this.selectedPage) {
      return this.faqs.filter(item => item.page === this.selectedPage);
    } else {
      return this.faqs;
    }
  }

  generatePageArray(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private FormsModule: FormsModule

  ) { 
    this.editForm = this.formBuilder.group({
      topic: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.required],
      number: ['', Validators.required],
    });

    this.form = this.formBuilder.group({
      topic: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.required],
      number: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadFaq();
  }
  
  loadFaq(): void {
    this.apiService.loadFaq().subscribe(
      (response) => {
        console.log('API Response:', response);
        this.faqs = response.faqs;
        this.applySort(); 

      },
      (error) => {
        console.error('Error fetching FAQ:', error);
      }
    );
  }

  applyFilter(): void {
    if (this.searchTerm.trim() !== '') {
      this.faqs = this.faqs.filter(
        (data) =>
          data.topic.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          data.question.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          data.answer.toLowerCase().includes(this.searchTerm.toLowerCase()) 
      );
    } else {
      this.loadFaq();
    }
    this.applySort(); 
  }
  
  sortByColumn(column: string) {
    if (this.sortBy === column) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortBy = column;
      this.sortAsc = true;
      this.currentPage = 1; 
    }
    this.applySort();
  }

  applySort() {
    this.faqs.sort((a, b) => {
      const order = this.sortAsc ? 1 : -1;
      return a[this.sortBy] > b[this.sortBy] ? order : -1 * order;
    });
  
    this.currentPage = 1;
  }
  
  //-----------ADD, EDIT, REMOVE data---------------------------------------------

  addFaq() {
    this.formSubmit = true; 
    this.apiService.store('faq', this.form.value).subscribe(
      () => {
        this.form.reset();
        this.loadFaq();
        this.formSubmit = false; 
      },
      (error) => {
        console.error('Error adding FAQ entry:', error);
      }
    );
  }

  editFaq(faq: any) {
    this.isEditing = true;
    this.editFaqId = faq.id;
    this.editForm.patchValue(faq);
  }
 
  saveEdit() {
    this.apiService.update('faq', this.editFaqId, this.editForm.value).subscribe(
      () => {
        this.isEditing = false;
        this.editFaqId = null;
        this.editForm.reset();
        this.loadFaq();
      },
      (error) => {
        console.error('Error updating FAQ entry:', error);
      }
    );
    this.formEdit = true; 
  }

  cancelEdit() {
    this.isEditing = false;
    this.editFaqId = null;
    this.editFaqDetails = {};
  }

  deleteFaq(id: number) {
    this.apiService.destroy('faq', id).subscribe(
      () => {
        this.loadFaq();
      },
      (error) => {
        console.error('Error deleting FAQ entry:', error);
      }
    );
  }
}

