<div class="fit_the_screen">
  <app-header></app-header>

   <!-- The slideshow/carousel -->
   <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>      
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div> 
  <div class="section">
      <div class="container">
        <h4 class="text-title text-center">Start Shipping Your Pallets With Us Now!</h4>
        <div class="justify-content-center d-flex" style="margin: auto;">
          <span type="button" class="sign_up_btn btn btn-dark inputButton" style="border-radius: 10px;"><a href="https://pallet.linehaul.com.my/login" style="color: white; text-decoration: none;">Sign Up Now</a></span>
        </div>
      </div>

  <!--delivery type carousel -->
      <div class="section"> 

        <!-- desktop/laptop -->
        <div class=" pb-5 p-0 d-none d-md-block">
          <h4 class="text-title text-center">Unlock the Potential of Pallet Shipping for Every Need!</h4>
          <div id="Personal_multi_carousel-1" class="carousel slide" data-bs-ride="carousel" data-bs-interval="999999999">
  
            <div class="container">
              <div class="row">
                <div class="col-12 text-end lead">
                  <button class="btn btn-secondary-outline" data-bs-target="#Personal_multi_carousel-1" title="go back" data-bs-slide="prev">
                    <i class="fa fa-lg fa-chevron-left"></i>
                  </button>
                  <button class="btn btn-secondary-outline" data-bs-target="#Personal_multi_carousel-1" title="more" data-bs-slide="next">
                    <i class="fa fa-lg fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
        
              <div class="carousel-inner row w-100 mx-auto" role="listbox" style="overflow: hidden;">
  
                  <div class="carousel-item active h-100 p-0">
                      <div class="card transport_solution-card">
                        <div style=" justify-content: center; display: flex;">
                          <img class="card-img-top" src="../assets/icons/LineHaul_Delivery_Method_4.png" alt="Card image" style=" padding: 10px;">
                        </div>
                          <div class="card-body p-0 green_hover">
                            <div class="card-content">
                              <h4 class=" text-title text-center m-1">Bulky Items</h4>
                              <p class="card-text text-center ">
                                Large or heavy items that might be challenging to transport due to their size or weight, such as furniture or machinery
                              </p>
                            </div>
                          </div>
                        </div>
                  </div>
  
                  <div class="carousel-item h-100 p-0">
                        <div class=" card transport_solution-card">
                          <div style=" justify-content: center; display: flex;">
                            <img class="card-img-top" src="../assets/icons/LineHaul_Delivery_Method_3.png" alt="Card image" style="padding: 10px;">
                          </div>
                            <div class="card-body  p-0 green_hover">
                              <div class="card-content">
                                <h4 class=" text-title text-center m-1">Motorbikes</h4>
                                <p class="card-text text-center ">
                                  Secure and reliable transport service specifically designed for motorcycles, whether for personal or commercial use
                                </p>
                              </div>
                            </div>
                          </div>  
                  </div>
  
                  <div class="carousel-item h-100 p-0">
                        <div class=" card transport_solution-card">
                          <div style=" justify-content: center; display: flex;">
                            <img class="card-img-top" src="../assets/icons/LineHaul_Delivery_Method_2.png" alt="Card image" style="padding: 10px;">
                          </div>
                            <div class="card-body  p-0 green_hover">
                              <div class="card-content">
                                <h4 class=" text-title text-center m-1">Products</h4>
                                <p class="card-text text-center ">
                                  A wide-ranging category covering various goods and items, from electronics and appliances to retail merchandise
                                </p>
                              </div>
                            </div>
                          </div> 
                  </div>
  
                  <div class="carousel-item h-100 p-0">
                      <div class=" card transport_solution-card">
                        <div style=" justify-content: center; display: flex;">
                          <img class="card-img-top" src="../assets/icons/LineHaul_Delivery_Method_1.png" alt="Card image" style="padding: 10px;">
                        </div>
                          <div class="card-body  p-0 green_hover">
                            <div class="card-content">
                              <h4 class=" text-title text-center m-1">Small Items</h4>
                              <p class="card-text text-center ">
                                Compact and lightweight items, despite their size which require careful handling during transportation such as electronics compnents or accessories
                              </p>
                            </div>
                          </div>
                        </div>
                  </div>
  
              </div>
          </div>
        </div>

        <!-- mobile -->
          <div class="container p-0 d-block d-md-none">
            <h4 class="text-title text-center">Unlock the Potential of Pallet <br>Shipping for Every Need!</h4>
            <div id="Personal_multi_carousel-2" class="carousel slide" data-bs-ride="carousel" wrap="false">

              <div class="container">
                <div class="row">
                  <div class="col-12 text-end lead">
                    <button class="btn btn-secondary-outline" data-bs-target="#Personal_multi_carousel-2" title="go back" data-bs-slide="prev">
                      <i class="fa fa-lg fa-chevron-left"></i>
                    </button>
                    <button class="btn btn-secondary-outline" data-bs-target="#Personal_multi_carousel-2" title="more" data-bs-slide="next">
                      <i class="fa fa-lg fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
          
                <div class="carousel-inner row w-100 mx-auto" role="listbox" style="overflow: hidden;">

                  <div class="carousel-item active h-100 p-0">
                    <div class="h-100 d-flex justify-content-center">
                    <div class="card transport_solution-card">
                      <div style=" justify-content: center; display: flex; padding-top: 15px;">
                        <img class="card-img-top" src="../assets/icons/LineHaul_Delivery_Method_4.png" alt="Card image" style=" padding: 10px;">
                      </div>
                        <div class="card-body p-0 green_hover">
                          <div class="card-content">
                            <h4 class=" text-title text-center m-1">Bulky Items</h4>
                            <p class="card-text text-center ">
                              Large or heavy items that might be challenging to transport due to their size or weight, such as furniture or machinery
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="carousel-item h-100 p-0">
                  <div class="h-100 d-flex justify-content-center">
                  <div class="card transport_solution-card">
                    <div style=" justify-content: center; display: flex; padding-top: 10px;">
                      <img class="card-img-top" src="../assets/icons/LineHaul_Delivery_Method_3.png" alt="Card image" style="padding: 10px;">
                    </div>
                      <div class="card-body  p-0 green_hover">
                        <div class="card-content">
                          <h4 class=" text-title text-center m-1">Motorbikes</h4>
                          <p class="card-text text-center ">
                            Secure and reliable transport service specifically designed for motorcycles, whether for personal or commercial use
                          </p>
                        </div>
                      </div>
                    </div>  
                  </div>
            </div>

                  <div class="carousel-item h-100 p-0">
                    <div class="h-100 d-flex justify-content-center">
                    <div class=" card transport_solution-card">
                      <div style=" justify-content: center; display: flex; padding-top: 15px;">
                        <img class="card-img-top" src="../assets/icons/LineHaul_Delivery_Method_2.png" alt="Card image" style="padding: 10px;">
                      </div>
                        <div class="card-body  p-0 green_hover">
                          <div class="card-content">
                            <h4 class=" text-title text-center m-1">Products</h4>
                            <p class="card-text text-center ">
                              A wide-ranging category covering various goods and items, from electronics and appliances to retail merchandise
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>

              <div class="carousel-item h-100 p-0">
                <div class="h-100 d-flex justify-content-center">
                <div class=" card transport_solution-card">
                  <div style=" justify-content: center; display: flex; padding-top: 15px;">
                    <img class="card-img-top" src="../assets/icons/LineHaul_Delivery_Method_1.png" alt="Card image" style="padding: 10px;">
                  </div>
                    <div class="card-body  p-0 green_hover">
                      <div class="card-content">
                        <h4 class=" text-title text-center m-1">Small Items</h4>
                        <p class="card-text text-center ">
                          Compact and lightweight items, despite their size which require careful handling during transportation such as electronics compnents or accessories
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>

                </div>
            </div>
          </div>
          
      </div>

  <div class="section pb-0">
      <!-- samsung fold -->
      <div class="container pb-5 p-0 fold-view">
        <h4 class="text-title text-center">Elevate Your Shipping Experience<br> Trust Us for Hassle-Free LCL Pallet Deliveries!</h4>
        <div id="package_Carousel_2" class="carousel slide" data-bs-ride="carousel" data-bs-interval="99999999" wrap="false">

          <div class="container">
            <div class="row">
              <div class="col-12 text-end lead">
                <button class="btn btn-secondary-outline" data-bs-target="#package_Carousel_2" title="go back" data-bs-slide="prev">
                  <i class="fa fa-lg fa-chevron-left"></i>
                </button>
                <button class="btn btn-secondary-outline" data-bs-target="#package_Carousel_2" title="more" data-bs-slide="next">
                  <i class="fa fa-lg fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
      
            <div class="carousel-inner row w-100 mx-auto" role="listbox" style="overflow: hidden;">


              <div class="carousel-item active h-100 p-0">
                <div class="h-100 d-flex justify-content-center">
                <div class="card mover-card-mobile">
                  <div class="package_card_imgBox">
                    <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_1.png" alt="Card image">
                  </div>
                    <div class="card-body  p-0 green_hover">
                      <div style="padding: 0 10px;">
                        <h4 class=" text-title text-center" style="width: auto;">Premium</h4>
                        <p class="text-center">Next Day Delivery</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            <div class="carousel-item h-100 p-0">
              <div class="h-100 d-flex justify-content-center">
              <div class=" card mover-card-mobile">
                <div class="package_card_imgBox">
                  <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_2.png" alt="Card image">
                </div>
                  <div class="card-body  p-0 green_hover">
                    <div style="padding: 0 10px;">
                      <h4 class=" text-title text-center" style="width: auto;">Express</h4>
                      <p class="text-center">Within 3 Days Delivery</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item h-100 p-0">
              <div class="h-100 d-flex justify-content-center">
              <div class=" card mover-card-mobile">
                <div class="package_card_imgBox">
                  <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_3.png" alt="Card image">
                </div>
                  <div class="card-body  p-0 green_hover">
                    <div style="padding: 0 10px;">
                      <h4 class=" text-title text-center" style="width: auto;">Motorbike</h4>
                      <p class="text-center">Within 3 Days Delivery</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<!-- 
            <div class="carousel-item h-100 p-0">
              <div class="h-100 d-flex justify-content-center">
              <div class=" card mover-card-mobile">
                <div class="package_card_imgBox">
                  <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_4.png" alt="Card image">
                </div>
                  <div class="card-body  p-0 green_hover">
                    <div style="padding: 0 10px;">
                      <h4 class=" text-title text-center" style="width: auto;">Line Clear Premium</h4>
                      <p class="text-center">Next Day Delivery</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item h-100 p-0">
              <div class="h-100 d-flex justify-content-center">
              <div class=" card mover-card-mobile">
                <div class="package_card_imgBox">
                  <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_5.png" alt="Card image">
                </div>
                  <div class="card-body  p-0 green_hover">
                    <div style="padding: 0 10px;">
                      <h4 class=" text-title text-center" style="width: auto;">Line Clear Express</h4>
                      <p class="text-center">Next Day Delivery</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            </div>
        </div>
      </div>
      
      <!-- mobile -->
      <div class=" pb-5 p-0 d-block d-md-none fold-no-view">
        <h4 class="text-title text-center">Elevate Your Shipping Experience<br> Trust Us for Hassle-Free LCL <br>Pallet Deliveries!</h4>
        <div id="package_Carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="9999999">

          <div class="container">
            <div class="row">
              <div class="col-12 text-end lead">
                <button class="btn btn-secondary-outline" data-bs-target="#package_Carousel" title="go back" data-bs-slide="prev">
                  <i class="fa fa-lg fa-chevron-left"></i>
                </button>
                <button class="btn btn-secondary-outline" data-bs-target="#package_Carousel" title="more" data-bs-slide="next">
                  <i class="fa fa-lg fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
      
            <div class="carousel-inner row w-100 mx-auto" role="listbox" style="overflow: hidden;">


              <div class="carousel-item active h-100 p-0">
                <!-- <div class="h-100 d-flex justify-content-center"> -->
                <div class="card mover-card-mobile">
                  <div class="package_card_imgBox">
                    <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_1.png" alt="Card image">
                  </div>
                    <div class="card-body  p-0 green_hover">
                      <div style="padding: 0 10px;">
                        <h4 class=" text-title text-center" style="width: auto;">Premium</h4>
                        <p class="text-center">Next Day Delivery</p>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->
              </div>

            <div class="carousel-item h-100 p-0">
              <!-- <div class="h-100 d-flex justify-content-center"> -->
              <div class=" card mover-card-mobile">
                <div class="package_card_imgBox">
                  <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_2.png" alt="Card image">
                </div>
                  <div class="card-body  p-0 green_hover">
                    <div style="padding: 0 10px;">
                      <h4 class=" text-title text-center" style="width: auto;">Express</h4>
                      <p class="text-center">Within 3 Days Delivery</p>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
            </div>

            <div class="carousel-item h-100 p-0">
              <!-- <div class="h-100 d-flex justify-content-center"> -->
              <div class=" card mover-card-mobile">
                <div class="package_card_imgBox">
                  <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_3.png" alt="Card image">
                </div>
                  <div class="card-body  p-0 green_hover">
                    <div style="padding: 0 10px;">
                      <h4 class=" text-title text-center" style="width: auto;">Motorbike</h4>
                      <p class="text-center">Within 3 Days Delivery</p>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
            </div>

            <!-- <div class="carousel-item h-100 p-0">
              <div class=" card mover-card-mobile">
                <div class="package_card_imgBox">
                  <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_4.png" alt="Card image">
                </div>
                  <div class="card-body  p-0 green_hover">
                    <div style="padding: 0 10px;">
                      <h4 class=" text-title text-center" style="width: auto;">Line Clear Premium</h4>
                      <p class="text-center">Next Day Delivery</p>
                    </div>
                  </div>
                </div>
            </div>

            <div class="carousel-item h-100 p-0">
              <div class=" card mover-card-mobile">
                <div class="package_card_imgBox">
                  <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_5.png" alt="Card image">
                </div>
                  <div class="card-body  p-0 green_hover">
                    <div style="padding: 0 10px;">
                      <h4 class=" text-title text-center" style="width: auto;">Line Clear Express</h4>
                      <p class="text-center">Next Day Delivery</p>
                    </div>
                  </div>
                </div>
            </div> -->

            </div>
        </div>
      </div>

      <!-- Delivery package segment-laptop/desktop -->
      <div class="container d-none p-0 d-md-block"> 
        <h4 class="text-title text-center">Elevate Your Shipping Experience</h4>
        <h4 class="text-title text-center">Trust Us Hassle-Free LTL Pallet Deliveries!</h4>

        <div class="d-flex justify-content-center" style="flex-wrap: wrap;">

          <div class="d-flex w-100 mx-auto" role="listbox" style="overflow: hidden;">


                <div class="card mover-card-mobile">
                  <div class="package_card_imgBox">
                    <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_1.png" alt="Card image">
                  </div>
                    <div class="card-body  p-0 green_hover">
                      <div style="padding: 0 10px;">
                        <h4 class=" text-title text-center" style="width: auto;">Premium</h4>
                        <p class="text-center">Next Day Delivery</p>
                      </div>
                    </div>
                  </div>





                  <div class=" card mover-card-mobile">
                    <div class="package_card_imgBox">
                      <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_2.png" alt="Card image">
                    </div>
                      <div class="card-body  p-0 green_hover">
                        <div style="padding: 0 10px;">
                          <h4 class=" text-title text-center" style="width: auto;">Express</h4>
                          <p class="text-center">Within 3 Days Delivery</p>
                        </div>
                      </div>
                    </div>





                  <div class=" card mover-card-mobile">
                    <div class="package_card_imgBox">
                      <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_3.png" alt="Card image">
                    </div>
                      <div class="card-body  p-0 green_hover">
                        <div style="padding: 0 10px;">
                          <h4 class=" text-title text-center" style="width: auto;">Motorbike</h4>
                          <p class="text-center">Within 3 Days Delivery</p>
                        </div>
                      </div>
                    </div>





                <!-- <div class=" card mover-card-mobile">
                  <div class="package_card_imgBox">
                    <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_4.png" alt="Card image">
                  </div>
                    <div class="card-body  p-0 green_hover">
                      <div style="padding: 0 10px;">
                        <h4 class=" text-title text-center" style="width: auto;">Line Clear Premium</h4>
                        <p class="text-center">Next Day Delivery</p>
                      </div>
                    </div>
                  </div>



                <div class=" card mover-card-mobile">
                  <div class="package_card_imgBox">
                    <img class="packageCard-img-top" src="../assets/icons/LineHaul_Delivery_Package_5.png" alt="Card image">
                  </div>
                    <div class="card-body  p-0 green_hover">
                      <div style="padding: 0 10px;">
                        <h4 class=" text-title text-center" style="width: auto;">Line Clear Express</h4>
                        <p class="text-center">Next Day Delivery</p>
                      </div>
                    </div>
                  </div> -->


          </div>
        </div>
      </div>    
  </div>



  </div>

  <app-footer></app-footer>
</div>