import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin_carousel.component.html',
  styleUrls: ['./admin_carousel.component.css']
})

export class AdminCarouselComponent implements OnInit {
  carousels: any[] = []; 
  newCarousel: any = {};
  isEditing: boolean = false;
  editCarouselId: number | null = null;
  editCarouselDetails: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 5; 
  formSubmit: boolean = false;
  formEdit: boolean = false; 
  selectedPage: string = '';

  form: FormGroup;
  editForm: FormGroup;

  sortBy: string = 'id';
  sortAsc: boolean = true;

  searchTerm: string = '';

  get totalPages() {
    return Math.ceil(this.carousels.length / this.itemsPerPage);
  }

  get pages() {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  get uniquePages() {
    return Array.from(new Set(this.carousels.map(item => item.page)));
  }

  get filteredData() {
    if (this.selectedPage) {
      return this.carousels.filter(item => item.page === this.selectedPage);
    } else {
      return this.carousels;
    }
  }

  get paginatedData() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredData.slice(start, end);
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  generatePageArray(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
  ) { 

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      description: ['', Validators.required],
      page: ['', Validators.required],
      number: ['', Validators.required],
      state: ['', Validators.required],

    });

    this.editForm = this.formBuilder.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      description: ['', Validators.required],
      page: ['', Validators.required],
      number: ['', Validators.required],
      state: ['', Validators.required],

    });
  }

  ngOnInit(): void {
    this.loadCarousel();
  }

  loadCarousel(): void {
    this.apiService.loadCarousel().subscribe(
      (response) => {
        console.log('API Response:', response);
        this.carousels = response.carousels;
        this.applySort(); 
      },
      (error) => {
        console.error('Error fetching carousel:', error);
      }
    );
  }

  sortByColumn(column: string) {
    if (this.sortBy === column) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortBy = column;
      this.sortAsc = true;
      this.currentPage = 1; 

    }
    this.applySort();
  }

  applySort() {
    this.carousels.sort((a, b) => {
      const order = this.sortAsc ? 1 : -1;
      return a[this.sortBy] > b[this.sortBy] ? order : -1 * order;
    });

    this.currentPage = 1;
  }

  applyFilter(): void {
    if (this.searchTerm.trim() !== '') {
      this.carousels = this.carousels.filter(
        (data) =>
          data.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          data.url.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          data.description.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          data.page.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    } else {
      this.loadCarousel();
    }
    this.applySort(); 
  }
  
  
  //-------------ADD, EDIT, REMOVE data-------------------------------------------------
  addCarousel() {
    this.formSubmit = true;  
    if (this.form.valid) {
      this.apiService.store('carousel', this.form.value).subscribe(
        () => {
          this.form.reset();
          this.loadCarousel();
          this.formSubmit = false;
        },
        (error) => {
          console.error('Error adding carousel entry:', error);
        }
      );
    }
  }

  editCarousel(carousel: any) {
    this.isEditing = true;
    this.editCarouselId = carousel.id;
    this.editForm.patchValue(carousel);

  }
  
  saveEdit() {
    this.apiService.update('carousel', this.editCarouselId, this.editForm.value).subscribe(
      () => {
        this.isEditing = false;
        this.editCarouselId = null;
        this.editForm.reset();
        this.loadCarousel();

      },
      (error) => {
        console.error('Error updating carousel entry:', error);
      }
    );
    this.formEdit = true; 
  }
  
  cancelEdit() {
    this.isEditing = false;
    this.editCarouselId = null;
    this.editCarouselDetails = {};
  }

  deleteCarousel(id: number) {
    this.apiService.destroy('carousel', id).subscribe(
      () => {
        this.loadCarousel();
      },
      (error) => {
        console.error('Error deleting carousel entry:', error);
      }
    );
  }
}
