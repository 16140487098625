<div class="fit_the_screen">
  <app-header></app-header>

   <!-- The slideshow/carousel -->
   <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
          </div>      
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div> 

  
  <div class="section pb-0" >
    <!-- upper segment -->

    <div class="custom-container container p-0 w-95" >
      <div>
        <h4 class="text-title text-center">
          Enquiry Form
          <br><br>
          Feel free to get in touch with us by sharing your questions or requests through the form below.
          <br>
          We're here to help!
        </h4>
      </div>

       <!-- laptop/desktop -->
  <div class="d-none d-sm-block">
    <form [formGroup]="form" (ngSubmit)="sendMail()" >
      <div style="margin: auto;">
        <table class="table table-borderless">
          <thead></thead>
          <tbody>
            <tr>
              <td class="input-control">
                <label for="enquiryName">Name</label>
                <input id="enquiryName" name="Name" class="form-control inputBorder" type="text" formControlName="enquiryName" required>
                <div *ngIf="submitted && form.get('enquiryName').invalid" class="invalid-feedback d-block">Please fill out this field.</div>

              </td>
            </tr>
            <tr>
              <td class="input-control">
                <label for="enquiryEmail">Address</label>
                <input id="enquiryEmail" name="Address" class="form-control inputBorder" type="text" formControlName="enquiryAddress" required>
                <div *ngIf="submitted && form.get('enquiryAddress').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control">
                <label for="enquiryEmail">Email</label>
                <input id="enquiryEmail" name="Email" class="form-control inputBorder" type="email" formControlName="enquiryEmail" required>
                <div *ngIf="submitted && form.get('enquiryEmail').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control">
                <label for="enquiryPhone">Phone</label>
                <input id="enquiryPhone" name="Phone Number" class="form-control inputBorder" type="text" formControlName="enquiryPhone" required>
                <div *ngIf="submitted && form.get('enquiryPhone').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control">
                <label for="enquiryMessage">Message</label>
                <textarea id="enquiryMessage" name="Comments" class="form-control inputBorder" rows="9" formControlName="enquiryMessage" required></textarea>
                <div *ngIf="submitted && form.get('enquiryMessage').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container" style="display: flex; justify-content: center;">
        <button id="submitButton" class="btn inputButton" type="button" (click)="submitForm()" style="width: 220px; font-size: 20px; align-items:center;">Submit</button>
      </div>
    </form>
  </div>

  <!-- mobile -->
  <div class="d-block d-sm-none">
    <form [formGroup]="form" (ngSubmit)="sendMail()">
      <div style="margin: auto;">
        <table class="table table-borderless">
          <thead></thead>
          <tbody>
            <tr>
              <td class="input-control">
                <label for="enquiryName">Name</label>
                <input id="enquiryName" name="Name" class="form-control inputBorder" type="text" formControlName="enquiryName" required>
                <div *ngIf="submitted && form.get('enquiryName').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control">
                <label for="enquiryEmail">Address</label>
                <input id="enquiryEmail" name="Address" class="form-control inputBorder" type="text" formControlName="enquiryAddress" required>
                <div *ngIf="submitted && form.get('enquiryAddress').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control">
                <label for="enquiryEmail">Email</label>
                <input id="enquiryEmail" name="Email" class="form-control inputBorder" type="email" formControlName="enquiryEmail" required>
                <div *ngIf="submitted && form.get('enquiryEmail').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control">
                <label for="enquiryPhone">Phone</label>
                <input id="enquiryPhone" name="Phone Number" class="form-control inputBorder" type="text" formControlName="enquiryPhone" required>
                <div *ngIf="submitted && form.get('enquiryPhone').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control">
                <label for="enquiryMessage">Message</label>
                <textarea id="enquiryMessage" name="Comments" class="form-control inputBorder" rows="9" formControlName="enquiryMessage" required></textarea>
                <div *ngIf="submitted && form.get('enquiryMessage').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container" style="display: flex; justify-content: center;">
        <button id="submitButton_mobile" class="btn inputButton" type="button" (click)="submitForm()" style="width: 220px; font-size: 20px; align-items:center;">Submit</button>
      </div>
    </form>
  </div>
</div>


    <!--  -->

    <!-- lower segment -->
    <div class="section pb-0">
      <div class="container p-0">
        <!-- laptop/desktop -->
      <div class="d-none d-sm-block">
        <div class="row ">
          <div class="col-6 p-0">
            <img class="card-img-top card-img" src="../assets/image/Icons/Contact_Us_img.png" alt="Card image" height="100%" width="100%">
          </div>
            <div class="col-6" style="padding: 35px 20px;">
              <!-- <div class="container"> -->
              <h4>
                Customer Care
              </h4>
                <br>
                  <h4>
                    Line Haul Sdn Bhd
                  </h4>
                      <p>
                        Registration No. 20190104236 (1351766-K)
                        <br>
                        Level 2, No 3, Jalan TP2, Taman Peridustrian UEP, Subang Jaya, 47600 Selangor.
                      </p>
                          <p><span style="font-weight: bold;">Operation Hours:</span> 9.00am - 6.00pm (Monday - Friday)</p>
                            <p><span style="font-weight: bold;">Tel No:</span>  016-3334816</p>
                              <p><span style="font-weight: bold;">Email:</span>  knockknock@linehaul.com.my</p>
                                <!-- <p><span style="font-weight: bold;">Facebook:</span>  https://www.facebook.com/linehaulexpress</p>                 -->
              <!-- </div> -->

            </div>    
        </div>
      </div>

    <!-- mobile -->
      <div class="d-block d-sm-none">
      <div class="row">
        <div class="p-0 w-100">
          <img class="card-img-top card-img w-100" src="../assets/image/Icons/Contact_Us_img.png" alt="Card image" height="100%">
        </div>
        <div class="container" style="padding: 35px 20px;">
          <h4>
            Customer Care
          </h4>
          <br>
          <h4>
            Line Haul Sdn Bhd
          </h4>
          <p>
            Registration No. 20190104236 (1351766-K)
            <br>
            Level 2, No 3, Jalan TP2, Taman Peridustrian UEP, Subang Jaya, 47600 Selangor.
          
          </p>
            <p><span style="font-weight: bold;">Operation Hours:</span> 9.00am - 6.00pm (Monday - Friday)</p>
            <p><span style="font-weight: bold;">Tel No:</span>016-3334816</p>
            <p  class="urls"><span style="font-weight: bold;">Email:</span><br>knockknock@linehaul.com.my</p>
            <!-- <p  class="urls"><span style="font-weight: bold;">Facebook:</span><br>https://www.facebook.com/linehaulexpress</p> -->
        </div>
      </div>
      </div>
    </div>

    
    </div>

  </div>

  <app-footer></app-footer>
</div>