import { Component, OnInit,AfterViewInit,ElementRef,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';

declare var $: any; // Declare jQuery

@Component({
  selector: 'app-home',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.css']
})
export class NetworkComponent implements OnInit, AfterViewInit {
  searchTerm: string = '';
  locations: any[] = [];
  sliders: any[] = [];

  // Reference to the map element in the template
  @ViewChild('map') mapElement: ElementRef;

  constructor(
    private route: Router,
    private viewportScroller: ViewportScroller,
    private titleService: Title,
    private metaService: Meta,
    private el: ElementRef,
    private httpClient: HttpClient,
    private apiService: ApiService,

  ) { }

  ngOnInit(): void {

        this.titleService.setTitle("Network | Line Haul")

        const metaDesc = this.metaService.getTag('name=description');

        if(!metaDesc){
            this.metaService.addTags([
                {name: "description", content: "Explore the ease of managing your logistics requirements effortlessly under one roof with our nationwide one-stop center."}
            ]);
        }else{
            this.metaService.updateTag(
                {name: "description", content: "Explore the ease of managing your logistics requirements effortlessly under one roof with our nationwide one-stop center." },
                "name=description"
            )
        }
        this.loadCarousel('network', 'active');
        this.loadNetwork();

  }

  loadCarousel(page: string, state: string): void {
    this.apiService.loadCarousel(page, state).subscribe(
        response => {
            this.sliders = response.carousels;
        },
        error => {
            console.error('Error fetching carousel:', error);
        }
    );
  }

  loadNetwork(): void {
    this.apiService.loadNetwork().subscribe(
      response => {
        this.locations = response.network_lists;
        const all= document.getElementById('search_button');  
        all.click(); 
      },
      error => {
        console.error('Error fetching network:', error);
      }
    );
  }

  ngAfterViewInit(): void {
    // Get the specific carousel elements by ID
    const networkCarousel: HTMLElement | null = this.el.nativeElement.querySelector('#network_carousel');
    const mobileNetworkCarousel: HTMLElement | null = this.el.nativeElement.querySelector('#mobile_network_carousel');
      // Define flags to keep track of whether the functions have been called
    let desktopCarouselInitialized = false;
    let mobileCarouselInitialized = false;
    this.carouselHandler(mobileNetworkCarousel);
    this.carouselHandler(networkCarousel);
    // Initial search on input change
    document.getElementById('search_button').addEventListener('click', () => {
      // Perform filtering
      this.filterCarousel();

      // Determine the screen size and switch between carousels accordingly
      if (window.innerWidth >= 768) {
        // If the screen size is 768px or above, use the desktop network carousel
        if (networkCarousel) {
          // console.log('Changed to id desktop');
          desktopCarouselInitialized = true;
          this.initializeMultiItemCarousel(networkCarousel);
        } else {
          // console.log('Desktop carousel not found.');
        }
      } else {
        // If the screen size is below 768px, use the mobile network carousel
        if (mobileNetworkCarousel) {
          // console.log('Changed to id mobile');
          this.carouselHandler(mobileNetworkCarousel);
        } else {
          // console.log('Mobile carousel not found.');
        }
      }
    });


    // Listen for window resize events to dynamically switch between carousels
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        // If the screen size is 768px or above, use the desktop network carousel
        if (networkCarousel && !desktopCarouselInitialized) {
          // console.log('Changed to id desktop');
          this.initializeMultiItemCarousel(networkCarousel);
          desktopCarouselInitialized = true;
          mobileCarouselInitialized = false;
        } else if (!networkCarousel) {
          // console.log('Desktop carousel not found.');
        }
      } else {
        // If the screen size is below 768px, use the mobile network carousel
        if (mobileNetworkCarousel && !mobileCarouselInitialized) {
          // console.log('Changed to id mobile');
          this.carouselHandler(mobileNetworkCarousel);
          mobileCarouselInitialized = true;
        } else if (!mobileNetworkCarousel) {
          // console.log('Mobile carousel not found.');
        }
      }
    });
  }

  initializeMultiItemCarousel(carouselElement: HTMLElement) {
    // Get all carousel items within the specific carousel element
    const items: NodeListOf<Element> = carouselElement.querySelectorAll('.carousel .carousel-item');

    // Set the number of carousel items based on the screen width
    let minPerSlide: number;

      this.carouselHandler(carouselElement);

    // Check if there is more than one item
    if (items.length > 1) {
      items.forEach((el: Element) => {
        // number of carousel items displayed
        if (items.length === 2) {
          minPerSlide = 2;
        }else{
          minPerSlide = 3;
        };

        let next: Element | null = el.nextElementSibling;

        for (let i = 1; i < minPerSlide; i++) {
          if (!next) {
            // Wrap carousel by using the first child
            next = items[0];
          }

          const cloneChild: Node = next.cloneNode(true);
          el.appendChild((cloneChild as Element).children[0]);
          next = next.nextElementSibling;
        }
      });


    }

    //     if (items.length <= 2) {
    //   document.querySelector('#network_carousel').classList.add('single_item_transition');
    // }

    // Initialize the carousel
    $(carouselElement).carousel();
  }

  carouselHandler(carouselElement: HTMLElement) {
    const items: NodeListOf<Element> = carouselElement.querySelectorAll('.carousel .carousel-item');
    // Get the buttons
    const prevButton = carouselElement.querySelector('[data-bs-slide="prev"]');
    const nextButton = carouselElement.querySelector('[data-bs-slide="next"]');
    let desktopLogicToggle = false;
    let mobileLogicToggle = false;

    // Immediately apply the condition when the screen loads
    const applyCondition = () => {
      if (items.length <= 3) {
        nextButton.classList.add('d-none');
        prevButton.classList.add('d-none');
        document.querySelector('#network_carousel').classList.add('single_item_transition');

      } else {
        nextButton.classList.remove('d-none');
        prevButton.classList.remove('d-none');
        document.querySelector('#network_carousel').classList.remove('single_item_transition');

      }
    };

    // Apply condition on initial load
    applyCondition();

    if(window.innerWidth >= 768){
      // Add event listener to hide/show next and prev buttons on slide
        carouselElement.addEventListener('slide.bs.carousel', (event: any) => {
          const currentIndex = event.to;
          const totalItems = items.length;
          console.log('Changed to desktop logic');
          desktopLogicToggle = true;
          // Hide/show buttons based on the current index
          if (currentIndex === 0) {
            // On the first item, hide the prev button
            prevButton.classList.add('d-none');
          } else {
            // Not on the first item, show the prev button
            prevButton.classList.remove('d-none');
          }

          if (currentIndex === totalItems - 3) {
            // On the second-to-last item (considering the cloned items), hide the next button
            nextButton.classList.add('d-none');
          } else {
            // Not on the second-to-last item, show the next button
            nextButton.classList.remove('d-none');
          }
        });
      }
      // Check screen size and apply condition accordingly
      else {
        console.log('Changed to mobile logic');
        mobileLogicToggle = true;
        carouselElement.addEventListener('slide.bs.carousel', (event: any) => {
          const currentIndex = event.to;
          const totalItems = items.length;

          // Hide/show buttons based on the current index
          if (currentIndex === 0) {
            // On the first item, hide the prev button
            prevButton.classList.add('d-none');
          } else {
            // Not on the first item, show the prev button
            prevButton.classList.remove('d-none');
          }

          if (currentIndex === totalItems - 1) {
            // On the second-to-last item (considering the cloned items), hide the next button
            nextButton.classList.add('d-none');
          } else {
            // Not on the second-to-last item, show the next button
            nextButton.classList.remove('d-none');
          }
        });
      }

    // Apply condition on window resize
    window.addEventListener('resize', () => {
      if(window.innerWidth >= 768){
          // console.log('Changed to desktop logic');
          desktopLogicToggle = true;
          mobileLogicToggle = false;
      // Add event listener to hide/show next and prev buttons on slide
        carouselElement.addEventListener('slide.bs.carousel', (event: any) => {
          const currentIndex = event.to;
          const totalItems = items.length;

          // Hide/show buttons based on the current index
          if (currentIndex === 0) {
            // On the first item, hide the prev button
            prevButton.classList.add('d-none');
          } else {
            // Not on the first item, show the prev button
            prevButton.classList.remove('d-none');
          }

          if (currentIndex === totalItems - 3) {
            // On the second-to-last item (considering the cloned items), hide the next button
            nextButton.classList.add('d-none');
          } else {
            // Not on the second-to-last item, show the next button
            nextButton.classList.remove('d-none');
          }
        });
      }
      // Check screen size and apply condition accordingly
      else {
        // console.log('Changed to mobile logic');
        mobileLogicToggle = true
        carouselElement.addEventListener('slide.bs.carousel', (event: any) => {
          const currentIndex = event.to;
          const totalItems = items.length;

          // Hide/show buttons based on the current index
          if (currentIndex === 0) {
            // On the first item, hide the prev button
            prevButton.classList.add('d-none');
          } else {
            // Not on the first item, show the prev button
            prevButton.classList.remove('d-none');
          }

          if (currentIndex === totalItems - 1) {
            // On the second-to-last item (considering the cloned items), hide the next button
            nextButton.classList.add('d-none');
          } else {
            // Not on the second-to-last item, show the next button
            nextButton.classList.remove('d-none');
          }
        });
      }
    });
  }

  filterCarousel() {
    // Array of carousel IDs
    const carouselIds: string[] = ['#network_carousel', '#mobile_network_carousel'];

    // Get the search term
    const searchTerm = (document.getElementById("location_input") as HTMLInputElement).value.trim().toLowerCase();

    // Check if the search term is empty
    // if (searchTerm === '') {
    //   // Clear the existing content
    //   carouselIds.forEach((carouselId) => {
    //     var resultContainer = document.querySelector(`${carouselId} .carousel-inner`);
    //     resultContainer.innerHTML = "";
    //   });

    //   // Exit the function or handle it accordingly
    //   return;
    // }

    // Continue with filtering logic
    carouselIds.forEach((carouselId) => {
      var resultContainer = document.querySelector(`${carouselId} .carousel-inner`);
      resultContainer.innerHTML = ""; // Clear existing content

      var matchCount = 0;

      this.locations.forEach(function(location, index) {
        var addressText = location.address.toLowerCase();
        var branchText = location.branch.toLowerCase();
        var isMatch = false; // Flag to check if any condition is met

        // Check if any condition is met (OR condition)
        if (addressText.includes(searchTerm) ||
          location.tel.toLowerCase().includes(searchTerm) ||
          location.email.toLowerCase().includes(searchTerm) ||
          branchText.includes(searchTerm)) {
          isMatch = true;
        }

        // Check if all conditions are met (AND condition)
        if (isMatch) {
          var carouselItem = document.createElement('div');
          carouselItem.classList.add('carousel-item', 'p-0', 'h-100');

          carouselItem.innerHTML = `

            <div class="card transport_solution-card">
              <div style="justify-content: center; display: flex; background-color:#2a2a86;   border-top-right-radius: 20px;
              border-top-left-radius: 20px;">
               <!-- <img class="card-img-top" src="../assets/image/Icons/map-landscape.jpg" alt="Card image"> -->
               <h4 style="color:white;">${location.branch}</h4>
              </div>
              <div class="card-body p-0 green_hover">
                <div class="card-content">
                  <span style="font-weight:bold;">Address:</span> <p>${location.address}</p>
                  <span style="font-weight:bold;">Tel:</span> <p>${location.tel}</p>
                  <span style="font-weight:bold;">Email:</span> <p>${location.email}</p>
                </div>
              </div>
            </div>
          `;

          resultContainer.appendChild(carouselItem);

          // Add 'active' class to the second item if there are more than one matching item
          if (matchCount === 1) {
            carouselItem.classList.add('active');
          }

          matchCount++;
        }
      });

      // Add 'active' class to the first item if there is only one matching item
      if (resultContainer.childElementCount === 1) {
        resultContainer.firstElementChild.classList.add('active');
      }

      // Display a message if no matching location found
      if (resultContainer.childElementCount === 0) {
        resultContainer.innerHTML = '<h3 style="text-align:center;">No matching location found.</h3>';
      }
    });
  }

  //  // Method to handle map pinning logic
  // pinLocationOnMap(location: any) {
  //   this.mapsAPILoader.load().then(() => {
  //     const map = new google.maps.Map(this.mapElement.nativeElement, {
  //       center: { lat: location.latitude, lng: location.longitude },
  //       zoom: 15, // Adjust the zoom level as needed
  //     });

  //     const marker = new google.maps.Marker({
  //       position: { lat: location.latitude, lng: location.longitude },
  //       map: map,
  //       title: location.name
  //     });

  //     map.setCenter({ lat: location.latitude, lng: location.longitude });
  //   });
  // }


  goToPremium(){
    window.open('https://linehaul.com.my/assets/image/Icons/premium.jpg', '_blank');
  }

  goToExpress(){
    window.open('https://linehaul.com.my/assets/image/Icons/express.jpg', '_blank');
  }


  public onClick(elementId: string): void {

    this.viewportScroller.scrollToAnchor(elementId);

    //   window.location.href = '/v2/landing#AnchorId';
  }

  public goToFragment(fragment: string): void{

    // let elementId = 'serviceId'

    // this.route.navigate( ['/landing#'], {fragment: fragment});
    // this.route.navigate(['/landing#'], { fragment: 'serviceId' });
    // this.route.navigate(['/landing#'+fragment])
    // this.redirectTo('/landing#serviceId');
    // window.location.href = '/v2/landing#' + fragment;
    // window.location.href = '/landing#' + fragment;
    // window.location.href = 'https://lineclearexpress.com/v2/landing#serviceId'
    // window.location.replace("http://someUrl.com");
    // this.viewportScroller.scrollToAnchor(elementId);

    // production
    // this.route.navigateByUrl("/v2/landing#serviceId")

    // staging
    this.route.navigateByUrl("/#serviceId")

  }

  goToAboutUs(){
    this.route.navigate(['about-us']);
  }

  goToOurStrength(){
    this.route.navigate(['our_strength']);
  }

  goToHome(){
    this.route.navigate(['home']);
  }

  goToMilestone(){
    this.route.navigate(['milestone']);
  }

  goToWhyUs(){
    this.route.navigate(['why_us']);
  }

  goToPersonal(){
    this.route.navigate(['personal']);
  }

  goToNetwork(){
    this.route.navigate(['network']);
  }

}