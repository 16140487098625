<div class="fit_the_screen">
  <app-header></app-header>

   <!-- The slideshow/carousel -->
   <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> 
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
        <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
        <div class="captionBox carousel-caption text-start">
          <div class="captionContent">
            <h3 class="captionTitle">{{ slider.name }}</h3>
            <p class="slideShow_Caption text-start">{{ slider.description }}</p>
            <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
          </div>      
        </div>
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div> 
  <div class="section">
    <h4 class="text-title text-center">Accelerate Your Career</h4>
    <h4 class="text-title text-center">Become A Driving Force In Our Logistics Family!</h4>

     <!-- Requirement Section -->
     <div class="col-sm-12 col-md-6">
      <div class="card card-mobile">
        <div class="card-img-box">
          <img class="card-img-top card-img" src="../assets/icons/Line Haul Icon Pack-10.png" alt="Card image">
        </div>
        <div class="card-body green_hover">
          <div>
            <h4 class="card-title text-title text-center" style="width: auto;">Driver (Requirements) </h4>
            <ul>
              <li>Possess driving license GDL- D / E-Sendi / E-Kejur</li>
              <li>To provide secure and timely driving services to the company</li>
              <li>To deliver the goods to customer responsibly</li>
              <li>Able to complete delivery within time frame given</li>
              <li>Responsible for loading, transporting and deliver goods to respected regions</li>
              <li>Maintaining cleanliness of the company trucks</li>
              <li>To work according to the schedule allocated by the Operations Team</li> 
              <li>To comply with company SOP, safety environmental rules & regulations</li>
            </ul>
            <h5>Interested?</h5>
            <p>Email address: recruit@linehaul.com.my</p>
          </div>
        </div>
      </div>
    </div>

  <!-- Driver Form ------------------->

  <!-- laptop/desktop -->
  <div class="row hidden-sm hidden-xs">
    <form [formGroup]="form" (ngSubmit)="sendMail()">
      <div style="margin: auto;">
        <table class="table table-borderless">
          <thead></thead>
          <tbody>
            <h4 class="text-title text-center">Be Part Of Us!</h4>
            <tr>
              <td class="input-control" style="border: none;">
                <label for="driverName">Name</label>
                <input id="driverName" name="Name" class="form-control inputBorder" type="text" formControlName="driverName" required>
                <div *ngIf="submitted && form.get('driverName').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control" style="border: none;">
                <label for="driverEmail">Email</label>
                <input id="driverEmail" name="Email" class="form-control inputBorder" type="email" formControlName="driverEmail" required>
                <div *ngIf="submitted && form.get('driverEmail').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control" style="border: none;">
                <label for="driverPhone">Phone</label>
                <input id="driverPhone" name="Phone Number" class="form-control inputBorder" type="text" formControlName="driverPhone" required>
                <div *ngIf="submitted && form.get('driverPhone').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control" style="border: none;">
                <label for="driverFile">File</label>
                <input id="driverFile" name="File" class="form-control inputBorder" type="file" (change)="onFileChange($event)" formControlName="driverFile" required>
                <div *ngIf="submitted && form.get('driverFile').invalid" class="invalid-feedback d-block">Please choose a file.</div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container" style="display: flex; justify-content: center;">
        <button id="submitButton" class="btn inputButton" type="button" (click)="submitForm()" style="width: 220px; font-size: 20px; align-items:center;">Submit</button>
      </div>
    </form>
  </div>

  <!-- mobile -->
  <div class="d-block hidden-lg hidden-md">
    <form [formGroup]="form" (ngSubmit)="sendMail()">
      <div style="margin: auto;">
        <table class="table table-borderless">
          <thead></thead>
          <tbody>
            <tr>
              <td class="input-control" style="border: none;">
                <label for="driverName">Name</label>
                <input id="driverName" name="Name" class="form-control inputBorder" type="text" formControlName="driverName" required>
                <div *ngIf="submitted && form.get('driverName').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control" style="border: none;">
                <label for="driverEmail">Email</label>
                <input id="driverEmail" name="Email" class="form-control inputBorder" type="email" formControlName="driverEmail" required>
                <div *ngIf="submitted && form.get('driverEmail').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control" style="border: none;">
                <label for="driverPhone">Phone</label>
                <input id="driverPhone" name="Phone Number" class="form-control inputBorder" type="text" formControlName="driverPhone" required>
                <div *ngIf="submitted && form.get('driverPhone').invalid" class="invalid-feedback d-block">Please fill out this field.</div>
              </td>
            </tr>
            <tr>
              <td class="input-control" style="border: none;">
                <label for="driverFile">File</label>
                <input id="driverFile" name="File" class="form-control inputBorder" type="file" (change)="onFileChange($event)" formControlName="driverFile" required>
                <div *ngIf="submitted && form.get('driverFile').invalid" class="invalid-feedback d-block">Please choose a file.</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container" style="display: flex; justify-content: center;">
        <button id="submitButton_mobile" class="btn inputButton" type="button" (click)="submitForm()" style="width: 220px; font-size: 20px; align-items:center;">Submit</button>
      </div>
    </form>
  </div>

    <!-- <h4 class="text-title text-center">Let's earn Yours Now!</h4>
    <h4 class="text-title text-center">(benefit be a Line Haul driver)</h4>
    <div class=" justify-content-center d-flex">
      <div class="driver-img-wrapper" style="width: 200px;">
        <img class="card-img-top delivery-choice-img" src="../assets/image/Icons/linehaulLogo.png" alt="Card image">
      </div>
    </div> -->

  </div>

  <app-footer></app-footer>
</div>