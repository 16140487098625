<app-header></app-header>

<div class="section" style="margin-top: 5%;">
    <div class="container">
      <div class="row" style="margin: 50px 0px;">
        <div class="col-md-12">

          <p class="mbr-text mbr-fonts-style display-7 textjustify " style="text-align: left;">
            <strong>1. Introduction to this Privacy Policy</strong>
          </p>
          <p class="mbr-text mbr-fonts-style display-7 text-justify" >
            1.1. In the course of you using LINEHAUL applications and websites (both web and mobile versions), as well as products, information, functions and other services operated by LINEHAUL, we will be collecting, using, disclosing, storing and/or processing data, including your personal data. In this Privacy Policy, Platform shall refer to all relevant applications and websites (both web and mobile versions), and Services shall refer to all products, information, functions and services provided by LINEHAUL from time to time at the Platform(s).
            <br><br>1.2. This Privacy Policy exists to keep you in the know about how we collect, use, disclose, store and/or process the data we collect and receive during the course of providing the Services or access to the Platform to you, our user, whether or not you have registered to use our Platform as a customer or partner. We will only collect, use, disclose, store and/or process your personal data in accordance with this Privacy Policy.
            <br><br>1.3. It is important that you read this Privacy Policy together with any other applicable notices we may provide for special applications where when we are collecting, using, disclosing and/or processing personal data about you, so that you are fully aware of how and why we are using your personal data.
            <br><br>1.4. We may update this Privacy Policy from time to time. Any changes we make to this Privacy Policy in the future will be reflected on this page and material changes will be notified to you. Where permissible under local laws, your continued use of the Services or access to the Platform, including placing Orders (as defined in the Terms of Use) on the Platform, or express consent thereto, following the modifications, updates or amendments to this Privacy Policy (whether or not you have reviewed such document) shall constitute your acknowledgment and acceptance of the changes we make to this Privacy Policy. You agree that it is your responsibility to review and check the Privacy Policy frequently to see if any updates or changes have been made to this Privacy Policy.
            <br><br>1.5. This Privacy Policy applies in conjunction with other notices, contractual clauses and consent clauses that apply in relation to the collection, storage, use, disclosure and/or processing of your personal data by us and is not intended to override them unless we state expressly otherwise.
            <br><br>1.6. All of these terms apply to LINEHAUL's users, whether or not the users have created an account as customer and/or partners, unless otherwise stated specifically to apply only to customer or only to partners.
            
          </p>
        
          <p class="mbr-text mbr-fonts-style display-7 text-justify ">
            <strong>2. The Personal Data We Collect From You</strong>
            <br><br>2.1. We collect the personal data described below in accordance with applicable local laws and, if required, upon obtaining your consent.
            <br><br>2.2. Personal data means any information about an individual, whether recorded in a material form or not and whether true or not, who can be identified from that data (whether directly or indirectly), or from that data and other data to which we have or are likely to have access.
            <br><br>2.3. Depending on your use of our Platform and/or your interaction with our Services (such as when registering for our Services and/or logging into our Platform), you may be asked to provide us with certain information. While you can choose not to provide us with certain information, you might not be able to take advantage of many of our Services that are provided to you. The personal data that you may opt to provide to us are as follows:
            <br><br>(a) Identity and Profile Data, which may include your name, date of birth, gender, username and password, email address, telephone number, your interests, and any personal data in any photographs or videos or audio recordings that you upload onto our Platform. 
            <br>
            <ul class="mbr-text mbr-fonts-style display-7 text-justify">
              <li>Where you are a partner, we may also collect your company name, address, date of incorporation, and other business-related information (e.g. company registration number, business licence, tax information, shareholder and director information</li>
            </ul>
            <p class="mbr-text mbr-fonts-style display-7 text-justify ">
            (b) Account and Transaction Data, which may include your credit card details, bank account details, bank statements, delivery/ billing address, payments and orders to and from you, and other details of products and services that you have supplied to or purchased through the Platform.
            <br><br>(c) Usage Data, such as information about how and when you use the Platform, products and Services or view any content on the Platform, as well as websites you were visiting before you came to the Platform and other similar statistics.
            <br><br>(d) Marketing and Communications Data, which may include your interests, survey responses, preferences in receiving marketing materials from us and your communication preferences, your preferences for particular products or services, as well as your feedback, chat, email or call history on the Platform or with third party service providers.
            <br><br>(e) Location data, such as when you check for deals near you or the delivery / pick-up status of orders.
            <br><br>2.4. We automatically collect and process certain types of information when you use your devices to access our Platform and interact with our Services for the purposes set out herein. 
          </p>
            
          <p class="mbr-text mbr-fonts-style display-7 text-justify ">
            <strong>How we receive your personal data</strong>     
            <br>
            <p class="mbr-text mbr-fonts-style display-7 text-justify" >
              2.5. During the course of your use of the Platform and our provision of the Services, we may receive personal data from you in the following situations:
              <br><br>(a) When you browse our websites (both mobile and web versions);
              <br><br>(b) When you create an account with us (as customer or partner or otherwise);
              <br><br>(c) When you apply for, subscribe to or otherwise use any of the Services on our Platform and/or on the platforms of our affiliates;
              <br><br>(d) When you make a transaction regarding the products available on the Platform;
              <br><br>(e) When you activate or use any payment-related functions available on the Platform or provided by our third party service providers;
              <br><br>(f) When you use the chat function on the Platform;
              <br><br>(g) When you subscribe to our publications or marketing collaterals;
              <br><br>(h) When you enter a competition, promotion or survey;
              <br><br>(i) When you participate in any activity or campaign on the Platform;
              <br><br>(j) When you log in to your account on the Platform or otherwise interact with us via an external service or application, such as Facebook or Google;
              <br><br>(k) When you interact with us offline, including when you interact with our outsourced customer service agents.
              <br><br>2.6. We may collect personal data from you, third parties (including but not limited to agents, vendors, contractors, partners and any others who provide services to us, who collect your personal information and/or perform functions on our behalf, or with whom we collaborate, including but not limited to payment service providers, government sources of data, financial services providers, credit bureaus, delivery, marketing and other service partners), our affiliates, or such data may be collected automatically when you use the Platform or the Services, as set out in this section. Please see also Sections 2.12 to 2.16 on the collection of computer data.
              <br><br>2.7. During the course of your use of the Platform and our provision of the Services, you agree that you have provided your consent (whether to us, the third party or our affiliates) to the transfer of your personal data from third parties and/or our affiliates to LINEHAUL for the purposes set out in this Privacy Policy or any other terms.
              <br><br>2.8. You agree to only submit personal data which is accurate and not misleading and to keep it up to date and inform us of any changes to the personal data that you have provided to us. We shall have the right to request for documentation and carry out the necessary checks to verify the personal data provided by you as part of our user verification processes or as required under law.
              <br><br>2.9. We will only be able to collect certain categories of personal data if you voluntarily provide the personal data to us or as otherwise provided for under this Privacy Policy. If you choose not to provide your personal data to us or subsequently withdraw your consent to our use of your personal data, we may not be able to provide you with certain features or functionality on the Services or access to the Platform.
              <br><br>2.10. If you sign up to be a user on our Platform using your social media account or link your LINEHAUL account to your social media account or use certain other LINEHAUL social media features, we may access personal data about you which you have voluntarily provided to your social media provider in accordance with the provider's policies and we will manage your personal data in accordance with this Privacy Policy.
              
            </p>

            <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              <strong>Collection of Computer Data</strong>     
              <p class="mbr-text mbr-fonts-style display-7 text-justify" >
                2.11. We or our authorised service providers may use cookies, web beacons, and other similar technologies in connection with your use of the Services or access of the Platform.
                <br><br>2.12. When you visit the Platform through your computer, mobile device, or any other device with Internet connectivity, our company servers will automatically record data that your browser sends whenever you visit a website, such as the technical data and usage data outlined in Section 2.2 above.
                <br><br>2.13. This data is collected for analysis and evaluation in order to help us improve our website and the services and products we provide, as well as to help us to personalise the content to match your preferred interests more quickly. The data is also collected to make the Services and the Platform more convenient and useful to you, and to provide more relevant advertising related to market products, services and features to you.
                <br><br>2.14. Cookies are small text files (typically made up of letters and numbers) placed in the memory of your browser or device when you visit a website or view a message. They allow us to recognise a particular device or browser. Web beacons are small graphic images that may be included on our Services and the Platform. They allow us to count users who have viewed these pages so that we can better understand your preference and interests.
                <br><br>2.15. You may be able to manage and delete cookies through your browser or device settings. However, certain cookies are required to enable core functionality (such as adding items to your shopping basket), so please note that changing and deleting cookies may affect the functionality available on the Platform or through our Services.
                
              </p>

              <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                <strong>3. Use and Disclosure of Your Personal Data</strong>     
                <br>
                <p class="mbr-text mbr-fonts-style display-7 text-justify" >

                  <strong>Purpose of Use</strong><br><br>
                  3.1. We use and disclose personal data for the purposes described below in accordance with applicable local laws and, if required, upon obtaining your consent.
                  <br><br>3.2. Depending on your relationship with us (for example, whether you are a customer, a partner, a visitor to our websites, or a user of our mobile applications), the personal data which we collect from you or via third parties may be used for certain purposes, as follows:                   
                  <br><br>(a) To facilitate your use of the Services or access to the Platform.
                  <br><br>(b) To administer the online account that you may have set up with us.
                  <br><br>(c) To conduct analytics, research, business and development: 
                </p>
                <ul class="mbr-text mbr-fonts-style display-7 text-justify ">
                  <li>To better understand user experience with the Services and the Platform;</li> <br>
                  <li>To improve the layout or content of the pages of the Platform and customise them for users;</li><br>
                  <li>To identify visitors on the Platform;</li><br>
                  <li>To conduct surveys, including carrying out research on our users’ demographics and behaviour to improve our 
                    current technology (e.g. voice recognition tech, etc) via machine learning or other means;</li><br>
                  <li>To derive further attributes relating to you based on personal data provided by you (whether to us or third parties), 
                    in order to provide you with more targeted and/or relevant information;</li><br>
                  <li>To conduct data analysis, testing and research, monitoring and analysing usage and activity trends; and</li><br>
                  <li>To further develop our products and services.</li>
                </ul>
                <p class="mbr-text mbr-fonts-style display-7 text-justify">                
                  (d) Legal and operational purposes
                </p>
                <ul class="mbr-text mbr-fonts-style display-7 text-justify">
                  <li>To ascertain your identity in connection with fraud detection purposes;</li><br>
                  <li>To compare information, and verify with third parties in order to ensure that the information is accurate;</li><br>
                  <li>To process any complaints, feedback, enforcement action and take-down requests in relation to any content you have 
                    uploaded to the Platform;</li><br>
                  <li>To produce statistics and research for internal and statutory reporting and/or record-keeping requirements;</li><br>
                  <li>To store, host, back up your personal data for operational, legal or business purposes;</li><br>
                  <li>To share personal data in connection with any proposed purchase, merger or acquisition of any part of our business, 
                    provided that we satisfy the requirements of applicable data protection law when disclosing your personal data;</li>
                  <li>To prevent or investigate any actual or suspected violations of our Terms of Use, Privacy Policy or any of our policies, 
                    fraud, unlawful activity, omission or misconduct, whether relating to your use of our Services or any other matter arising 
                    from your relationship with us;</li><br>
                  <li>To perform due diligence checks;</li><br>
                  <li>To comply with legal and regulatory requirements (including, where applicable, the display of your name, contact details 
                    and company details), including any law enforcement requests, requests by any governmental or regulatory authority having 
                    jurisdiction over LINEHAUL, in connection with any legal proceedings, or as otherwise deemed necessary by us; and</li><br>
                  <li>To protect the life, health or personal safety of LINEHAUL’s employees, its users and/or the public.</li>
                </ul>
                <p class="mbr-text mbr-fonts-style display-7 text-justify">                
                  (e) Any other purpose to which your consent has been obtained, in compliance with our obligations under applicable laws, including to notify you at the time of obtaining your consent.         
                </p>
                <p class="mbr-text mbr-fonts-style display-7 text-justify">
                  3.3. Where you are a customer and/or you have signed in or registered with us, the personal data which we collect from 
                  you or via third parties may be used for other purposes, as follows:
                </p>
                <p class="mbr-text mbr-fonts-style display-7 text-justify"> 
                  (i) Processing your order for delivery services 
                </p>
                <ul class="mbr-text mbr-fonts-style display-7 text-justify">
                  <li>To process orders you submit through the Platform;</li><br>
                  <li>To deliver the pallet you have booked through the Platform. We may pass your personal information on to a third party
                     (e.g. our logistics partner) or relevant regulatory authority (e.g. customs) in order to make delivery of the pallet to 
                     you;</li><br>
                  <li>To update you on the delivery of the pallets;</li><br>
                  <li>To provide customer support for your orders; and</li><br>
                  <li>To verify and carry out payment transactions (including any credit card payments, bank transfers, offline payments, 
                    remittances, or e-wallet transactions) in relation to payments related to you, promotions participated in by you and/or 
                    Services used by you. In order to verify and carry out such payment transactions, payment information, which may include 
                    personal data, will be transferred to third parties such as our payment service providers.</li>
                </ul>

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  (ii) Providing Services
                </p>
                <ul class="mbr-text mbr-fonts-style display-7 text-justify " >
                  <li>To display your name, username or profile on the Platform;</li><br>
                  <li>To facilitate the use of the mini-apps available on the Platform and purchases made via these mini-apps;</li><br>
                  <li>To respond to your queries, feedback, claims or disputes, whether directly or through our third party service providers;
                  </li><br>
                  <li>To display on scoreboards on the Platform in relation to campaigns or any other activity;</li><br>
                  <li>To allow other users to identify you as a user of the Service, to allow you to find other users and to connect with them 
                    on the Platform, and to support the socialising function of the Services (where applicable);</li><br>
                  <li>To assess your application for loan facilities and/or to perform credit risk assessments in relation to applications for 
                    financial services and products, such as credit or loan facilities, insurance or credit cards (where applicable); and</li><br>
                  <li>To facilitate the integration of third party’s loyalty membership programmes (including those supported by partners or brands)
                     with our Platform.</li>
                </ul>
                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  (iii) Marketing and advertising
                </p>
                <ul class="mbr-text mbr-fonts-style display-7 text-justify ">
                  <li>To provide you with information we think you may find useful or which you have requested from us;</li><br>
                  <li>To send you marketing or promotional information about our or third-party partners’ products and services on our Platform from
                     time to time; and</li><br>
                  <li>To help us conduct marketing and advertising;</li>
                </ul>

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  (iv) Other
                </p>
                <ul class="mbr-text mbr-fonts-style display-7 text-justify">
                  <li>To know our customer better; and</li><br>
                  <li>To conduct automated decision-making processes in accordance with any of the above purposes, which will be carried out in 
                    compliance with our obligations under applicable laws, including to notify and obtain your consent to the same.</li>
                </ul>


                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  3.4. Where you are a partner, the personal data which we collect from you or via third parties may be used for other purposes, as follows:   
                </p>
                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  (i) Providing Services
                </p>
                <ul class="mbr-text mbr-fonts-style display-7 text-justify">
                  <li>To respond to your queries, feedback, claims or disputes, whether directly or through our third party service 
                    agents;</li><br>
                  <li>To verify your documentation submitted to us to facilitate your onboarding with us as a partner on the Platform,
                     including the testing of technologies to enable faster and more efficient onboarding;</li><br>
                  <li>To display your name, username or profile on the Platform;</li><br>
                  <li>To verify and carry out financial transactions (including any credit card payments, bank transfers, offline 
                    payments, remittances, or e-wallet transactions) in relation to payments related to you, promotions participated by 
                    you and/or Services used by you. In order to verify and carry out such payment transactions, payment information,
                     which may include personal data, will be transferred to third parties such as our payment service providers;</li><br>
                  <li>To assess your application for loan facilities and/or to perform credit risk assessments in relation to your applications 
                    for partner financing and products, including credit or loan facilities, insurance or credit cards (where applicable); and</li><br>
                  <li>To help us conduct marketing and advertising.</li>
                </ul>

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  (ii) Other
                </p>
                <ul class="mbr-text mbr-fonts-style display-7 text-justify">
                  <li>To know our partners better; and</li><br>
                  <li>To conduct automated decision-making processes in accordance with any of these purposes, which will be carried out in compliance with our obligations under applicable laws, including to notify and obtain your consent to the same.</li>
                </ul>
              

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  Who we disclose your personal data to
                  </p>
                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  3.5. We may share (or permit the sharing of) your personal data with and/or transfer your personal data to third 
                  parties and/or our affiliates for the above-mentioned purposes. These third parties and affiliates, which may be located 
                  inside or outside your jurisdiction, include but are not limited to:
                  </p>
                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  (i) Service providers (such as agents, vendors, contractors and partners) in areas such as payment services, logistics and shipping, marketing, data analytics, market or consumer research, survey, social media, customer service, installation services, information technology and website hosting;
                  <br><br>(ii) Their service providers and related companies; 
                  <br><br>(iii) Courts, tribunals, enforcement agencies, governmental or regulatory authorities having jurisdiction over LINEHAUL; and
                  <br><br>(iv) Other users of the Platform or Services.
                </p>

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                 3.6. In disclosing your personal data to them, we endeavour to ensure that the third parties and our affiliates keep your personal data secure from unauthorised access, collection, use, disclosure, processing or similar risks and retain your personal data only for as long as your personal data is needed to achieve the above-mentioned purposes.
                </p>

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  <strong>International data transfer</strong>
                  </p>
                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                    3.7. We may transfer or permit the transfer of your personal data outside of your jurisdiction for any of the
                    purposes set out in this Privacy Policy. However, we will not transfer or permit any of your personal data to be 
                    transferred outside of such jurisdiction unless the transfer is in compliance with applicable laws, including, for 
                    example, to inform and obtain your consent on the countries to which your personal data may be transferred to and the
                    applicable standards on data protection under the laws of such countries.
                  </p>
                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  Third party services
                  </p>
                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                    3.8. We may share your personal data with our third party service providers or affiliates (e.g. payment service 
                    providers) in order for them to offer their services to you in conjunction with your use of the Platform or our 
                    Services. Your acceptance and use of the third party service provider’s or our affiliate’s services shall be subject 
                    to terms and conditions as may be agreed between you and the third party service provider or our affiliate. Upon your 
                    acceptance of the third party service provider’s or our affiliate’s service offering, the collection, use, disclosure,
                    storage, transfer and processing of your data (including your personal data and any data disclosed by us to such third
                    party service provider or affiliate) shall be subject to the applicable privacy policy of the third party service 
                    provider or our affiliate, which shall be the data controller of such data. You agree that any queries or complaints 
                    relating to your acceptance or use of the third party service provider’s or our affiliate’s services shall be directed
                    to the party named in the applicable privacy policy.
                  </p>
                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  4. Withdrawal of Consent to Continued Use, Disclosure, Storing and/or Processing of Personal Data
                  <br><br>4.1. You may communicate the withdrawal of your consent to the continued use, disclosure, storing and/or processing of your personal data by contacting us using the contact details below, subject to the conditions and/or limitations imposed by applicable laws or regulations.
                  <br><br>4.2. Please note that if you communicate your withdrawal of your consent to our use, disclosure, storing or processing of your personal data for the purposes and in the manner as stated above or exercise your other rights as available under applicable local laws, we may not be in a position to continue to provide the Services to you or perform any contract we have with you, and we will not be liable in the event that we do not continue to provide the Services to, or perform our contract with you. Our legal rights and remedies are expressly reserved in such an event.
                  <br><br>Marketing information
                  <br><br>4.3. You may unsubscribe from receiving marketing information at any time in our mobile application settings or by using the unsubscribe function within the electronic marketing material. 
                  </p>
                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                    5. Updating Your Personal Data
                    <br><br>5.1. It is important that the personal data you provide to us is accurate and complete for you to continue 
                    using the Platform and for us to provide the Services. You are responsible for informing us of changes to your 
                    personal data, or in the event you believe that the personal data we have about you is inaccurate, incomplete, 
                    misleading or out of date.
                    <br><br>5.2. You can update your personal data anytime by accessing your account on the Platform.
                    <br><br>5.3. We may take steps to share the updates to your personal data with third parties and our affiliates 
                    with whom we have shared your personal data if your personal data is still necessary for the above-stated purposes.
                  </p>
                  
                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                    6. Accessing and Correcting Your Personal Data
                    <br><br>6.1. You may request information about your personal data which we have collected, or enquire about the ways 
                    in which your personal data may have been used, disclosed, stored or processed by us via the personal account 
                    information setting on our Platform or by contacting us using the contact details in Section 11 below. You may also 
                    request correction of any error or omission in your personal data which we have collected in the same way. In order 
                    to facilitate processing of your request, it may be necessary for us to request further information relating to your 
                    request. Where permissible under law, we may refuse such correction requests if deemed vexatious or unreasonable.
                    <br><br>6.2. Where permitted by applicable data protection laws, we reserve the right to charge a reasonable 
                    administrative fee for retrieving your personal data records. If so, we will inform you of the fee before processing 
                    your request.
                  </p>

                  <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                    7. Security of Your Personal Data
                    <br><br>7.1. To safeguard your personal data from unauthorised access, collection, use, disclosure, processing, 
                    copying, modification, disposal, loss, misuse, modification or similar risks during the course of your use of our 
                    Platform and/or Services, we have introduced appropriate administrative, physical and technical measures such as:
                    <br><br>(a) Restricting access to personal data to individuals who require access;
                    <br><br>(b) Maintaining technology products to prevent unauthorised computer access;
                    <br><br>(c) implementing other security measures as required by applicable law.
                    <br><br>7.2. You should be aware, however, that no method of transmission over the Internet or method of electronic 
                    storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your 
                    information and are constantly reviewing and enhancing our information security measures. You are also advised to 
                    exercise caution with respect to the sharing of your personal data and your authentication / security details (such as 
                    passwords, credit card details, transaction details) outside of our Platform / Services.
                  </p>
                

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  <strong>8. Retention of Personal Data</strong>
                  </p>
                <p class="mbr-text mbr-fonts-style display-7 text-justify "> 
                  8.1. We will only retain your personal data for as long as we are either required or permitted to by law or as relevant for the purposes for which it was collected.
                  <br><br>8.2. We will cease to retain your personal data by securely disposing of the same, or remove the means by which the data can be associated with you through anonymisation, as soon as it is reasonable to assume that such retention no longer serves the purposes for which the personal data was collected, and is no longer necessary for any legal or business purpose.
                </p>

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  <strong>9. Minors</strong> </p>
                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  9.1. LINEHAUL does not sell products to minors (which is to be determined based on the applicable law), nor does it intend to provide any of the Services or the use of the Platform to minors. We do not knowingly collect any personal data relating to minors.
                  <br><br>9.2. You hereby confirm and warrant that you are above the age of minority and you are capable of understanding and accepting the terms of this Privacy Policy. If you are a minor, you may use our Platform only with the involvement of a parent or legal guardian.
                  <br><br>9.3. As a parent or legal guardian, please do not allow minors under your care to submit personal data to LINEHAUL. In the event that such personal data of a minor is disclosed to LINEHAUL, you hereby consent to the processing of the minor’s personal data and accept and agree to be bound by this Privacy Policy and take responsibility for his or her actions.
                  <br><br>9.4. We will not be responsible for any unauthorised use of the Services on the Platform by yourself, users who act on your behalf or any unauthorised users. It is your responsibility to make your own informed decisions about the use of the Services on the Platform and take necessary steps to prevent any misuse of the Services on the Platform.
                  <br>
                </p>

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  <strong>10. Third Party Sites</strong>
                  </p>
                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  10.1. The Platform may contain links to other websites operated by other parties, such as our business affiliates, merchants or payment gateways. We are not responsible for the privacy practices of websites operated by these other parties. You are advised to check on the applicable privacy policies of those websites to determine how they will handle any information they collect from you.
                  <br><br>10.2. Please note that even if the third party is affiliated with us, we have no control over these third party websites, each of which may have their own separate privacy and data collection practices independent of us. We therefore have no responsibility or liability for the content, security arrangements (or lack thereof) and activities of these linked sites. These linked sites are only for your convenience and you therefore access them at your own risk. Nonetheless, we seek to protect the integrity of our Platform and the links placed upon each of them and therefore welcome any feedback about these linked sites (including, without limitation, if a specific link does not work).
                  <br>
                </p>

                <p class="mbr-text mbr-fonts-style display-7 text-justify ">
                  <strong>11. Questions, Feedback, Concerns, Suggestions or Complaints</strong>
                  <br><br>11.1. If you have other concerns about our data protection / privacy practices, or wish to exercise any rights you may have under applicable law, please feel free to contact us via our email or customer services.
                  <br>
                </p>
                <br>
                <p class="mbr-text mbr-fonts-style display-7 textjustify ">
                  Date of update of this Privacy Policy: 18 January 2023</p>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>