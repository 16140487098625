<div class="fit_the_screen">
  <app-header></app-header>
 
     <!-- The slideshow/carousel -->
     <div id="slideShow" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> 
      <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let slider of sliders; let i = index" [class.active]="i === 0">
          <img [src]="slider.url" alt="{{ slider.name }}" class="slideShow-images w-100">
          <div class="captionBox carousel-caption text-start">
            <div class="captionContent">
              <h3 class="captionTitle">{{ slider.name }}</h3>
              <p class="slideShow_Caption text-start">{{ slider.description }}</p>
              <!-- <span type="button" class="learnMoreBtn btn btn-light fw-bold" (click)="goToOurStrength()">Learn More &#10230;</span> -->
            </div>      
          </div>
        </div>
      </div>
  
      <!-- Left and right controls/icons -->
      <!-- <button class="carousel-control-prev" type="button" data-bs-target="#slideShow" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#slideShow" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button> -->
    </div> 

  <div class="section">
      <div class="container p-0">
          <div class="about_us_info">
            <h4 class="text-title text-center">Background</h4>
            <br>
            <p class="text-start">
              Enter the dynamic realm of Line Haul Logistics, a pioneer reshaping mid-mile delivery across Malaysia. Our services are 
              finely tuned to cater to the precise needs of our customers, ensuring a dependable and efficient experience. Operating 
              through a network of 54 depots and hubs, Line Haul facilitates cost-effective pallet distribution to numerous business 
              and customers daily, accommodating pallets of various sizes, from individual loads to full lorry shipments.
              </p>
            <br>
            <p class="text-start">
              Line Haul's adaptability is showcased in our innovative business model, minimizing driver time on the road while maximizing 
              delivery efficiency through a dedicated network of members. Customers benefit from an abundance of delivery options, 
              including premium or express services, as we strive to meet all pallet needs. Our comprehensive range of services, 
              varied delivery options, and a fleet of versatile vehicles position us as an industry-leading network.
            </p>
            <br>
            <p class="text-start">
              In the world of mid-mile delivery, Line Haul Logistics is driven by a team committed to product innovation and adapting to 
              diverse market segments. Our vision is to lead in logistic innovation, providing unmatched efficiency and accessibility, 
              while continually enhancing our value proposition with security, competitive pricing, and haulage excellence.
            </p>
            <p class="text-start">
              Embark on your logistics journey with Line Haul, where dedication, innovation, and a nationwide network converge to 
              redefine the logistics
              experience. </p>
          </div>
      </div>

    </div>

    <!-- <div class="section"> -->      

      <div class="container p-0">
          <h4 class="text-title text-center">Visions & Missions</h4>

        <div class="d-none d-md-block ">
          <div class="container p-0" style="position: absolute; z-index: -1; height: 700px; width: 100%;">
            <img src="../assets/image/Icons/Rectangle_8.png" width="50%" height="549px">
            <img src="../assets/image/Icons/Rectangle_9.png" width="50%" height="549px">
          </div>
          <div class="row p-0 m-0">

            <div class="col-6 signUpNow-Box">
                <div class="card signUpNow-Card">
                  <div >
                    <img class="signUpNow-icon" src="../assets/icons/Line_Haul_Vision_Mission_1.png" alt="Apple Vector">
                  </div>
                  <h4 class="text-title text-center">Our Vision</h4>
                  <p class="text-center p-4">
                    To be the leader in pallet mobility with
                    excellent connectivity via technological innovation and critical knowledge from logistic industry
                  </p>
                </div>
            </div>
      
            <div class="col-6 signUpNow-Box" >
                <div class="card signUpNow-Card">
                  <div >
                    <img class="signUpNow-icon" src="../assets/icons/Line_Haul_Vision_Mission_2.png" alt="Apple Vector">
                  </div>
                  <h4 class="text-title text-center">Our Mission</h4>
                  <p class="text-center p-4">
                    Provide small business entrepreneurs a platform to mobilize their consignment at ease via our wide distribution network.
                    <br><br>
                    Consistently invest in technology R&D to enable connection simplicity between customer, 
                    their consignment and our company
                  </p>
                </div>
            </div>

          </div>
        </div>
    
        <div class="d-md-none row p-0 m-0">
          <div class="container"  style="position: absolute; z-index: -1; height: 700px;">
            <img src="../assets/image/Icons/Rectangle_8.png" width="100%" height="463px">
            <img src="../assets/image/Icons/Rectangle_9.png" width="100%" height="550px">
          </div>
          <div class="col-12 signUpNow-Box">
            <div class="card signUpNow-Card" style="height: 380px;">
              <div >
                <img class="signUpNow-icon" src="../assets/icons/Line_Haul_Vision_Mission_1.png" alt="Apple Vector">
              </div>
              <h4 class="text-title text-center">Our Vision</h4>
              <p class="text-center p-4">
                To be the leader in pallet mobility with
                excellent connectivity via technological innovation and critical knowledge from logistic industry
              </p>
            </div>
        </div>

          <div class="col-12 signUpNow-Box" >
            <div class="card signUpNow-Card" style="height: 400px;">
              <div >
                <img class="signUpNow-icon" src="../assets/icons/Line_Haul_Vision_Mission_2.png" alt="Apple Vector">
              </div>
              <h4 class="text-title text-center">Our Mission</h4>
              <p class="text-center p-4">
                Provide small business entrepreneurs a platform to mobilize their consignment at ease via our wide distribution network.
                <br><br>
                Consistently invest in technology R&D to enable connection simplicity between customer, 
                their consignment and our company
              </p>
            </div>
        </div>
    
        </div>

      </div>
    <!-- </div> -->

  <div class="container"  style="margin-top:0; margin-bottom:0;"></div>

  <app-footer></app-footer>
</div>